export const actions = {
	REQ_LOGIN: 'REQ_LOGIN',
	REQ_LOGOUT: 'REQ_LOGOUT',
	SET_AUTHENTICATED: 'SET_AUTHENTICATED',
	SET_KEYCLOAK: 'SET_KEYCLOAK',
	SET_TOKEN: 'SET_TOKEN'
}

export const reqLogin = () => ({ type: actions.REQ_LOGIN })
export const reqLogout = () => ({ type: actions.REQ_LOGOUT })
export const setAuthenticated = authenticated => ({ type: actions.SET_AUTHENTICATED, authenticated })
export const setKeycloak = keycloak => ({ type: actions.SET_KEYCLOAK, keycloak })
export const setToken = token => ({ type: actions.SET_TOKEN, token })

export default actions
import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import { BlockchainInfoCard, TitlesHeader } from 'components';

const BlockchainInfo = (props) => {

  const buildChainData = () => {
    return(
      {
        "head_block":  props.connectionInfo[1].service_data.head_block_num,
        "lib": props.connectionInfo[1].service_data.last_irreversible_block,
        "block_time": props.connectionInfo[1].time,
        "chain_id": props.connectionInfo[1].service_data.chain_id
      }
    )
  }

  return (
    <>
        <BlockchainInfoCard
            head={"Blockchain Details"}
            chainData = {
                buildChainData()
            }

        />
    </>
  );
}
// <ConnectionIndicator latestBlock={(data) => {setKeyblock(data)}}/>

export default connect(
  ({ homePage: { blockchainInfo }}) => ({
    blockchainInfo
  }),
  {
  }

)(BlockchainInfo);

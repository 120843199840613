import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchTransactionsList, setTransactionsList } from './actions'

import { mythicalFetcher } from '../../utilities'
import { getTransactions } from "../../../services/ivi-engine";

function* fetchTransactionsListSaga(action) {
	try {
		const response = yield mythicalFetcher(getTransactions, [action.body])
		yield put(setTransactionsList(response, action.uuid))
	} catch(e) {}
}

function* watchFetchTransactionsListSaga() {
	yield takeLatest(actions.REQ_TRANSACTIONS_LIST, fetchTransactionsListSaga)
}


export default function* transactionListSagas() {
	yield fork(watchFetchTransactionsListSaga)
}

export const transformData = (data) => {
	let series_data = [];
	let categories = []
	let maximumY = 10;

	for (let item of data) {
		series_data.push(item.doc_count)
		categories.push(item.key_as_string)
	}

	let font = "#6984aa"
	let lines = "#6984aa";
	let theme = localStorage.getItem("theme")
	if (theme === "false") {
		font = "#6984aa"
		lines = "#6984aa"
		theme = "dark"
	} else {
		theme = "light"
	}

	series_data.map(d => {
		maximumY = Math.max(maximumY, d)
	})

	return (
		{
			series: [{
					name: "Actions",
					data: series_data
				}]
			,
			chart: {
				foreColor: font,
				toolbar: {
					show: false
				},
			},
			tooltip: {
				enabled: true,
				followCursor: true,
				style: {
					fontSize: '12px',
				},
				theme: theme
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'light',
					gradientToColors: [ '#11B1B4', '#a905bb'],
					stops: [1, 95, 100],
					colorStops: [
						{
							offset: 0,
							color: "#656ceb",
							opacity: 1
						},
						{
							offset: 20,
							color: "#7971e3",
							opacity: 1
						},
						{
							offset: 60,
							color: "#0acadc",
							opacity: 1
						},
						{
							offset: 100,
							color: "#098ed5",
							opacity: 1
						}
					]
				},
			},
			stroke: {
				width: 4,
				curve: "smooth",
				lineCap: "round"
			},

			grid: {
				show: false
			},

			plotOptions: {
				area: {
					fillTo: 'end',
				}
			},

			yaxis: {
				forceNiceScale: true,
				max: maximumY + 1,
				axisBorder: {
					show: false,
					color: font
				},
				axisTicks: {
					show: false
				}
			},

			xaxis: {
				categories: categories,
				axisTicks: {
					show: false
				},
				axisBorder: {
					show: false,
					color: lines
				},
				labels: {
					show: false,
					trim: true
				}
			},

			dataLabels: {
				enabled: false,
				style: {
					fontSize: '14px',
					fontWeight: 'bold',
					colors: undefined
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderWidth: 0,
					opacity: 0.9,
					dropShadow: {
						enabled: true,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45
					}
				},
			}
		}
	)
}

const splitQuantity = (quantity) => {
	let split = quantity.split(" ");
	return split[0]
}
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {makeStyles} from '@material-ui/core/styles'
import {v4 as uuidv4} from 'uuid';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import {setActivePage, setDashboardLayout} from '../../redux/modules/settings'
import {Blockdetail} from '../../components/Blockdetail'
import getTheme from '../../themes'
import {checkQueryParams} from "../../helpers/check-location";
import {fetchBlockDetails} from "../../redux/modules/blockdetail";
import {BlockDetailsHeader} from "../../components/Cards";

import {Transactiondetails} from "../../components/Transactiondetails";
import Page from "../../components/Page";


const useStyles = makeStyles({

	dashContainer: ({height}) => ({
		width: '100%',
	})

}, {name: "BlockDetails"})

export default function BlockDetails(props) {
	const dispatch = useDispatch()
	const {Settings, BlockDetail} = useSelector(state => state)
	const {colorTheme, block_details, screenDims} = Settings
	const [tab, setTab] = useState(0);

	// FETCH ITEMS
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	const theme = getTheme(colorTheme)
	const classes = useStyles({height: screenDims.height})
	const [page, setPage] = useState({
		pageLabel: null,
		tx_id: null,
		root_breadcrumbs: [
			{
				path: '/',
				page: "home",
				label: 'Home',
			},
			{
				path: `/blocks`,
				page: "blocks",
				label: 'Blocks'
			}
		],
		sub_breadcrumbs: []
	})

	const setUpSubPage = (local) => {
		setPage({
			...page,
			tx_id: local.tx_id,
			pageLabel: local ? local.label : "blockdetails",
			sub_breadcrumbs: local ? [...local.breadcrumb] : []
		})
	}

	// Set initial activePage and environment
	useEffect(() => {
		let local = checkQueryParams(props.location)
		setUpSubPage(local)

		dispatch(setActivePage('Blocks'))
		if (local.tx_id) {
			dispatch(fetchBlockDetails({block_num_or_id: local.tx_id}, uuid))
		}

	}, [dispatch, props.location])

	return (
		<Page title={'title'} className={classes.page}>
			{
				payload !== BlockDetail.payload && uuid === BlockDetail.uuid
					?
					<>
						{setPayload(BlockDetail.payload)}
					</>
					: <></>
			}
			{
				payload
					?
						<>
							<BlockDetailsHeader
								title={`Block Details`}
								ref_block_prefix={payload.ref_block_prefix}
								subtitle={payload.block_num}
								txs={payload.transactions.length}
								status={payload.block_num ? "Confirmed" : "Unconfirmed"}
								setTab={(e) => {
									setTab(e)
								}}
								tabValue={tab}
							/>

							{
								tab === 0
									?
									<Blockdetail
										data={payload}
									/>
									: tab === 1
									?
									<Transactiondetails
										data={payload.transactions}
									/>
									: <>none</>
							}
						</>
					:
					<></>
			}
		</Page>
	)
}

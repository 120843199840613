import React from 'react';
import { hydrate, render } from 'react-dom';

import ReactGA from 'react-ga'

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'
import App from './app';
import Unauthorized from './unauthorized';
import { persistor, store, history } from './redux'

import config from './config.js'

import Cookies from 'js-cookie';


render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </PersistGate>
    </Provider>,
    document.getElementById('body')
)

ReactGA.initialize(config.apis.google.analytics.trackingId)

// WITH KEYCLOAK
// import React from 'react';
// import { hydrate, render } from 'react-dom';
//
// import ReactGA from 'react-ga'
//
// import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router';
// import { PersistGate } from 'redux-persist/integration/react'
// import App from './app';
// import Unauthorized from './unauthorized';
// import { persistor, store, history } from './redux'
//
// import config from './config.js'
//
// import Cookies from 'js-cookie';
// import Keycloak from 'keycloak-js'
// import { setKeycloak } from './redux/modules/authentication'
//
// const  { authUrl, clientId, realm } = config.apis.mythical.keycloak
//
//
// let keycloak = new Keycloak({ clientId, realm, url: authUrl })
//
// const currentLastTimestamp = Cookies.get('lastTimestamp') || process.env.REACT_APP_LAST_INIT_TIMESTAMP || "";
// if ( currentLastTimestamp ){
//   const storedLastTimestamp = localStorage.getItem('lastTimestamp');
//   if (storedLastTimestamp < currentLastTimestamp){
//     persistor.purge();
//   }
//
//   localStorage.setItem('lastTimestamp', currentLastTimestamp);
// }
//
// keycloak.onTokenExpired = () =>
//     keycloak.updateToken(30)
//         .success(() => {
//           console.log('keycloak token updated')
//           store.dispatch(setKeycloak(keycloak))
//         })
//         .error(() => {})
//
// keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
//     .success(auth => {
//       if(!auth){
//         console.log('keycloak auth:' + auth)
//         window.location.reload()
//       } else {
//         if(keycloak.realmAccess.roles.includes("mythical_marketplace_participants")) {
//           store.dispatch(setKeycloak(keycloak))
//           render(
//               <Provider store={store}>
//                 <PersistGate loading={null} persistor={persistor}>
//                   <ConnectedRouter history={history}>
//                     <App />
//                   </ConnectedRouter>
//                 </PersistGate>
//               </Provider>,
//               document.getElementById('body')
//           )
//         } else {
//           store.dispatch(setKeycloak(keycloak))
//           render(
//               <Provider store={store}>
//                 <PersistGate loading={null} persistor={persistor}>
//                   <ConnectedRouter history={history}>
//                     <Unauthorized />
//                   </ConnectedRouter>
//                 </PersistGate>
//               </Provider>,
//               document.getElementById('body')
//           )
//         }
//
//       }
//     })
//     .error((e) => console.log('keycloak error', e))
//
// ReactGA.initialize(config.apis.google.analytics.trackingId)

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage, setDashboardLayout } from '../../redux/modules/settings'
import Page from '../../components/Page'
import {ConnectionRPC, ConnectionIndexed, AtAGlance} from '../../components/Cards'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import Typography from "@material-ui/core/Typography";
import Header from "../../components/Cards/Header";
import WidgetsIcon from "@material-ui/icons/Widgets";
import HistoryIcon from "@material-ui/icons/History";
import InputIcon from "@material-ui/icons/Input";

const ResponsiveGridLayout = WidthProvider(GridLayout)

const useStyles = makeStyles({
	col: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		'&:last-child': {
			marginLeft: 20,
		}
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	dashContainer: ({ height }) => ({
		marginTop: 20,
		width: '100%',
		height: height - 200
	}),
	envSelect: {
		width: 350,
	},
	page: {
		height: '100%'
	},
	select: {
		marginLeft: 10,
		marginRight: 10
	},
}, {name: "Info"})

export default function Info() {
	const dispatch = useDispatch()
	const { Settings, ConnectionIndicator } = useSelector(state => state)
	const { colorTheme, dashboard, screenDims } = Settings
	const theme = getTheme(colorTheme)
	const { chain_resources, lib, indexed, soldItems } = theme.dashboard.icons
	const classes = useStyles({ height: screenDims.height })

	let headBlock = ConnectionIndicator.payload['health'] && ConnectionIndicator.payload['health'].length > 0 ? ConnectionIndicator.payload.health[1].service_data.head_block_num : 0
	let headBlockTime = ConnectionIndicator.payload['health'] && ConnectionIndicator.payload['health'].length > 0 ? ConnectionIndicator.payload.health[1].service_data.head_block_time : 0

	let libBlock = ConnectionIndicator.payload['health'] && ConnectionIndicator.payload['health'].length > 0 ? ConnectionIndicator.payload.health[1].service_data.last_irreversible_block : 0
	let offset = ConnectionIndicator.payload['health'] && ConnectionIndicator.payload['health'].length > 0 ? ConnectionIndicator.payload.health[1].service_data.time_offset : 0

	let lastIndexed = ConnectionIndicator.payload['health'] && ConnectionIndicator.payload['health'].length > 0 ? ConnectionIndicator.payload.health[2].service_data.last_indexed_block : 0
	let activeShards = ConnectionIndicator.payload['health'] && ConnectionIndicator.payload['health'].length > 0 ? ConnectionIndicator.payload.health[2].service_data.active_shards : 0


	const breadcrumbs = [
		{
			path: '/',
			label: 'Home'
		},
		{
			path: `/info`,
			label: 'Connection Info'
		}
	]


	// Set initial activePage and environment
	useEffect(() => {
		dispatch(setActivePage('Info'))
	},[dispatch])

	return (
		<Page
			title={copy.pages.info.title}
			className={classes.page}
			breadcrumbs={breadcrumbs}
		>
			<div className={`flex flex-col`}>
				<Header
					header={`Connection Overview`}
				/>
				<div className={'flex'}>
					<AtAGlance
						title={`Head Block`}
						range={headBlockTime}
						value={headBlock}
						icon={<WidgetsIcon />}
						color={chain_resources.color}
					/>
					<AtAGlance
						title={`Last Irreversible`}
						range={"Time Offset " +  offset + " ms"}
						value={libBlock}
						icon={<HistoryIcon />}
						color={lib.color}
					/>
					<AtAGlance
						title={`Last Indexed`}
						range={"Active Shards " +  activeShards}
						value={lastIndexed}
						icon={<InputIcon />}
						color={indexed.color}
					/>
				</div>

			</div>
		</Page>
	)
}

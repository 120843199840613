import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid';

import {makeStyles} from '@material-ui/core/styles'
import {fetchTableRows} from "../../redux/modules/tablerows";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {DGoodstats} from "../Cards";
import {SubHeader} from "../index";
const useStyles = makeStyles(theme => ({
	backgroundCard: {

	},
	selected: {
		color: theme.text.mint
	},
	token_buttons: {
		display: "flex",
		position: "relative",
		height: "100%",
	},
	disabled_skip_button: {
		border: "0px solid !important",
		height: 50,
		width: 50,
		minWidth: 50,
		position: "absolute",
		right: 0,
		top: "calc(50% - 25px)",
		color: "white"
	},
	skip_button: {
		border: "0px solid !important",
		height: 50,
		width: 50,
		minWidth: 50,
		position: "absolute",
		right: 0,
		top: "calc(50% - 25px)",
		color: "white"
	},
	disabled_back_button: {
		border: "0px solid !important",
		height: 50,
		width: 50,
		minWidth: 50,
		position: "absolute",
		left: 0,
		top: "calc(50% - 25px)",
		color: "white"
	},
	back_button: {
		border: "0px solid !important",
		height: 50,
		width: 50,
		minWidth: 50,
		position: "absolute",
		left: 0,
		top: "calc(50% - 25px)",
		color: "white"
	},
	hover: {
		'&:hover': {
			backgroundColor: theme.body.darkCard
		}
	},
	buffer_space: {
		flex: 1,
		border: "0px solid !important"
	}
}))

const TokenCategoryDisplayCase = props => {
	// SETTINGS
	const classes = useStyles()
	const dispatch = useDispatch()
	const {Settings, TableRows} = useSelector(state => state)

	// FETCH ITEMS
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	// COMPONENT ITEMS
	const [pageSize, setPageSize] = useState(4)
	const [nextKey, setNextKey] = useState("")
	const [previousKey, setPreviousKey] = useState("")

	useEffect(() => {
		let body = {
			"code": props.account,
			"scope": props.category,
			"table": "dgoodstats",
			"limit": pageSize,
			"lower_bound": nextKey,
			"upper_bound": previousKey,
			"json": true
		}

		dispatch(fetchTableRows(body, uuid))
	}, [nextKey, previousKey, props.account, props.category])


	const pageUp = () => {
		setPreviousKey("")
		setNextKey(payload.next_key)
	}

	const pageDown = () => {
		setNextKey("")
		setPreviousKey(payload.next_key)
	}

	const build = (stats) => {
		let builtStats = [];
		stats.map((stat) => {
			builtStats.push(
				<DGoodstats
					dgoodstats={stat}
					category={props.category}
				/>
			)
		})

		if (stats.length < pageSize) {
			builtStats.push(
				<div className={classes.buffer_space}></div>
			)
		}

		return builtStats
	}

	return (
		<>
			{
				payload !== TableRows.payload && uuid === TableRows.uuid
					?
					<>
						{setPayload(TableRows.payload)}
					</>
					: <></>
			}

			{
				payload
					?
					<>
						<div
							className={`flex flex-col px-12 h-full relative  ${classes.backgroundCard}`}
						>
							<SubHeader
									text={`Tokens`}
							/>
							<div className={`flex  h-full`}>
								{
									nextKey !== ""
											?
											<Button className={`${classes.back_button} ${classes.hover}`} onClick={() => {pageDown()}}><ChevronLeftIcon/></Button>
											: 	<Button className={classes.disabled_back_button} disabled><ChevronLeftIcon/></Button>

								}
								{build(payload.rows)}
								{
									payload.more
											?
											<Button className={`${classes.skip_button} ${classes.hover}`} onClick={() => {pageUp()}}><ChevronRightIcon/></Button>
											: 	<Button className={classes.disabled_skip_button} disabled><ChevronRightIcon/></Button>

								}
							</div>

						</div>
					</>
					:<></>
			}

		</>

	)
}

export default TokenCategoryDisplayCase

import React, {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage } from '../../redux/modules/settings'
import Page from '../../components/Page'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import {TitleNewestDGoods} from "../../components/Cards";

import {ActionsList} from "../../components/ActionsList";
import {fetchTitleDetails} from "../../redux/modules/titledetails";

import config from '../../config.js'
import {SubHeader} from "../../components";
import {TitleTokenContainer} from "../../components/TitleTokenContainer";

const useStyles = makeStyles({
	col: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		'&:last-child': {
			marginLeft: 20,
		}
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	dashContainer: ({ height }) => ({
		marginTop: 20,
		width: '100%',
		height: height - 200
	}),
	envSelect: {
		width: 350,
	},
	page: {
		height: '100%',
	},
	select: {
		marginLeft: 10,
		marginRight: 10
	},
}, {name: "Dashboard"})

export default function Dashboard() {
	const dispatch = useDispatch()
	const { Settings, TitleDetails } = useSelector(state => state)
	const { colorTheme, screenDims } = Settings

	let payload = { ...TitleDetails.payload }

	useEffect(() => {
		dispatch(setActivePage('Dashboard'))

		let body = {
			"account": config.apis.mythical.iviPlaceholderEnv.toLowerCase()
		}
		dispatch(fetchTitleDetails(body))
	},[])

	return (
		<Page title={copy.pages.dashboard.title} >

			<div className={`flex flex-col flex-grow`}>
				{
					!payload
						?
							<>Loading</>
						: !payload.account
						?
							<>Loading</>
						: payload.account
							?
								<>
									<TitleTokenContainer account={config.apis.mythical.iviPlaceholderEnv.toLowerCase()}/>
									<div className={" flex flex-col p-6 no-outline"}>
										<SubHeader
											text={`Latest Transactions`}
										/>
										<ActionsList
											account={config.apis.mythical.iviPlaceholderEnv.toLowerCase()}
											pageSize={15}
										/>
									</div>
								</>
							: <></>
				}
			</div>
		</Page>
	)
}

import React, { useState }from 'react';

import { Dropdown } from 'react-bootstrap';

const CustomMenu = React.forwardRef(
	({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
		const [value, setValue] = useState('');

		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				<ul className="list-unstyled">
					{React.Children.toArray(children).filter(
						(child) =>
							!value || child.props.children.toLowerCase().startsWith(value),
					)}
				</ul>
			</div>
		);
	},
);



const ReactDropdown = props => {
	return (
		<>
			<Dropdown  className="dropdown-container">
				<Dropdown.Toggle className="dropdown-button" id="dropdown-custom-components">
					<div className="header-text">
						{props.text}
					</div>
				</Dropdown.Toggle>

				<Dropdown.Menu as={CustomMenu}>
					<Dropdown.Item eventKey="1">Red</Dropdown.Item>
					<Dropdown.Item eventKey="2">Blue</Dropdown.Item>
					<Dropdown.Item eventKey="3" active>
						Orange
					</Dropdown.Item>
					<Dropdown.Item eventKey="1">Red-Orange</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	)
}

export default ReactDropdown;

import React, { useEffect, useState } from 'react';


import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import { push } from 'connected-react-router'
import isObjectEmpty from 'helpers/is-object-empty';
import { fetchStart, paramsSet } from '../../redux/modules/transfersdisplaycase';

import { LoadingSpinner, TransferCards } from 'components';

const TransfersDisplayCase = (props) => {
	const [optionsState, setOptionsState] = useState(10);
	const [upperBound, setUpperBound] = useState(null);

	useEffect(()=>{

		props.paramsSet({
			range_field: "global_sequence",
			action: "transfers",
			upper_bound: upperBound,
			order: 'desc',
			size: 100,
			title: props.title,
			interval: "1H",
			to: null,
			from: null,
			doc_count: 1
		})
		props.fetchStart();

	}, [optionsState, upperBound])

	let { transfersDisplayCase: { isFetching, data, params } } = props;
	let { payload={}, error } = data;

	const handleUpperBoundChange = (event) => {
		switch(event) {
			case "up":
				setUpperBound(payload.response.data[payload.response.data.length - 1].id)
				break;
			case "down":
				setUpperBound(parseInt(upperBound) + parseInt(optionsState))
				break
			case "first":
				setUpperBound(null)
				break;
			case "last":
				setUpperBound(optionsState)
				break;
		}
	}

	return (
		<>
			{
				props.header
					?
					<>
						<div className="row dataTables_wrapper">
							<div className="col-sm-10 col-md-9 mt-n2 text-secondary">
								<div className="dataTables_info py-3">
									Showing <span className="font-weight-bold text-secondary">{props.title.toUpperCase()}</span>'s  {optionsState} Latest Transfers
								</div>
							</div>
						</div>
					</>
					: <></>
			}
			<table className="table font-size-1 text-nowrap mb-0">
				<thead className="thead-light">
				<th scope="col">Txn Hash</th>
				<th scope="col">Block</th>
				<th scope="col">Global Sequence</th>
				<th scope="col">Age</th>
				<th scope="col">From</th>
				<th scope="col">To</th>
				<th scope="col">Value</th>
				</thead>

				<tbody>
				{ error
					?
					<>
						{!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
						<button onClick={props.fetchStart}>Connection error, click to reload</button>
					</>
					: isFetching
						? <LoadingSpinner />
					: !payload.response
						? <LoadingSpinner />
					: payload.response.status_code === 200
						?
							<>
								{
									payload.response.data.map((item) => {
										return (
											<>
												<TransferCards item={item} />
											</>
										)
									})
								}
							</>
						: <></>
				}
				</tbody>
			</table>
			{
				props.footer
					?
					<>
						<div className="row mt-3 dataTables_wrapper">
							<div className="col-sm-12 col-md-6">
								<div className="dataTables_paginate paging_input">
									<ul className="pagination">
										<li className={`first paginate_button page-item ${
											props.keyblock === upperBound
												?
												` disabled`
												: ``
										}`} onClick={(e) => {handleUpperBoundChange("first")}}>
											<a className="page-link">First</a>
										</li>
										<li className={`previous paginate_button page-item ${
											props.keyblock === upperBound
												?
												` disabled`
												: ``
										}`} onClick={(e) => {handleUpperBoundChange("down")}}>
											<a className="page-link">{" < "}</a>
										</li>
										<li className="first paginate_button page-item" onClick={(e) => {handleUpperBoundChange("up")}}>
											<a className="page-link">{" > "}</a>
										</li>
										<li className="first paginate_button page-item" onClick={(e) => {handleUpperBoundChange("last")}}>
											<a className="page-link">Last</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</>
					: <></>
			}
		</>
	);
}

export default connect(
	({ transfersDisplayCaseComponent: { transfersDisplayCase }, router}) => ({
		transfersDisplayCase,
		router
	}),
	{
		fetchStart,
		paramsSet,
		push
	}

)(TransfersDisplayCase);


export const actions = {
	FETCH_BLOCK_DETAILS: `FETCH_BLOCK_DETAILS`,
	SET_BLOCK_DETAILS: `SET_BLOCK_DETAILS`,
}

export const fetchBlockDetails = (body, uuid) => ({ type: actions.FETCH_BLOCK_DETAILS, body, uuid });
export const setBlockDetails = (response, uuid) => ({ type: actions.SET_BLOCK_DETAILS, response, uuid });


export default actions
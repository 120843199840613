import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'

const useStyles = makeStyles(theme => ({
  pagination: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(2.5),
  }  
}))

const TablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onChangePage, maxResults } = props
  const classes = useStyles()

  const handleFirstPageButtonClick = event => onChangePage(event, 'first')
  const handleBackButtonClick = event => onChangePage(event, 'back')
  const handleNextButtonClick = event => onChangePage(event, 'next')
  const handleLastPageButtonClick = event => onChangePage(event, 'last')
    
  return(
    <div className={classes.pagination}>
      <IconButton onClick={handleFirstPageButtonClick} aria-label="First Page">
        <FirstPageIcon />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} aria-label="Previous Page">
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} aria-label="Next Page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} aria-label="Last Page">
        <LastPageIcon />
      </IconButton>
    </div>        
  )
}

TablePaginationActions.propTypes = {   
  count: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  rowsPerPage: PropTypes.number  
}

export default TablePaginationActions
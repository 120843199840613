import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

export default function Page(props) {
	const { breadcrumbs=[], children, className, cta, style, subTitle='', title='' } = props
	const { Settings } = useSelector(state => state)
	const { isMobile, menuOpen } = Settings
	// const classes = useStyles({ menuOpen, isMobile })

	const renderBreadcrumbs = () =>

		<>
			<div className={'flex pl-6 text-white'}>
				{
					breadcrumbs.map((link,i) => i < breadcrumbs.length-1 && link?.label?.length
						?
						<>
							<Typography key={i}>
								<Link to={link.path}>{link.label}</Link>
								&nbsp;{`>`}&nbsp;
							</Typography>
						</>
						: <Typography key={i}>{link.label}</Typography>
					)
				}
			</div>
		</>
	
	return (
		<>
			<div className={"flex flex-col flex-grow page-z"}>
				{
					breadcrumbs.length
						?
						<>
							{renderBreadcrumbs()}
						</>
						: null
				}
				{ children }
			</div>
		</>
	)
}

import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchTitlesList, setTitlesList } from './actions'

import { mythicalFetcher } from '../../utilities'
import { getTitles } from "../../../services/hyperion";

function* fetchTitlesListSaga(action) {
	try {
		const response = yield mythicalFetcher(getTitles, [action.body])
		yield put(setTitlesList(response, action.uuid))
	} catch(e) {}
}

function* watchFetchTitlesListSaga() {
	yield takeLatest(actions.REQ_TITLES_LIST, fetchTitlesListSaga)
}


export default function* titlesListSagas() {
	yield fork(watchFetchTitlesListSaga)
}

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage, setDashboardLayout } from '../../redux/modules/settings'
import Page from '../../components/Page'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import {TitlesList} from "../../components/TitlesList";

const ResponsiveGridLayout = WidthProvider(GridLayout)

export default function Titles() {
	const dispatch = useDispatch()
	const { Settings } = useSelector(state => state)
	const { colorTheme, full, screenDims } = Settings
	const theme = getTheme(colorTheme)
	const breadcrumbs = [
		{
			path: '/',
			label: 'Home'
		},
		{
			path: `/titles`,
			label: 'Titles'
		}
	]

	// Set initial activePage and environment
	useEffect(() => {
		dispatch(setActivePage('Titles'))
	},[dispatch])

	return (
		<Page
			title={copy.pages.titles.title}
			breadcrumbs={breadcrumbs}
		>

			<TitlesList />

		</Page>
	)
}

import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchDeltasList, setDeltasList } from './actions'

import { mythicalFetcher } from '../../utilities'
import { getDeltas } from "../../../services/hyperion";

function* fetchDeltasListSaga(action) {
	try {
		const response = yield mythicalFetcher(getDeltas, [action.body])
		yield put(setDeltasList(response, action.uuid))
	} catch(e) {}
}

function* watchFetchDeltasListSaga() {
	yield takeLatest(actions.REQ_DELTAS_LIST, fetchDeltasListSaga)
}


export default function* deltaListSagas() {
	yield fork(watchFetchDeltasListSaga)
}

import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid';

import moment from "moment";
import Card from "../Card";
import GridLayout, {WidthProvider} from "react-grid-layout";
import {useSelector} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import {Link} from "react-router-dom";

const ResponsiveGridLayout = WidthProvider(GridLayout)
const tableRowMargin = 15

const useStyles = makeStyles(theme => ({
	card: {
		height: 'auto',

	},
	titleDash: {
		width: '100%',
		height: '100%',
		userSelect: 'none',

	},
	tableHead: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 50
	},
	tableRow: {
		background: theme.body.accent2Primaryl2,
		borderBottom: `1px solid ${theme.body.accent2Primaryl4}`,
		display: 'flex',
		alignItems: 'center',
		minHeight: 50,

		"&:hover": {
			background: theme.body.accent2Primaryl4,
		}
	},
	headerCell: {
		flex: 1,
		border: 'none',
		color: theme.text.primary,
		textAlign: 'right',
		textOverflow: 'ellipsis',
		fontWeight: 100,
		fontSize: '12px',
		padding: 5,
		'&:first-child': {
			textAlign: 'left',
			paddingLeft: tableRowMargin,
		},
		'&:last-child': {
			textAlign: 'right',
			paddingRight: tableRowMargin,
		}
	},
	tableCell: {
		flex: 1,
		border: 'none',
		color: theme.text.primary,
		textAlign: 'right',
		textOverflow: 'ellipsis',
		fontWeight: 100,
		fontSize: '12px',
		padding: 5,
		'&:first-child': {
			paddingLeft: tableRowMargin,
		},
		'&:last-child': {
			paddingRight: tableRowMargin,
		}
	},
	cellData: {
		display: 'flex',
		textAlign: 'left',
		marginBottom: '10px',
		marginTop: '10px'
	},
	authKey: {
		flex: 1
	},
	authData: {
		flex: 1,
		color: theme.text.primaryRed,
		fontWeight: theme.typography.fontWeightMedium
	},
	dataKey: {
		flex: 1
	},
	dataValue: {
		flex: 1,
		color: theme.text.mint,
		fontWeight: theme.typography.fontWeightMedium
	}
}))
export default function Transactiondetails (props) {
	const classes = useStyles()
	const { Settings } = useSelector(state => state)
	const { colorTheme, transaction_details, screenDims } = Settings

	function createData(name, data) {
		return { name, data };
	}

	const buildObjectCell = (data) => {
		let cell = [];

		for (let key in data) {
			console.log(key)

			if (data.hasOwnProperty(key)) {
				cell.push(
					<div className={classes.cellData} key={uuidv4()}>
						<span className={classes.dataKey}>{key}</span>
						<span className={classes.dataValue}>{data[key] || ' - '}</span>
					</div>
				)
			}
		}
		return cell
	}

	const buildAuthCell = (data) => {
		let cell = [];

		for (let key in data) {
			for (let ikey in data[key]) {
				if (data[key].hasOwnProperty(ikey) && key !== "AMOUNT") {
					cell.push(
						<div className={classes.cellData} key={uuidv4()}>
							<span className={classes.authKey}>{ikey}</span>
							<span className={classes.authData}>{data[key][ikey] || ' - '}</span>
						</div>
					)
				}
			}

		}
		return cell
	}

	const buildRow = (trx_id,  ref_block_num, ref_block_pre, actions) => {
		return(
			<TableRow key={uuidv4()} className={classes.tableRow} component={Link} to={`transaction?id=${trx_id}`}>
				<TableCell component="th" scope="row" className={classes.tableCell}>
					{trx_id}
				</TableCell>
				<TableCell align="right" className={classes.tableCell}>
					{ref_block_num}
				</TableCell>
				<TableCell align="right" className={classes.tableCell}>
					{ref_block_pre}
				</TableCell>
				<TableCell align="right" className={classes.tableCell}>
					{actions}
				</TableCell>
			</TableRow>)

	}

	return (
		<>
				{
					props.data.length
						?
						<Card className={classes.card}>
							<div className={classes.content}>
								<Table className={classes.table}>
									<TableHead>
										<TableRow key={uuidv4()} className={classes.tableHead}>
											<TableCell className={classes.headerCell}>Transaction</TableCell>
											<TableCell align="right" className={classes.headerCell}>Reference Block Number</TableCell>
											<TableCell align="right" className={classes.headerCell}>Reference Block Prefix</TableCell>
											<TableCell align="left" className={classes.headerCell}>Actions</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{
											props.data.map((row) => {
												return buildRow(row.trx.id, row.trx.transaction.ref_block_num, row.trx.transaction.ref_block_prefix, row.trx.transaction.actions.length)
											})
										}
									</TableBody>
								</Table>
							</div>
						</Card>
						: <></>
				}
		</>
	);
}

const getConfig = () => {
	const local = {
		apis: {
			google: {
				analytics: {
					trackingId: "UA-145492735-1"
				}
			},
			mythical: {
				hyperionBaseUrl: 'http://127.0.0.1:7777',
				iviBaseUrl: "https://api.dev.iviengine.com",
				iviPlaceholderEnv: "hcihi.p.535",
				keycloak: {
					authUrl: "https://accounts.iviengine.com",
					clientId: "block-explorer-dev",
					realm: "ivi"
				},
			}
		},
		links: {
			account_reset: "https://accounts.mythical.games/realms/mythical-dev/login-actions/reset-credentials",
			dev_docs: "https://docs.dev.mythical.games/docs/doc1",
			api_docs: "https://docs.dev.mythical.games/api.html"
		}
	}
	const dev = {
		apis: {
			google: {
				analytics: {
					trackingId: "UA-145492735-1"
				}
			},
			mythical: {
				hyperionBaseUrl: 'https://hyperion-api.dev.iviengine.com',
				iviBaseUrl: "https://api.dev.iviengine.com",
				iviPlaceholderEnv: "hcihi.d.153",
				keycloak: {
					authUrl: "https://accounts.iviengine.com",
					clientId: "block-explorer-dev",
					realm: "ivi"
				},
			}
		},
		links: {
			account_reset: "https://accounts.mythical.games/realms/mythical-dev/login-actions/reset-credentials",
			dev_docs: "https://docs.dev.mythical.games/docs/doc1",
			api_docs: "https://docs.dev.mythical.games/api.html"
		}
	}
	const dev_testnet = {
		apis: {
			google: {
				analytics: {
					trackingId: "UA-145492735-1"
				}
			},
			mythical: {
				hyperionBaseUrl: 'https://hyperion-api.testnet.dev.iviengine.com',
				iviBaseUrl: "https://api.dev.iviengine.com",
				iviPlaceholderEnv: "hcihi.d.153",
				keycloak: {
					authUrl: "https://accounts.iviengine.com",
					clientId: "block-explorer-dev",
					realm: "ivi"
				},
			}
		},
		links: {
			account_reset: "https://accounts.mythical.games/realms/mythical-dev/login-actions/reset-credentials",
			dev_docs: "https://docs.dev.mythical.games/docs/doc1",
			api_docs: "https://docs.dev.mythical.games/api.html"
		}
	}
	const qa_testnet = {
		apis: {
			google: {
				analytics: {
					trackingId: "UA-145492735-1"
				}
			},
			mythical: {
				hyperionBaseUrl: 'https://hyperion-api.testnet.qa.iviengine.com',
				iviBaseUrl: "https://api.qa.iviengine.com",
				iviPlaceholderEnv: "npcn2.q.413",
				keycloak: {
					authUrl: "https://accounts.iviengine.com",
					clientId: "block-explorer-qa",
					realm: "ivi"
				},
			}
		},
		links: {
			account_reset: "https://accounts.mythical.games/realms/mythical-qa/login-actions/reset-credentials",
			dev_docs: "https://docs.dev.mythical.games/docs/doc1",
			api_docs: "https://docs.dev.mythical.games/api.html"
		}
	}
	const prod = {
		apis: {
			google: {
				analytics: {
					trackingId: "UA-145492735-2"
				}
			},
			mythical: {
				hyperionBaseUrl: 'https://hyperion-api.iviengine.com',
				iviBaseUrl: "https://api.iviengine.com",
				iviPlaceholderEnv: "hcihi.p.535",
				keycloak: {
					authUrl: "https://accounts.iviengine.com",
					clientId: "block-explorer-prod",
					realm: "ivi"
				},
			}
		},
		links: {
			account_reset: "https://accounts.mythical.games/realms/mythical/login-actions/reset-credentials",
			dev_docs: "https://docs.mythical.games/docs/doc1",
			api_docs: "https://docs.mythical.games/api.html"
		}
	}
	const prod_testnet = {
		apis: {
			google: {
				analytics: {
					trackingId: "UA-145492735-2"
				}
			},
			mythical: {
				hyperionBaseUrl: 'https://hyperion-api.testnet.iviengine.com',
				iviBaseUrl: "https://api.iviengine.com",
				iviPlaceholderEnv: "roybk.d.353",
				keycloak: {
					authUrl: "https://accounts.iviengine.com",
					clientId: "block-explorer-prod",
					realm: "ivi"
				},
			}
		},
		links: {
			account_reset: "https://accounts.mythical.games/realms/mythical/login-actions/reset-credentials",
			dev_docs: "https://docs.mythical.games/docs/doc1",
			api_docs: "https://docs.mythical.games/api.html"
		}
	}
	const lt = {
		apis: {
			google: {
				analytics: {
					trackingId: ""
				}
			},
			mythical: {
				authUrl: "https://accounts.lt.blankos.game",
				iviBaseUrl: "https://api.lt.iviengine.com",
				clientId: "ivi-customer-portal-lt",
				realm: "master"
			}
		},
		links: {
			account_reset: "https://accounts.lt.mythical.games/realms/master/login-actions/reset-credentials",
			dev_docs: "https://docs.mythical.games/docs/doc1",
			api_docs: "https://docs.mythical.games/api.html"
		}
	}

	switch(process.env.REACT_APP_ENV) {
		case 'local': return local
		case 'dev': return dev
		case 'dev_testnet': return dev_testnet
		case 'qa_testnet': return qa_testnet
		case 'prod': return prod
		case 'prod_testnet': return prod_testnet
		case 'lt': return lt
		default: return dev
	}
}

const config = getConfig()

export default config

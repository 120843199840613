import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage, setDashboardLayout } from '../../redux/modules/settings'
import Page from '../../components/Page'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import {AccountsList} from "../../components/AccountsList";

const ResponsiveGridLayout = WidthProvider(GridLayout)

const useStyles = makeStyles({
	col: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		'&:last-child': {
			marginLeft: 20,
		}
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	dashContainer: ({ height }) => ({
		marginTop: 20,
		width: '100%',
		height: height - 200
	}),
	envSelect: {
		width: 350,
	},
	page: {
		height: '100%'
	},
	select: {
		marginLeft: 10,
		marginRight: 10
	},
}, {name: "Accounts"})

export default function Accounts() {
	const dispatch = useDispatch()
	const { Settings } = useSelector(state => state)
	const { colorTheme, dashboard, screenDims } = Settings
	const theme = getTheme(colorTheme)
	const classes = useStyles({ height: screenDims.height })
	const breadcrumbs = [
		{
			path: '/',
			label: 'Home'
		},
		{
			path: `/accounts`,
			label: 'Accounts'
		}
	]

	// Set initial activePage and environment
	useEffect(() => {
		dispatch(setActivePage('Accounts'))
	},[dispatch])

	return (
		<Page
			title={copy.pages.accounts.title}
			breadcrumbs={breadcrumbs}
		>

			<AccountsList />

		</Page>
	)
}

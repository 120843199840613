
import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, {fetchBlockDetails, setBlockDetails} from './actions'

import { mythicalFetcher } from '../../utilities'

import { getBlockDetails } from "../../../services/hyperion";

function* getBlockDetailsSaga(action) {
	try {
		const response = yield mythicalFetcher(getBlockDetails, [action.body])
		yield put(setBlockDetails(response, action.uuid))
	} catch(e) {}
}

function* watchFetchBlockDetailsSaga() {
	yield takeLatest(actions.FETCH_BLOCK_DETAILS, getBlockDetailsSaga)
}


export default function* blockDetailsSagas() {
	yield fork(watchFetchBlockDetailsSaga)
}

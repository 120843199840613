import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter } from 'connected-react-router'
import { fork } from 'redux-saga/effects'
import { Account, accountSagas } from './account'
import { Authentication, authenticationSagas } from './authentication'

import { ConnectionIndicator, connectionIndicatorSagas } from './connectionindicator'
import { TransactionList, transactionListSagas } from './transactionlist'
import { BlockList, blockListSagas } from './blocklist'
import { TitlesList, titlesListSagas } from './titleslist'
import { TitleDetails, titleDetailsSagas } from './titledetails'
import { TableRows, tableRowsSagas } from './tablerows'
import { DGoodTableRows, dgoodTableRowsSagas } from './dgoodtablerows'
import { ActionList, actionListSagas } from './actionlist'
import { TransactionDetail, transactionDetailSagas } from './transactiondetail'
import { BlockDetail, blockDetailsSagas } from './blockdetail'
import { DeltaList, deltaListSagas } from './deltaslist'
import { AccountDetail, accountDetailsSagas } from './accountdetails'

import { Settings, settingsSagas } from './settings'

const persistConfig = {
	key: 'root',
	storage,
}

export const createRootReducer = history =>
	combineReducers({
		router: connectRouter(history),
		// persisted: persistReducer(persistConfig, combineReducers({Orgs, Settings})),
		persisted: persistReducer(persistConfig, combineReducers({Settings})),
		// Account, Authentication, Items, Orgs, Partners, Players, Settings, Titles, Transactions, Webhooks
		Account, AccountDetail, Authentication, Settings, ConnectionIndicator, TransactionDetail, TransactionList, BlockList, TitlesList, TitleDetails, TableRows, ActionList, BlockDetail, DeltaList, DGoodTableRows
	})

export function* rootSaga () {
	yield fork(accountSagas)
	yield fork(authenticationSagas)
	yield fork(settingsSagas)
	yield fork(connectionIndicatorSagas)
	yield fork(transactionListSagas)
	yield fork(blockListSagas)
	yield fork(titlesListSagas)
	yield fork(titleDetailsSagas)
	yield fork(tableRowsSagas)
	yield fork(dgoodTableRowsSagas)
	yield fork(actionListSagas)
	yield fork(transactionDetailSagas)
	yield fork(blockDetailsSagas)
	yield fork(deltaListSagas)
	yield fork(accountDetailsSagas)
}

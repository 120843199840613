import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../../components/Card'
import {fetchTableRows} from "../../redux/modules/dgoodtablerows";
import Table from "../Table";

const useStyles = makeStyles(theme => ({
	title: {
		paddingBottom: 15
	},
	noneFound: {
		color: theme.text.mint,
		paddingTop: 15,
		fontSize: theme.typography.fontSize
	}
}))

const TitleNewestDGoods = props => {
	const dispatch = useDispatch()
	const {  DGoodTableRows } = useSelector(state => state)

	// FETCH ITEMS
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)
	const [rowData, setRowData] = useState(null)

	useEffect(() => {
		let body = {
			code: props.code,
			table: 'dgood',
			scope: props.code,
			limit: 10,
			reverse: true,
			json: true
		}

		dispatch(fetchTableRows(body, uuid))
	},[props.account])

	const classes = useStyles()

	const renderRowData = row => {
		const rowData = {
			table: {},
			link: {},
			raw: {}
		}
		Object.keys(row).forEach((key,i) => {
			rowData.raw[key] = row[key]
			rowData.table[key] = row[key]
		})
		return rowData
	}

	const tableData = {
		token_name: { label: 'Token', width: 0.3, sortable: true },
		category: { label: 'Category', width: 0.2, sortable: true },
		serial_number: { label: 'Serial Number', width: 0.3, sortable: true },
		id: { label: 'Global ID', width: .3, sortable: true },
		owner: { label: 'Owner', width: 0.3, sortable: true },
		blank: { label: '', width: 0, sortable: false },
	}

	const setPayloadData = () => {
		setRowData(DGoodTableRows.payload.rows && DGoodTableRows.payload.rows.length ? DGoodTableRows.payload.rows.map(row => renderRowData(row)) : [])
		setPayload(DGoodTableRows.payload)
	}

	return (
		<Card className={`${classes.card} pt-0`}>
			<div className={classes.content}>
				{
					payload !== DGoodTableRows.payload && uuid === DGoodTableRows.uuid
						?
						<>
							{console.log("Setting payload", DGoodTableRows.payload)}

							{setPayloadData(DGoodTableRows.payload)}
						</>
						: <></>
				}
				{
					payload && rowData
						?
							<Table
								label={``}
								downloadble={false}
								expandable={false}
								rowData={rowData}
								tableData={tableData}
								transparent={true}
							/>
						:
						<>
							<Typography className={`${classes.noneFound} ${classes.title}`}>None Found</Typography>
						</>
				}
			</div>
		</Card>
	)
}

export default TitleNewestDGoods
import React from 'react';

import { connect } from 'react-redux';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Fade from "@material-ui/core/Fade";
import Badge from '@material-ui/core/Badge';

const DropdownList = (props) => {
  const [anchorListEl, setAnchorListEl] = React.useState(null);
  const openList = Boolean(anchorListEl);

  const handleClick = (event) => {
    setAnchorListEl(event.currentTarget);
  };

  const handleOrderClose = (state) => {
    if (state !== null) {
      setAnchorListEl(null);
      props.setItem(state);
    } {
      setAnchorListEl(null);
    }
  };

  const currentState = () => {
    return (
      <>
        {
          props.badge
            ?
              <Badge badgeContent={props.items.length} color="primary">
                {
                  props.item
                    ?
                      <>{props.item}</>
                      : <>{props.empty_message} </>
                }

              </Badge>
            :
              <>
                {props.item || props.empty_message}
              </>
        }
      </>
    )
  }

  let category_buttons = [];
  const buildCategoryMenuList = (items) => {
    for (let item of items) {
      category_buttons.push(
        <MenuItem onClick={() => {handleOrderClose(item)}} className={
          item === props.item
            ?
              ` active-item `
            : ``
        }>
           <>{item}</>
        </MenuItem>
      )
    }
    return category_buttons;
  }

  return (
    <>
      <div className={
        props.item === null || props.item === "all" || props.item === "All"
          ?
          `list-menu`
            : `list-menu active-menu`
      }>
        <div aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
          {currentState()}
        </div>
        <Menu
          id="fade-menu"
          anchorEl={anchorListEl}
          keepMounted
          open={openList}
          onClose={() => {handleOrderClose(null)}}
          TransitionComponent={Fade}
        >
          {buildCategoryMenuList(props.items)}
        </Menu>
      </div>
    </>
  );
}

export default connect(
  (router) => ({
    router
  })
)(DropdownList);

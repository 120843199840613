import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const AntTabs = withStyles((theme) => ({
	root: {
		bottom: 0
	},
	indicator: {
		backgroundColor: theme.text.mint,
	},
}))(Tabs);

const AntTab = withStyles((theme) => ({
	root: {
		textTransform: 'none',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing(4),
		outline: "none !important",
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:hover': {
			color: theme.text.secondary,
			opacity: 1,
		},
		'&$selected': {
			color: theme.text.mint,
			fontWeight: theme.typography.fontWeightMedium,
		},
		'&:focus': {
			color: theme.text.mint,
		},
	},
	selected: {},
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	padding: {
		padding: theme.spacing(3),
	},
	demo1: {
		backgroundColor: 'transparent',
	},
}));

export default function CustomizedTabs(props) {
	const classes = useStyles();

	const handleChange = (event, newValue) => {
		props.setTab(newValue);
	};


	return (
		<div className={classes.root}>
			<div className={classes.demo1}>
				<AntTabs value={props.tabValue} onChange={handleChange} aria-label="ant example">
					{
						props.tabs.map((tab) =>{
							return <AntTab label={tab.label}></AntTab>
						})
					}
				</AntTabs>
			</div>
		</div>
	);
}
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {fetchActionList} from "../../redux/modules/actionlist";
import Table from "../Table";
import {makeStyles} from "@material-ui/core/styles";
import TableHyperion from "../TableHyperion";
import Card from "../Card";
import Paginator from "../Paginator";
import Jazzicon from 'react-jazzicon'

const useStyles = makeStyles(theme => ({
	card: {
		width: '100%',
		height: '100%',
		userSelect: 'none',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		height: '100%'
	},
	title: {
		color: theme.text.secondary,
		fontWeight: 600,
		padding: 5,
		position: 'absolute',
		top: 0,
		left: 0
	},
	table: {
		borderCollapse: 'collapse',
		borderSpacing: 0,
		border: '1px solid white',
		borderRadius: 3,
		marginTop: 50,
		overflow: 'hidden',
		width: '100%',
	},
	tableHeader: {
		width: '100%',
		color: 'white',
		backgroundColor: theme.table.header.backgroundColor,
		'& p': {
			display: 'flex',
			paddingLeft: 5,
			fontSize: '0.8em',
			textAlign: 'left'
		},
		'& td:last-child': {
			'& p': {
				justifyContent: 'flex-end',
				paddingRight: 5,
			}
		}
	},
	tableRow: {
		padding: '0 10px'
	},
	cell: {
		padding: 5,
		border: '1px solid white',
		'& p': {
			display: 'flex',
			fontSize: '1em',
		},
		'&:last-child': {
			'& p': {
				justifyContent: 'flex-end'
			}
		}
	},
	seeMore: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		width: '100%',
		bottom: 0,
		'& a': {
			textDecoration: 'none',
		},
		'& p': {
			color: theme.text.highlight,
			cursor: 'pointer',
			fontSize: '1em'
		}
	}
}))

const AccountsList = props => {
	// SETTINGS DATA
	const dispatch = useDispatch()
	const { Settings, ActionList } = useSelector(state => state)
	const classes = useStyles()

	// FETCH DATA
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	// PAGE DATA
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(25)
	const [skip, setSkip] = useState(0)
	const [rowData, setRowData] = useState(null)

	useEffect(() => {
		let body = {
			"action": "newaccount",
			"limit": pageSize,
			"skip": skip
		}

		dispatch(fetchActionList(body, uuid))

	},[dispatch, pageSize, skip])

	const generateGravatar = (s) => {
		let seed = s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

		return (
			<div className={'flex'}>
				<Jazzicon diameter={25} seed={seed} /> <span className={'flex pl-4 items-center'}>{s}</span>
			</div>
		)
	}

	const renderRowData = row => {
		const rowData = {
			table: {},
			raw: {}
		}
		Object.keys(row).forEach((key,i) => {
			rowData.raw[key] = row[key]

			switch(key) {
				case 'timestamp':
					rowData.table[key] = new Date(row[key]).toLocaleString('en-US', { timeZone: 'UTC' })
					break
				case 'act':
					rowData.link = `/account?account_name=${row.act.data.newact}`
					rowData.table['account'] = generateGravatar(row.act.data.newact)
					rowData.table['creator'] = row.act.data.creator
				default:
					rowData.table[key] = row[key]
					break
			}
		})
		return rowData
	}

	const tableData =
		{
			account: {label: 'Account', width: 0.2, sortable: true},
			creator: {label: 'Creator', width: 0.1, sortable: true},
			block_num: { label: 'Block', width: 0.2, sortable: true },
			trx_id: { label: 'Transaction', width: 0.5, sortable: true },
			producer: { label: 'Producer', width: 0.1, sortable: true },
			blank: { label: '', width: 0, sortable: false },
		}

	const setNewPage = (e) => {
		const max = payload['total']['value'];

		switch(e) {
			case 'next':
				if (skip+pageSize < max) {
					setSkip(skip + pageSize)
				}
				break;
			case 'back':
				if (skip+pageSize > pageSize) {
					setSkip(skip - pageSize)
				}
				break;
			case 'first':
				setSkip(0)
				break;
			case 'last':
				if (skip+pageSize < max) {
					let remainder = max - (skip+pageSize)
					if (remainder)
						setSkip(max - remainder)
				}
				break;
			default:
				console.log("no more")
				break;
		}
	}
	const setRowsPerPage = (e) => {
		if (e !== pageSize ) {
			setPageSize(e)
		}
	}
	const setPayloadData = (_payload) => {
		setRowData(_payload.actions && _payload.actions.length ? _payload.actions.map(row => renderRowData(row)) : [])
		setPayload(_payload)
	}

	return (
		<>
			<Card className={classes.card}>
				<div className={classes.content}>						{
					payload !== ActionList.payload && uuid === ActionList.uuid
						?
						<>
							{setPayloadData(ActionList.payload)}
						</>
						: <></>
				}
				{
					payload
						?
						<>
							<div className={"flex justify-end w-full"}>
								<Paginator
									pageBackward={() => {setNewPage("back")}}
									pageForward={() => {setNewPage("next")}}
									start={skip}
									end={skip+pageSize}
								/>
							</div>

							<TableHyperion
								downloadble={true}
								expandable={false}
								rowData={rowData}
								tableData={tableData}
								pageSize={pageSize}
								maxResults={pageSize + skip}
								skip={`${skip}`}
								page={page}
							/>
						</>
						: <>Loading</>

				}
				</div>
			</Card>
		</>

	)
}

export default AccountsList;

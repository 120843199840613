import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import Card from '../../components/Card'
import {Link, NavLink} from "react-router-dom";

const useStyles = makeStyles(theme => ({
	dgoodCard: {
		color: theme.text.secondary,
		borderBottom: `1px solid ${theme.body.darkCard}`,
		fontSize: 12
	},
	secondaryTitle: {
		color: theme.text.secondary,
		fontWeight: 100
	},
	blueLink: {
		color: theme.text.mint,
		fontSize: 15
	},
	white: {
		color: theme.text.primary,
		fontSize: 15
	}
}))

const AssetDisplayCase = props => {
	const classes = useStyles()

	const buildDgoodCase = (dgoods) => {
		dgoods = sort(dgoods)
		let divs = []
		for (let dgood of dgoods) {
			divs.push(
					<div className={`w-full flex justify-between mb-0 p-4 px-6 h-auto ${classes.dgoodCard}`}>
						<div className={`${classes.white} w-1/3`}>{dgood['data']['owner']}</div>
						<NavLink className={`${classes.blueLink} w-1/5`} tag={Link}  to={`/block?id=${dgood['block_num']}`}>
							{dgood['block_num']}
						</NavLink>
						<div className={`${classes.white} w-1/6 text-right`}>{dgood['data']['serial_number']}</div>
					</div>
			)
		}

		return divs.reverse()
	}

	const sort = (array) => {
		return array.sort(function(a, b) {
			var x = parseInt(a['data']['serial_number']);
			var y = parseInt(b['data']['serial_number']);
			return ((x < y) ? -1 : ((x > y) ? 1 : 0));
		});

	}
	return (
    <Card className={`flex flex-wrap w-full h-full`}>
			<div className={`${classes.secondaryTitle} flex w-full justify-between px-6`}>
				<div className={`w-1/3`}>Owner</div>
				<div className={`w-1/5`}>Block</div>
				<div className={`w-1/6 text-right`}>Serial Number</div>
			</div>
			{
				props.latestDgoods.deltas
						?
						<>
							{buildDgoodCase(props.latestDgoods['deltas'])}
						</>
						: <></>
			}
  	</Card>
  );
};


export default AssetDisplayCase;

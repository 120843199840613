import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchActionList, setActionsList } from './actions'

import { mythicalFetcher } from '../../utilities'
import { getActions } from "../../../services/hyperion";

function* fetchActionsListSaga(action) {
	try {
		const response = yield mythicalFetcher(getActions, [action.body])
		yield put(setActionsList(response, action.uuid))
	} catch(e) {}
}

function* watchFetchActionsListSaga() {
	yield takeLatest(actions.REQ_ACTIONS_LIST, fetchActionsListSaga)
}


export default function* actionListSagas() {
	yield fork(watchFetchActionsListSaga)
}

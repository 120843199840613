import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles'
import TableHyperion from '../../components/TableHyperion'
import {fetchTitlesList} from "../../redux/modules/titleslist";
import Card from "../Card";
import Table from "../Table";
import {Dropdown, DropdownButton} from "react-bootstrap";
import Paginator from "../Paginator";

const useStyles = makeStyles(theme => ({

}))

const TitlesList = props => {
	// SETTINGS DATA
	const dispatch = useDispatch()
	const { Settings, TitlesList } = useSelector(state => state)
	const classes = useStyles()

	// FETCH DATA
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	// PAGE DATA
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(25)
	const [skip, setSkip] = useState(0)
	const [rowData, setRowData] = useState(null)

	useEffect(() => {
		let body = {
			"action": "setconfig",
			"limit": pageSize,
			"skip": skip
		}
		dispatch(fetchTitlesList(body, uuid))
	},[dispatch, pageSize, skip])


	const renderRowData = row => {
		const rowData = {
			table: {},
			raw: {},
			link: null
		}

		Object.keys(row).forEach((key,i) => {
			rowData.raw[key] = row[key]

			switch(key) {
			case 'timestamp':
				rowData.table[key] = new Date(row[key]).toLocaleString('en-US', { timeZone: 'UTC' })
				break
			case 'act':
				rowData.link = `/title?contract=${row.act.account}`
				rowData.table['account'] = row.act.account || '-'
				rowData.table['currency'] = row.act.data.currency || '-'
				rowData.table['symbol'] = row.act.data.symbol || '-'
				rowData.table['version'] = row.act.data.version || '-'
			default:
				rowData.table[key] = row[key]
				break
			}
		})
		return rowData
  }

	const tableData = {
		block_num: { label: 'Block', width: 0.2, sortable: true },
		account: { label: 'Title', width: 0.5, sortable: true },
		currency: { label: 'Currency', width: 0.5, sortable: true },
		symbol: { label: 'Symbol', width: 0.5, sortable: true },
		version: { label: 'Version', width: 0.5, sortable: true },
		producer: { label: 'Producer', width: 0.1, sortable: true },
		timestamp: { label: 'Created', width: 0.1, sortable: true },
		blank: { label: '', width: 0, sortable: false },
	}

	const setNewPage = (e) => {
		const max = payload['total']['value'];

		switch(e) {
			case 'next':
				if (skip+pageSize < max) {
					setSkip(skip + pageSize)
				}
				break;
			case 'back':
				if (skip+pageSize > pageSize) {
					setSkip(skip - pageSize)
				}
				break;
			case 'first':
				setSkip(0)
				break;
			case 'last':
				if (skip+pageSize < max) {
					let remainder = max - (skip+pageSize)
					if (remainder)
						setSkip(max - remainder)
				}
				break;
			default:
				console.log("no more")
				break;
		}
	}
	const setRowsPerPage = (e) => {
		if (e !== pageSize ) {
			setPageSize(e)
		}
	}
	const setPayloadData = (_payload) => {
		setRowData(_payload.actions && _payload.actions.length ? _payload.actions.map(row => renderRowData(row)) : [])
		setPayload(_payload)
	}

	return (
		<>
			<Card className={classes.card}>
				<div className={classes.content}>				{
					payload !== TitlesList.payload && uuid === TitlesList.uuid
						?
						<>
							{setPayloadData(TitlesList.payload)}
						</>
						: <></>
				}
				{
					payload
						?
						<>
						<div className={"flex justify-between"}>


							<div className={'flex-grow'}>

							</div>
							<Paginator
								pageBackward={() => {setNewPage("back")}}
								pageForward={() => {setNewPage("next")}}
								start={skip}
								end={skip+pageSize}
							/>
						</div>

						<TableHyperion
							downloadble={true}
							expandable={false}
							rowData={rowData}
							tableData={tableData}
							pageSize={pageSize}
							maxResults={pageSize + skip}
							skip={`${skip}`}
							page={page}
						/>
						</>
						: <>Loading</>
				}

				</div>
			</Card>
		</>

	)
}

export default TitlesList

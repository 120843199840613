import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {v4 as uuidv4} from 'uuid';

import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage, setDashboardLayout } from '../../redux/modules/settings'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import {checkQueryParams} from "../../helpers/check-location";
import {fetchAccountDetails} from "../../redux/modules/accountdetails";
import {AccountDetailsHeader} from "../../components/Cards";
import Page from "../../components/Page";
import {ActionsList} from "../../components/ActionsList";

const ResponsiveGridLayout = WidthProvider(GridLayout)

const useStyles = makeStyles({

	dashContainer: ({ height }) => ({
		width: '100%',
	})
}, {name: "AccountDetails"})

export default function AccountDetails(props) {
	const dispatch = useDispatch()
	const { Settings, AccountDetail } = useSelector(state => state)
	const { colorTheme, account_details, screenDims } = Settings
	const classes = useStyles({ height: screenDims.height })
	const theme = getTheme(colorTheme)

	// FETCH ITEMS
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	// COMPONENT ITEMS

	const [pageSize, setPageSize] = useState(5)
	const [skip, setSkip] = useState(0)
	const [tab, setTab] = useState(0);

	const [page, setPage] = useState({
		pageLabel: null,
		tx_id: null,
		root_breadcrumbs: [
			{
				path: '/',
				page: "home",
				label: 'Home',
			},
			{
				path: `/explore?accounts`,
				page: "accounts",
				label: 'Accounts'
			}
		],
		sub_breadcrumbs: []
	})

	const setUpSubPage = (local) => {
		setPage({
			...page,
			account: local.account,
			pageLabel: local ? local.label : "accountdetails",
			sub_breadcrumbs: local ? [...page.root_breadcrumbs, local.breadcrumb[0]] : []
		})
	}

	// Set initial activePage and environment
	useEffect(() => {
		let local = checkQueryParams(props.location)
		setUpSubPage(local)
		dispatch(setActivePage('AccountDetails'))

		if (local.account) {
			dispatch(fetchAccountDetails({account: local.account}, uuid))
		}
	},[dispatch])

	return (
		<Page
			title={copy.pages.accounts.title}
			className={classes.page}
			breadcrumbs={page.sub_breadcrumbs}
		>
			{
				payload !== AccountDetail.payload && uuid === AccountDetail.uuid
					?
					<>
						{setPayload(AccountDetail.payload)}
					</>
					: <></>
			}
			{
				payload
					?
					<>

						<AccountDetailsHeader
							account={`${page.account.toUpperCase()}`}
							created={payload.account.created}
							ram={payload.account.ram_usage}
							qtime={payload.query_time_ms}
							status={'payload.block_num' ? "Confirmed" : "Unconfirmed"}
							setTab={(e) => {
								setTab(e)
							}}
							tabValue={tab}
						/>
						{
							tab === 0
								?
									<ActionsList
										pageSize={25}
										account={page.account}
									/>
								: tab === 1
									?
									<></>
								: <>none</>
						}
					</>
					: <></>
			}
		</Page>
	)
}

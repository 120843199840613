import actions from './actions'

const initialState = {
	authenticated: false,
	keycloak: {},
	token: null
}

export default function Authentication(state = initialState, action) {

	switch (action.type) {

		case actions.SET_AUTHENTICATED:
			return {
				...state,
				authenticated: action.authenticated
			}

		case actions.SET_KEYCLOAK:
			return {
				...state,
				keycloak: action.keycloak
			}

		case actions.SET_TOKEN:
			return {
				...state,
				token: action.token
			}

		default:
			return state
	}
}
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage } from '../../redux/modules/settings'
import Page from '../../components/Page'
import getTheme from '../../themes'
import {getUrlTitle} from "../../helpers/get-url-title";
import {fetchTitleDetails} from "../../redux/modules/titledetails";
import {TitleHeader} from "../../components/Cards";
import TabPanel from "@material-ui/lab/TabPanel";
import {TitleDashboard} from "../../components";
import {ActionsList} from "../../components/ActionsList";

const ResponsiveGridLayout = WidthProvider(GridLayout)
const useStyles = makeStyles(theme => ({
	col: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		'&:last-child': {
			marginLeft: 20,
		}
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	dashContainer: ({ height }) => ({
		width: '100%',
		height: height - 200
	}),
	envSelect: {
		width: 350,
	},
	page: {
		height: '100%',
	},
	select: {
		marginLeft: 10,
		marginRight: 10
	},
}), {name: "Title"})

export default function Title(props) {
	const dispatch = useDispatch()
	const { Settings, TitleDetails } = useSelector(state => state)
	const { colorTheme, dashboard, title, screenDims } = Settings
	const [tab, setTab] = useState(0);

	let payload = { ...TitleDetails.payload }

	const theme = getTheme(colorTheme)
	const classes = useStyles({ height: screenDims.height })
	const title_name = getUrlTitle(props.location)

	useEffect(() => {
		dispatch(setActivePage('Title'))
		let body = {
			"account": title_name.toLowerCase()
		}
		dispatch(fetchTitleDetails(body))
	},[props.account])

	return (

		<Page title={title}>
			{
				!payload
					?
					<>Loading</>
				: !payload.account
					?
					<>Loading</>
				: payload.account
					?
						<>
								<TitleHeader
									title={title_name}
									created={payload.account.created}
									last_code_update={payload.account.last_code_update}
									head_block_num={payload.account.head_block_num}
									head_block_time={payload.account.head_block_time}
									ram_usage={payload.account.ram_usage}
									query_time_ms={payload.query_time_ms}
									setTab={(e) => {setTab(e)}}
									tabValue={tab}
								/>

								{
									tab === 0
										?
										<TitleDashboard
											account={title_name}
											titleActions={payload.actions.slice(0, 10)}
										/>
									: tab === 1
										?
										<ActionsList
											account={title_name.toLowerCase()}
											pageSize={10}
										/>
									: tab === 2
										?
										<>acc</>
									: tab === 3
										?
										<>del</>
									: tab === 4
										?
										<>strea</>
									: <>none</>
								}
						</>
				: <></>
			}
		</Page>
	)
}

import React from "react";

import "./SubHeader.scss";
import { connect } from "react-redux";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	color: {
		color: theme.text.primary
	},

}))

const SubHeader = props => {
	const classes = useStyles()

	return (
    <div className={`pb-6 font-thin  text-xl ${classes.color}`}>
			{props.text}
  	</div>
  );
};


export default connect(({ router }) => ({
  router
}))(SubHeader);


import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../../components/Card'
import TabBar from '../UI/TabBar'
import ChipList from "../UI/ChipList";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import {useDispatch, useSelector} from "react-redux";

import {addTitleToFavorites} from "../../redux/modules/settings";

const useStyles = makeStyles(theme => ({
	headerCard: {
		width: '100%',
		height: '100%',
		userSelect: 'none',
		background: 'transparent',
		boxShadow: 'none',
	},
	titleNameHeader: {

	},
	favoriteButton: {
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		cursor: "pointer"
	},
	favorited: {
		color: theme.text.primaryRed
	},
	unfavorited: {
		color: theme.text.primary
	},
	title: {
		color: theme.text.primary,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.fontSizeLarge
	},
}))

const TitleHeader = props => {
	const dispatch = useDispatch()
	const classes = useStyles();
	const { Settings } = useSelector(state => state)

	const createChipData = () => {
		return [
			{ key: 0, label: `Age`, text: props.created},
			{ key: 1, label: `Last Code Update`, text: props.last_code_update},
			{ key: 2, label: `RAM`, text: props.ram_usage},
			{ key: 3, label: `As of block `, text: props.head_block_num},
			{ key: 4, label: `Retrieved in `, text: `${props.query_time_ms}ms`},
		]
	};
	const titleTabs = [
		{label: "Dashboard", index: 0},
		{label: "Transactions", index: 1},
		{label: "Accounts", index: 2},
		{label: "Deltas", index: 3},
		{label: "Stream", index: 4},
	]

	const addToFavorites = (title) => {
		dispatch(addTitleToFavorites(title))
	}

	return (
		<Card className={'h-auto pb-24 relative no-outline'}>
			<div className={classes.titleNameHeader}>
				<Typography className={classes.title}>
					{props.title}
					<button className={classes.favoriteButton} onClick={(e) => {addToFavorites(props.title)}}>
						{
							Settings.favoriteTitles.includes(props.title)
								?
								<StarIcon
									className={classes.favorited}
								/>
								:
								<StarBorderIcon
									className={classes.unfavorited}
								/>
						}
					</button>
				</Typography>
			</div>
			<div>
				<ChipList chipData={createChipData()}/>
			</div>
			<TabBar tabValue={props.tabValue} tabs={titleTabs} setTab={(e) => {props.setTab(e)}}/>
		</Card>
	)
}

export default TitleHeader

import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Fuse from 'fuse.js'
import Download from 'downloadjs'
import { makeStyles } from '@material-ui/core/styles'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core'
import { Table, TableBody, TableCell, TableHead, TableFooter, TablePagination, TableRow, TableSortLabel, } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import DownloadIcon from '@material-ui/icons/SaveAlt'
import Button from '../../components/UI/Button'
import TablePaginationActions from './TablePaginationActions'
import { Link } from 'react-router-dom'

const tableRowMargin = 15

const useStyles = makeStyles(theme => ({   
  container: {    
    width: '100%',
    minWidth: 705,
    backgroundColor: theme.table.backgroundColor
  },
  label: {
    marginTop: 5,
    marginRight: 30
  },
  details: {
    fontWeight: 'bold',
    marginBottom: 10
  },
  detailsKey: {
    width: 250,    
    color: theme.text.secondary,
    fontSize: '1em'
  },
  detailsValue: {
    color: theme.text.secondary,
    fontWeight: 'bold',
    overflowWrap: 'break-word',
    maxWidth: 650,
    '& > *': {
      padding: '0px !important'
    },
    '& a': {
      color: theme.text.secondary,
    }
  },
  downloadBtn: {
    color: theme.text.primary,
    display: 'flex',
    float: 'right',    
  },
  downloadIcon: {    
    fontSize: '1.2em',    
  },
  expandIcon: {
    color: theme.text.primary,     
    marginRight: tableRowMargin
  },
  expandableRow: {        
    boxShadow: 'none',
    color: theme.text.primary,     
  },
  expandableRowCell: {     
    '&:last-child': {
      padding: 0
    },
    borderBottom: 0
  },
  expandableTableCell: {    
    border: 'none',  
    color: theme.text.primary,     
    display: 'flex',
    alignItems: 'center',    
    overflow: 'hidden',    
    textOverflow: 'ellipsis',
    paddingLeft: 15,
    boxSizing: 'border-box',
    overflowWrap: 'anywhere',
    '&:first-child': {
      paddingLeft: tableRowMargin,      
    },         
  },
  accordionDetails: {
    backgroundColor: theme.table.rows.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 100
  },  
  accordionSummary: {
    backgroundColor: theme.table.rows.backgroundColor,      
    padding: 0,
    '& content': {
      alignItems: 'flex-start'
    }      
  },
  accordionSummaryContent: {    
    alignItems: 'flex-start',  
    margin: 0,  
  },  
  highlight: {
    backgroundColor: theme.table.highlight.backgroundColor,
  },   
  tableTop: {
    background: theme.table.header.background,
    borderTopLeftRadius: 10,    
    borderTopRightRadius: 10,   
    color: theme.text.primary,  
    display: 'flex',
    flexDirection: 'row',  
    padding: 15,
  	paddingLeft:0,
    justifyContent: 'space-between'
  },  
  tableCell: {    
    border: 'none',  
    color: theme.text.primary,      
    textOverflow: 'ellipsis',
    fontWeight: 100,
    fontSize: '12px',
    padding: 5,
    '&:first-child': {
      paddingLeft: tableRowMargin,      
    }, 
    '&:last-child': {
      textAlign: 'end',
      paddingRight: tableRowMargin,
    }     
  },
  tableRowHead: {
    height: `50px !important`,

  },
  tableHead: {
    background: `linear-gradient(${theme.body.accent2Primaryl4}, ${theme.body.accent2Primaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
    borderBottom: 'none',
    '& tr': {
      borderBottom: 'none',
    },
    '& th': {      
      color: theme.text.primary,  
      border: 'none',  
      lineHeight: '1.2em',
      padding: 0,
      //paddingLeft: 5, 
      textTransform: 'uppercase',
      fontSize: '0.6em',
      fontWeight: 600,
      '&:first-child': {
        paddingLeft: tableRowMargin
      },
      '&:last-child': {        
        paddingRight: tableRowMargin,
        paddingLeft: 0,
        textAlign: 'end',
      }
    },
  },  
  tableRow: {
    background: theme.table.rows.background,

    borderTop: `1px solid ${theme.body.accent2Primaryl4}`,
    height: 50,
    '&:last-child td:first-child': {
      borderBottomLeftRadius: 10      
    },
    '&:last-child td:last-child': {
      borderBottomRightRadius: 10      
    },
    '&:hover': {
      background: `linear-gradient(${theme.body.accent2Primaryl3}, ${theme.body.accent2Primaryl3}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
      cursor: 'pointer',
      transition: theme.overrides.table.transition
    },
    '&:active': {
      backgroundColor: theme.table.rows.activeBackgroundColor,
      transition: theme.overrides.table.transition
    }
  },
  tableSortLabel: {
    color: theme.text.secondary,
    '&:focus': {
      color: theme.text.primary,
      fontWeight: 'bold'
    },
    '&:hover': {
      color: theme.text.primary,
      fontWeight: 'bold'
    }
  },
  pagination: {
    color: theme.text.primary,
    padding: '0 !important',    
    borderBottom: 'none',
	display: 'flex',
	justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5px 0',
    width: '100%'
  },
}))

const stableSort = (data, cmp) =>  
  data.map((row, index) => [row, index])
      .sort((a, b) => {
        const order = cmp(a[0].raw, b[0].raw)    
        return order !== 0 ? order : a[1] - b[1]
      })
      .map(row => row[0])

const getSorting = (order, orderBy) => {
  const desc = (a, b, orderBy) => {
    if(b[orderBy] < a[orderBy]) return -1  
    if(b[orderBy] > a[orderBy]) return 1  
    return 0
  }
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy)
}

const TableComponent = (props) => {
  const { className='', cta, expandable=false, label, downloadable, onExpand, rowData, Settings, tableData } = props
  const { menuOpen } = Settings
  let colWidths = {}
  const minRowsPerPage = 25
  const classes = useStyles()  
  const [page, setPage] = useState(props.page)
  const [rowsPerPage, setRowsPerPage] = useState(minRowsPerPage)
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState(Object.keys(tableData)[0])
  const [searchStr, setSearchStr] = useState('')
  const [expanded, setExpanded] = useState(new Array(rowData.length).fill(false))  
  const [headerWidth, setHeaderWidth] = useState(0)
  const [headerNode, setHeaderNode] = useState(null)
  const headerRef = useCallback(node => {
    if(node){
      setHeaderNode(node)
      setHeaderWidth(node.getBoundingClientRect().width)
    } 
  })
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowData.length - page * rowsPerPage)
  const fuse = new Fuse(rowData, { keys: Object.keys(tableData) })
  const filteredData = searchStr.length ? fuse.search(searchStr) : rowData
  
  const createSortHandler = property => event => handleRequestSort(event, property)
  const handleChangePage = (event, newPage) => props.setPage(newPage)
  const handleChangeRowsPerPage = event => props.setRowsPerPage(parseInt(event.target.value, 10))
  const handleDownload2CSV = () => {        
    const headerArr = Object.keys(rowData[0].raw).map(key => key)    
    const dataArr = rowData.map(row => Object.keys(row.raw).map(col => row.raw[col]))    
    const csvContent = "data:text/csv;charset=utf-8," + headerArr.join(",") + "\n" + dataArr.map(e => e.join(",")).join("\n")    
    Download(csvContent, `${label}.csv`, 'text/plain')
  }
  const handleRequestSort = (event, prop) => {
    const key = Object.keys(tableData)[prop]
    const isDesc = orderBy === key && order === 'desc'
    setOrder(isDesc ? 'asc' : 'desc')
    setOrderBy(key)
  }
  const toggleExpanded = (index, rowObj) => (event, isExpanded) => {    
    setExpanded(expanded.map((row,i) => i === index ? isExpanded : row))    
    isExpanded && onExpand && onExpand(rowObj.raw)
  }

  // Rerender table if dims change
  useEffect(() => {
    let timer    
    if(headerNode){      
      timer = setTimeout(() => {
        const width = headerNode.getBoundingClientRect().width        
        setHeaderWidth(width)
      }, 350)   
    }    
    return () => clearTimeout(timer) 
    
  },[headerNode, menuOpen])

  // Update expanded array if number of rows changes
  useEffect(() => {
    rowData.length !== expanded.length && setExpanded(new Array(rowData.length).fill(false))
  }, [expanded, rowData])
      
  const renderRow = (rowObj, link, index) =>
    <TableRow key={index} component={Link} to={link} className={classes.tableRow}>
      {
        Object.keys(tableData).map((key,i) =>
          <TableCell key={key} className={classes.tableCell} style={{textAlign: tableData[key].align ? tableData[key].align : ''}}>
            {rowObj[key]}
          </TableCell>
        )
      }
    </TableRow>

  const renderExpandableRow = (rowObj, index) =>   
    <TableRow key={index} className={classes.tableRow}>  
      <TableCell className={classes.expandableRowCell} colSpan={Object.keys(tableData).length+1}>
        <Accordion 
          className={`${classes.expandableRow} ${expanded[index] ? classes.highlight : ''}`}          
          expanded={expanded[index] || false}           
          onChange={toggleExpanded(index, rowObj)}
        >
          <AccordionSummary 
            classes={{ content: classes.accordionSummaryContent, expandIcon: classes.expandIcon }} 
            className={`${classes.accordionSummary} ${expanded[index] ? classes.highlight : ''}`} 
            expandIcon={<ExpandMoreIcon />}>  
            { 
              Object.keys(tableData).map((key) => 
                <div key={key} className={classes.expandableTableCell} style={{width: colWidths[key]}}>
                  { rowObj.table[key] }
                </div>
              ) 
            }                            
          </AccordionSummary>
          <AccordionDetails className={`${classes.accordionDetails} ${classes.highlight}`}>
            <Typography className={classes.details}>Details</Typography>                    
            {          
              Object.keys(rowObj.raw).map((key,i) =>
                <div key={i} className={classes.row}>
                  <div className={classes.detailsKey}>{key}</div>
                  <div className={classes.detailsValue}>{rowObj.raw[key]}
                  </div>
                </div>
              )
            }
          </AccordionDetails>
        </Accordion>
      </TableCell>            
    </TableRow>
                
  const renderHeaderRow = () => {      
    const headerRow = Object.keys(tableData).map((key,i) => {      
      colWidths[key] = expandable 
        ? (tableData[key].width * (headerWidth - tableRowMargin * 2)) - ((tableData[key].width * (headerWidth - tableRowMargin * 2)) * 0.1) //- tableRowMargin
        : tableData[key].width * headerWidth
      return ( 
        <TableCell 
          key={i} 
          sortDirection={orderBy === i ? order : false} 
          style={{width: colWidths[key], textAlign: tableData[key].align ? tableData[key].align : ''}}>
          <TableSortLabel 
            className={classes.tableSortLabel}
            hideSortIcon={!tableData[key].sortable} 
            active={orderBy === i} 
            direction={order} 
            onClick={createSortHandler(i)}>
            { tableData[key].label }
          </TableSortLabel>
        </TableCell>
      )
    })

    expandable && headerRow.push(
      <TableCell key={Object.keys(tableData).length+1} align="right" style={{width: headerWidth * 0.1}}>
        Details
      </TableCell>
    )

    return headerRow
  }

  return (
    <div className={`${classes.container} ${className}`}>
    {
      cta || label || downloadable
        ? <div className={classes.tableTop}> 
            {
              cta || label
                ? <div style={{display: 'flex'}}>
                    <Typography className={classes.label} variant="h6">{label}</Typography>                  
                  </div>                      
                : null
            }               
            <div style={{display: 'flex'}}>
              <div className={classes.downloadBtn}>
                { cta && cta }
                { downloadable && rowData.length
                    ? <a download={`${label}.csv`} onClick={() => rowData && rowData.length ? handleDownload2CSV() : null}>
                        <Button 
                          type="contained"                           
                          icon={<DownloadIcon className={classes.downloadIcon} />}
                          disabled={rowData && rowData.length ? false : true} 
                        >                          
                          Download CSV
                        </Button>   
                      </a>
                    : null
                }                    
              </div>        
            </div>
          </div>                 
        : null
    }

      <Table>
        <TableHead  className={`${classes.tableHead} py-4`}>
          <TableRow
            classes={{
              root: classes.tableRowHead
            }}
              ref={headerRef}>
            { 
              rowData && rowData.length ? renderHeaderRow() : null              
            }
          </TableRow>
        </TableHead>
        <TableBody>                   
          { 
            rowData && rowData.length && 
            stableSort(filteredData, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row,i) => expandable ? renderExpandableRow(row,i) : renderRow(row.table,row.link,i) ) || null
          }
          { 
            page > 0 && emptyRows > 0 && (
            <TableRow style={{ height: 41 * emptyRows }}>
              <TableCell colSpan={Object.keys(tableData).length} />
            </TableRow>
            )
          }           
        </TableBody>
      </Table>
    </div>    
  )         
}

TableComponent.propTypes = {   
  className: PropTypes.string,  
  downloadble: PropTypes.bool,
  expandable: PropTypes.bool,
  onExpand: PropTypes.func,  
  rowData: PropTypes.array.isRequired,
  tableData: PropTypes.object.isRequired  
}

const mapStateToProps = ({ dispatch, Settings }) => ({ dispatch, Settings })

export default connect(mapStateToProps)(TableComponent)

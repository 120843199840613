import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchBlockchainConnection, setBlockchainConnection } from './actions'

import { mythicalFetcher } from '../../utilities'

import { getConnection } from "../../../services/hyperion";

function* getBlockchainConnectionSaga(action) {
	try {
		const response = yield mythicalFetcher(getConnection)
		yield put(setBlockchainConnection(response))
	} catch(e) {}
}

function* watchFetchBlockchainConnectionSaga() {
	yield takeLatest(actions.REQ_BLOCKCHAIN_CONNECTION, getBlockchainConnectionSaga)
}


export default function* connectionIndicatorSagas() {
	yield fork(watchFetchBlockchainConnectionSaga)
}

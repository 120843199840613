import React, {useEffect, useState} from 'react'

import Chart from "react-apexcharts";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import { fetchStart, paramsSet } from '../../redux/modules/timedactionchart';

import {LoadingSpinner, ResizableBox} from "components";
import isObjectEmpty from "helpers/is-object-empty";
import { transformData, transformBubbleData, transformBarData } from "./helpers/transform_graph_data";

const TimedActionChart = props => {

	useEffect(()=>{
		let now = new Date().getTime();

		props.paramsSet({
			field: "@timestamp",
			lower_bound: new Date(now - 86400000).toISOString(),
			upper_bound: new Date(now).toISOString(),
			order: "desc",
			action: "range_transactions",
			size: 10000,
			environment: props.environment
		})
		props.fetchStart();

	}, [props.token_name])

	let { timedActionChart: { isFetching, data, params } } = props;
	let { payload={}, error } = data;

	const buildAreaChart = (areaD) => {
		let areaData = transformData(areaD);

		return (
			<Chart
				className={"panel-inner-body"}
				options={areaData}
				series={areaData.series}
				type={"line"}
				width="100%"
				height="135px"
			/>
		)
	}
	return (
		<>

			{
				error ?
					<>
						{!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
						<button onClick={props.pollingStart}>Connection error, click to reload</button>
					</>
				: isFetching
					? <LoadingSpinner />
				: !payload.response
					? <LoadingSpinner />
				: payload.response.status_code === 200
					?
						<>
							{
								payload.response.data.full.aggregations
									?
										<>
											{
												payload.response.data.full.aggregations["@timestamp-agg"].buckets.length > 0
													?
														<>
															{buildAreaChart(payload.response.data.full.aggregations["@timestamp-agg"].buckets)}
														</>
													:
														<>
															{
																buildAreaChart(
																	[
																		{
																			"doc_count": 0,
																			"key": 0
																		}
																	])
															}
														</>

											}
										</>
									:
										<></>

							}
						</>

				: <>Error retrieving</>
			}

		</>
	)
}

export default connect(TimedActionChart);

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'

const useStyles = makeStyles(theme => ({
	card: {
		background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		color: theme.text.primary,
		boxShadow: "none"
	}
}))

export default function CustomCard(props) {
	const { children, className='', ...restProps } = props
	const classes = useStyles()

	return (
		<Card raised={false} className={`flex flex-col justify-between h-48 md:my-16 px-6 py-6 ${classes.card} `} {...restProps}>
			{ children }
		</Card>
	)
}
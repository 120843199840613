
import React, {useState} from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../../components/Card'
import TabBar from '../UI/TabBar'
import ChipList from "../UI/ChipList";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import {useDispatch, useSelector} from "react-redux";

import {addTitleToFavorites} from "../../redux/modules/settings";
import Jazzicon from "react-jazzicon";

const useStyles = makeStyles(theme => ({
	headerCard: {
		width: '100%',
		background: 'transparent',
		boxShadow: 'none',
		userSelect: 'none',
	},
	titleNameHeader: {

	},
	favoriteButton: {
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		cursor: "pointer"
	},
	favorited: {
		color: theme.text.primaryRed
	},
	unfavorited: {
		color: theme.text.primary
	},
	title: {
		color: theme.text.primary,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.fontSizeMedium,
	},
	subTitle:{
		color: theme.text.mint,
		fontWeight: theme.typography.fontWeightMedium,
		margin: 5
	}
}))

const AccountDetailsHeader = props => {
	const dispatch = useDispatch()
	const classes = useStyles();
	const { Settings } = useSelector(state => state)

	const createChipData = () => {
		return [
			{ key: 0, label: `Created`, text: props.created},
			{ key: 1, label: `RAM Usage`, text: props.ram},
			{ key: 2, label: `Query Time`, text: `${props.qtime} ms`}
		]
	};
	const blockTabs = [
		{label: "Transactions", index: 0},
		{label: "Details", index: 1}
	]

	const generateGravatar = (s) => {
		let seed = s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);

		return (
			<div className={'flex items-center'}>
				<Jazzicon diameter={25} seed={seed} /> <span className={'flex pl-4 items-center'}>{s}</span>
			</div>
		)
	}

	return (
		<Card className={'h-auto pb-24 relative no-outline'}>
			<div className={classes.titleNameHeader}>
				<Typography className={classes.title}>
					{generateGravatar(props.account)}
				</Typography>
				<Typography className={classes.subTitle}>
					{props.subtitle}
				</Typography>
			</div>
			<ChipList chipData={createChipData()}/>
			<TabBar tabValue={props.tabValue} tabs={blockTabs} setTab={(e) => {props.setTab(e)}}/>
		</Card>
	)
}

export default AccountDetailsHeader

export const checkQueryParams = (location) => {
	if (location.search) {
		let _label = location.search.replace("?", "")
		let _queries = _label.split("&")
		let breadcrumbs = [];
		let tx_id = undefined;
		let account = undefined;
		let token_name = undefined;
		let token_category = undefined;

		for (let query of _queries) {
			if (query === 'titles') {
				breadcrumbs.push({
					label: "Titles",
					page: "titles",
					path: '?titles'
				})
			} else if (query === 'accounts') {
				breadcrumbs.push({
					label: "Accounts",
					page: "accounts",
					path: '?accounts'
				})
			} else {
				let split = query.split("=")
				if (split[0] === 'id') {
					_label = "id"
					tx_id = split[1]
					breadcrumbs.push({
						label: split[1]
					})
				} else if  (split[0] === "account_name") {
					_label = "account_details"
					account = split[1]
					breadcrumbs.push({
						label: split[1]
					})
				} else if  (split[0] === "name") {
					_label = "token_name"
					token_name = split[1]
					breadcrumbs.push({
						label: split[1]
					})
				} else if  (split[0] === "category") {
					_label = "token_category"
					token_category = split[1]
					breadcrumbs.push({
						label: split[1]
					})
				} else {
					_label = "selected_title"
					breadcrumbs.push({
						label:  split[1].charAt(0).toUpperCase() + split[1].slice(1)
					})
				}

			}
		}

		return {
			label: _label,
			token_category: token_category,
			token_name: token_name,
			queries: _queries,
			breadcrumb: breadcrumbs,
			account: account,
			tx_id: tx_id,
		}
	} else {
		return null
	}
}


import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchTransactionDetails, setTransactionDetails } from './actions'

import { mythicalFetcher } from '../../utilities'

import { getTransactionDetails } from "../../../services/hyperion";

function* getTransactionDetailsSaga(action) {
	try {
		const response = yield mythicalFetcher(getTransactionDetails, [action.body])
		yield put(setTransactionDetails(response, action.uuid))
	} catch(e) {}
}

function* watchFetchTransactionDetailsSaga() {
	yield takeLatest(actions.FETCH_TRANSACTION_DETAILS, getTransactionDetailsSaga)
}


export default function* transactionDetailSagas() {
	yield fork(watchFetchTransactionDetailsSaga)
}

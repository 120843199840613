import { fork, put, takeLatest } from 'redux-saga/effects'
import actions, { setProfile, setProfileImage, updateProfile } from './actions'
import { actions as authenticationActions } from '../authentication/actions'
import { mythicalFetcher } from '../../utilities'
import { getUserTheme, resetPassword, updateProfileData, updateProfileImage } from '../../../services/ivi-engine'

function* getUserSettingsSaga() {
	try {
		/*
		const theme = yield mythicalFetcher(getUserTheme)
		yield console.log('theme: ' + theme)
		*/
	}
	catch(e){
		console.log(e)
	}
}
function* resetPasswordSaga() {
	try {
		yield mythicalFetcher(resetPassword)
	} catch(e) {}
}
function* setKeycloakSaga(action) {
	if(action.keycloak.authenticated && action.keycloak.idTokenParsed !== null){
		// Update profile
		yield put(setProfile(action.keycloak.idTokenParsed))
	}
}
function* setProfileDataSaga(action) {
	try {
		const response = yield mythicalFetcher(updateProfileData, [action.profileData])
		yield put(updateProfile(response))
	} catch(e) { }
}
function* setProfileImageSaga(action) {
	try {
		const response = yield mythicalFetcher(updateProfileImage, [action.imgData])
		yield put(setProfileImage(response.profileImageUrl))
	} catch(e) { }
}
function* watchGetUserSettings() {
	yield takeLatest(actions.GET_USER_SETTINGS, getUserSettingsSaga)
}
function* watchResetPassword() {
	yield takeLatest(actions.REQ_RESET_PASSWORD, resetPasswordSaga)
}
function* watchSetKeycloak() {
	yield takeLatest(authenticationActions.SET_KEYCLOAK, setKeycloakSaga)
}
function* watchSetProfileData() {
	yield takeLatest(actions.REQ_SET_PROFILE_DATA, setProfileDataSaga)
}
function* watchSetProfileImage() {
	yield takeLatest(actions.REQ_SET_PROFILE_IMAGE, setProfileImageSaga)
}
export default function* authenticationSagas() {
	yield fork(watchGetUserSettings)
	yield fork(watchResetPassword)
	yield fork(watchSetKeycloak)
	yield fork(watchSetProfileData)
	yield fork(watchSetProfileImage)
}
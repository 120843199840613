
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TransactionHeaderCard from '../../components/TransactionHeaderCard'
import ChipList from "../UI/ChipList";

const useStyles = makeStyles(theme => ({
	headerCard: {
		width: '100%',
		height: '100%',
		background: 'transparent',
		boxShadow: 'none',
		userSelect: 'none',
	},
	titleNameHeader: {

	},
	favoriteButton: {
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		cursor: "pointer"
	},
	favorited: {
		color: theme.text.primaryRed
	},
	unfavorited: {
		color: theme.text.primary
	},
	title: {
		color: theme.text.primary,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.fontSizeMedium,
	}
}))

const TransactionDetailsHeader = props => {
	const classes = useStyles();
	const createChipData = () => {
		return [
			{ key: 0, label: `Block`, text: props.block},
			{ key: 1, label: `Action Count`, text: props.traces},
			{ key: 2, label: `Status`, text: props.status},
			{ key: 4, label: `Retrieved in `, text: `${props.query_time_ms}ms`},
		]
	};

	const titleTabs = [
		{label: "Details", index: 0},
		{label: "Actions", index: 1}
	]

	return (
		<TransactionHeaderCard className={`${classes.headerCard}`}>
			<div className={classes.titleNameHeader}>
				<Typography className={classes.title}>
					{props.title}
				</Typography>
				<div className={` font-thin m-4 pb-4`}>
					{props.subtitle}
				</div>
			</div>
				<ChipList chipData={createChipData()}/>
		</TransactionHeaderCard>
	)
}

export default TransactionDetailsHeader

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles'
import Table from '../../components/Table'
import {fetchTransactionsList} from "../../redux/modules/transactionlist";
import Typography from "@material-ui/core/Typography";
import Card from "../Card";

const useStyles = makeStyles(theme => ({
	card: {
		width: '100%',
		height: 'fit-content',
		userSelect: 'none',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		height: '100%'
	},
	title: {
		color: theme.text.secondary,
		fontWeight: 600,
		padding: 5,
		position: 'absolute',
		top: 0,
		left: 0
	},
	table: {
		borderCollapse: 'collapse',
		borderSpacing: 0,
		border: '1px solid white',
		borderRadius: 3,
		marginTop: 50,
		overflow: 'hidden',
		width: '100%',
	},
	tableHeader: {
		width: '100%',
		color: 'white',
		backgroundColor: theme.table.header.backgroundColor,
		'& p': {
			display: 'flex',
			paddingLeft: 5,
			fontSize: '0.8em',
			textAlign: 'left'
		},
		'& td:last-child': {
			'& p': {
				justifyContent: 'flex-end',
				paddingRight: 5,
			}
		}
	},
	tableRow: {
		padding: '0 10px'
	},
	cell: {
		padding: 5,
		border: '1px solid white',
		'& p': {
			display: 'flex',
			fontSize: '1em',
		},
		'&:last-child': {
			'& p': {
				justifyContent: 'flex-end'
			}
		}
	},
	seeMore: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		width: '100%',
		bottom: 0,
		'& a': {
			textDecoration: 'none',
		},
		'& p': {
			color: theme.text.highlight,
			cursor: 'pointer',
			fontSize: '1em'
		}
	}
}))

const TransactionsList = props => {
	const dispatch = useDispatch()
	const { Settings, TransactionList } = useSelector(state => state)
	const [uuid, setUuid] = useState(uuidv4())

	const [pageSize, setPageSize] = useState(props.pageSize)
	const classes = useStyles()

	let payload = { ...TransactionList.payload }

	useEffect(() => {
		let body = {
			"account": props.account,
			"pageSize": pageSize
		}

		dispatch(fetchTransactionsList(body, uuid))
	},[dispatch, pageSize])

	const renderRowData = row => {
		const rowData = {
			table: {},
			raw: {},
			link: `/transaction?id=${row.trx_id}`
		}
		Object.keys(row).forEach((key,i) => {
		  rowData.raw[key] = row[key]

		  switch(key) {
			case 'timestamp':
			  rowData.table[key] = new Date(row[key]).toLocaleString('en-US', { timeZone: 'UTC' })
			  break
					case 'act':
						rowData.table['action'] = row.act.name
			default:
			  rowData.table[key] = row[key]
			  break
		  }
		})
		return rowData
  }

	const rowData = payload.data && payload.data.length ? payload.data.map(row => renderRowData(row["_transaction_source"])) : []
	const tableData = props.meta ? {
		action: { label: 'action', width: 0.4, sortable: true },
		timestamp: { label: 'Timestamp', width: 0.4, sortable: true },
		trx_id: { label: 'Transaction ID', width: 0.5, sortable: true },
		block_num: { label: 'Block', width: 0.1, sortable: true },
		global_sequence: { label: 'Global Sequence', width: 0.1, sortable: true },
		blank: { label: '', width: 0.1, sortable: false },
	} : {
		action: { label: 'action', width: 0.4, sortable: true },
		trx_id: { label: 'Transaction ID', width: 0.5, sortable: true },
		block_num: { label: 'Block', width: 0.1, sortable: true },
		blank: { label: '', width: 0.1, sortable: false },
	}

	console.log(tableData)
	console.log(rowData)
	return (
		<>
			<Card className={classes.card}>
				<div className={classes.content}>
					{
						!payload.data
							?
							<>Loading</>
							: payload.data && payload.data.length > 0 && TransactionList.uuid === uuid
							?
							<Table
								label={`Transactions`}
								downloadble={true}
								expandable={false}
								subLabel={props.sub ? `(Last ${props.pageSize} transactions)` : null}
								rowData={rowData}
								tableData={tableData} />

							: <>Loading</>
					}
				</div>
			</Card>
		</>

	)
}

export default TransactionsList

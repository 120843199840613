import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import {makeStyles} from '@material-ui/core/styles'
import Table from '../../components/Table'
import {fetchTableRows} from "../../redux/modules/tablerows";
import {TitleNewestDGoods, TitleLatestActions} from "../Cards";
import GridLayout, {WidthProvider} from "react-grid-layout";
import {TitleTokenContainer} from "../../components/TitleTokenContainer";

const ResponsiveGridLayout = WidthProvider(GridLayout)

const useStyles = makeStyles(theme => ({
	titleDash: {
		width: '100%',
		height: '100%',
		userSelect: 'none',
	},
}))

const TitleDashboard = props => {
	const dispatch = useDispatch()
	const {Settings, TableRows} = useSelector(state => state)
	const {colorTheme, title, screenDims} = Settings

	const classes = useStyles()

	let payload = {...TableRows.payload}

	useEffect(() => {

	}, [dispatch, props.account])

	return (
		<>
			<div className={"p-6 no-outline"}>
				<TitleTokenContainer account={props.account.toLowerCase()}/>
			</div>
			<div className={" flex flex-col p-6 no-outline"}>

				<TitleLatestActions
					titleActions={props.titleActions}
				/>
				<TitleNewestDGoods
					code={props.account.toLowerCase()}
				/>
			</div>
		</>

	)
}

export default TitleDashboard

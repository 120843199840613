
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../../components/Card'

const useStyles = makeStyles(theme => ({
	card: {
		width: '100%',
		height: '100%',
		userSelect: 'none',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		height: '100%'
	},
	title: {
		color: theme.text.secondary,
		fontWeight: 600,
		padding: 5,
		position: 'absolute',
		top: 0,
		left: 0
	},
	table: {
		borderCollapse: 'collapse',
		borderSpacing: 0,
		border: '1px solid white',
		borderRadius: 3,
		marginTop: 50,
		overflow: 'hidden',
		width: '100%',
	},
	tableHeader: {
		width: '100%',
		color: 'white',
		backgroundColor: theme.table.header.backgroundColor,
		'& p': {
			display: 'flex',
			paddingLeft: 5,
			fontSize: '0.8em',
			textAlign: 'left'
		},
		'& td:last-child': {
			'& p': {
				justifyContent: 'flex-end',
				paddingRight: 5,
			}
		}
	},
	tableRow: {
		padding: '0 10px'
	},
	cell: {
		padding: 5,
		border: '1px solid white',
		'& p': {
			display: 'flex',
			fontSize: '1em',
		},
		'&:last-child': {
			'& p': {
				justifyContent: 'flex-end'
			}
		}
	},
	seeMore: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		width: '100%',
		bottom: 0,
		'& a': {
			textDecoration: 'none',
		},
		'& p': {
			color: theme.text.highlight,
			cursor: 'pointer',
			fontSize: '1em'
		}
	}
}))

const ConnectionIndexed = props => {
	const classes = useStyles()

	return (
		<Card className={classes.card}>
			<div className={classes.content}>
				<Typography className={classes.title}>Indexed Data</Typography>
				<table className={classes.table}>
					<tbody>
					<tr className={`${classes.tableHeader} ${classes.tableRow}`}>
						<td><Typography>EVENT</Typography></td>

					</tr>
					{

					}
					</tbody>
				</table>
			</div>
		</Card>
	)
}

export default ConnectionIndexed
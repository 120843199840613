export default {
  components: {
    imageUploader: {
      title: "Upload Image"
    }
  },
  pages: {
    dashboard: {
      title: "Dashboard",
      cards: {
        revenue: {
          label: 'Revenue'
        },
        accounts: {
          label: 'New Accounts'
        },
        issuedItems: {
          label: 'Issued Items'
        },
        soldItems: {
          label: 'Sold Items'
        }
      },
      charts: {
        label: 'Add Chart',
        text: "Add Charts to highlight your most important metrics."
      },
      favorites: {
        label: 'Favorite Pages',
        text: "Bookmark your most frequented pages for quicker access from the dashboard."
      },
      activity: {
        label: 'Recent Activity',
      },
      notifications: {
        label: 'Notifications',
        text: "We'll be in touch here with any updates!"
      }
    },
    info: {
      title: "Blockchain Connection",
      editEnvironment: {
        modal: {
          title: "Edit Environment",
          displayName: {
            label: "Environment Name",
            helperText: "",
            errorText: "Please fill in a valid environment name"
          },
          environmentId: {
            label: "Environment ID"
          },
          environmentType: {
            label: "Environment Type"
          },
          titleName: {
            label: "Title Name",            
          },
        }
      }
    },
    accounts: {
      title: "Accounts"
    },
    transactions: {
      title: "Transactions"
    },
    transactionsDetails: {
      title: "Transaction Details"
    },
    blocks: {
      title: "Blocks"
    },
    titles: {
      title: "Titles"
    },

    account: {
      title: "Account",
      details: {
        title: "My Details",
        picture: {
          cta: "Change picture"         
        },
        updatePassword: {
          cta: "Update password",
          title: "Update Password Email Sent",

        },
        firstName: {
          label: "First Name",
          errorText: "Please fill in a first name"
        },
        lastName: {
          label: "Last Name",
          errorText: "Please fill in a last name"
        },
        email: {
          label: "Email",
          errorText: "Please fill in a valid email"
        },
        email2: {
          label: "Confirm Email",
          errorText: "Email address does not match"
        }
      }

    },
    admin: {
      users: {
        cta: "+ Invite Users",
        text: "Invite additional users to join your organization and assign roles to manage their access to the platform.",
        inviteModal: {
          title: "Invite Users",
          cta: "+ Add Invite",
          text: "Enter the email address for any users that you want to invite to your organization and select a role for each user. You may send multiple invites at once.",
          email: {
            label: "User Email",
            errorText: "Must be a valid email address"
          },
          role: {
            label: "Role",
            errorText: "You must select a role"
          }
        }
      },
      settings: {
        title: "Organization Settings",
        name: {
          label: "Organization Name",
          errorText: "Organization name must be between 1-20 characters"
        },
        envTypes: {
          label: "Environment Instance Type Limits",
          helperText: "Maximum number of environment types allowed for each title"
        }
      }
    },
    partners: {
      title: "Partners",
      text: "Add and manage partners or create revenue sharing agreements for existing partners."
    }
  }  
}
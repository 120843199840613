import baseTheme from './base'

const colorPrimary = '#FE395A'
const colorSecondary = '#11B1B4'

const textPrimary = '#000'
const textSecondary = '#747474'
const textLowlight = '#FFF'

const bgColor1 = '#FFFFFF'
const bgColor2 = '#E3E3E3'
const bgColor3 = '#F4F4F4'
const bgColor4 = '#163A59'
const bgColor5 = '#A2A2A2'

const theme = {
	...baseTheme,
	body: {
		backgroundColor: bgColor3
	},
	buttons: {
		...baseTheme.buttons,
		outlined: {
			...baseTheme.buttons.outlined,
			default: {
				...baseTheme.buttons.outlined.default,
				color: textPrimary,
			},
			disabled: {
				...baseTheme.buttons.outlined.disabled,
				color: textPrimary,
			},
			hover: {
				...baseTheme.buttons.outlined.hover,
				color: textPrimary,
			},
			active: {
				...baseTheme.buttons.outlined.active,
				color: textPrimary,
			}
		}
	},
	card: {
		backgroundColor: bgColor1,
		header: {
			backgroundColor: bgColor4,
			color: textLowlight,
		}
	},
	environments: {
		...baseTheme.environments,
		card: {
			backgroundColor: bgColor3,
		},
	},
	imageUploader: {
		backgroundColor: bgColor5,
	},
	modal: {
		backgroundColor: bgColor1,
	},
	overrides: {
		...baseTheme.overrides,
		MuiCheckbox: { // .MuiCheckbox-colorSecondary.Mui-disabled
			...baseTheme.overrides.MuiCheckbox,
			colorSecondary: {
				'&.Mui-disabled': {
					color: 'rgba(0,0,0,0.25)'
				},
			},
			root: {
				...baseTheme.overrides.MuiCheckbox.root,
				color: textPrimary
			}
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					color: textSecondary
				}
			}
		},
		MuiFormHelperText: {
			root: {
				color: textSecondary,
				'&.Mui-error': {
					color: colorPrimary,
				}
			},
		},
		MuiFormLabel: {
			root: {
				color: textPrimary,
				'&.Mui-disabled': {
					color: textSecondary,
				},
				'&.Mui-focused': {
					color: colorSecondary,
				},
				'&.Mui-error': {
					color: colorPrimary,
				},
			},
		},
		MuiIconButton: {
			root: {
				color: textSecondary,
			},
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottomColor: textSecondary,
				},
				'&:after': {
					borderBottomColor: textSecondary,
				},
				'&:hover:before': {
					borderBottomColor: [textSecondary, '!important'],
				},
				'&.Mui-error:after': {
					borderBottomColor: colorPrimary,
				},
			},
			root: {
				color: textPrimary,
				'&:hover': {
					borderBottom: 'none'
				},
				'& .Mui-disabled': {
					color: textPrimary,
				},
			},
		},
		MuiInputAdornment: {
			root: {
				color: textPrimary,
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: bgColor1,
				color: textPrimary,
			}
		},
		MuiPickersDay: {
			day: {
				color: textPrimary,
			},
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: textSecondary,
			},
			iconButton: {
				backgroundColor: 'none'
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: bgColor4,
			}
		},
		MuiPopover: {
			paper: {
				backgroundColor: bgColor1,
			},
		},
		MuiSelect: {
			icon: {
				color: textPrimary
			}
		},
		MuiTypography: {
			colorTextSecondary: {
				color: textSecondary
			}
		}
	},
	palette: {
		...baseTheme.palette,
		primary: {
			...baseTheme.palette.primary,
			main: colorPrimary,
		},
		secondary: {
			...baseTheme.palette.secondary,
			main: colorSecondary,
		}
	},
	select: {
		backgroundColor: bgColor2,
	},
	table: {
		actions: {
			color: '#C9C9C9'
		},
		header: {
			backgroundColor: bgColor2
		},
		highlight: {
			backgroundColor: bgColor1
		},
		rows: {
			backgroundColor: bgColor1
		}
	},
	tabs: {
		indicator: colorPrimary,
		buttonTabs: {
			root: {
				backgroundColor: '#CCC',
				color: '#666'
			},
			selected: {
				backgroundColor: '#000'
			}
		}
	},
	text: {
		primary: textPrimary,
		secondary: textSecondary,
		highlight: colorSecondary,
		lowlight: textLowlight
	},
}

export default theme
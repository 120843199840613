import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import { v4 as uuidv4 } from 'uuid';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage } from '../../redux/modules/settings'
import Page from '../../components/Page'
import getTheme from '../../themes'
import {checkQueryParams} from "../../helpers/check-location";
import {TokenDetailsHeader} from "../../components/Cards";
import AssetDisplayCase from "../../components/AssetDisplayCase";
import {getDeltas} from "../../services/hyperion";

import config from "../../config";


const useStyles = makeStyles({

	dashContainer: ({ height }) => ({
		width: '100%',
	})

}, {name: "Transactions"})

export default function TokenType(props) {
	const dispatch = useDispatch()
	const { Settings, } = useSelector(state => state)
	const { colorTheme, screenDims } = Settings
	const [tokenStats, setTokenStats] = useState(uuidv4())
	const [latestDgoods, setLatestDgoods] = useState(uuidv4())

	const theme = getTheme(colorTheme)
	const classes = useStyles({ height: screenDims.height })
	const [page, setPage] = useState({
		pageLabel: null,
		tx_id: null,
		root_breadcrumbs: [
			{
				path: '/',
				page: "home",
				label: 'Home',
			},
			{
				path: `/transactions`,
				page: "transactions",
				label: 'Transactions'
			}
		],
		sub_breadcrumbs: []
	})
	const [tab, setTab] = useState(0);

	const setUpSubPage = (local) => {
		setPage({
			...page,
			token_name: local.token_name,
			token_category: local.token_category,
			pageLabel: local ? local.label : "transactiondetails",
			sub_breadcrumbs: local ? [...local.breadcrumb] : []
		})
	}

	// Set initial activePage and environment
	useEffect(() => {
		let local = checkQueryParams(props.location)
		setUpSubPage(local)

		dispatch(setActivePage('TokenType'))
		getDeltas("", {
			table: "dgood",
			code: config.apis.mythical.iviPlaceholderEnv.toLowerCase(),
			scope: config.apis.mythical.iviPlaceholderEnv.toLowerCase(),
			limit: 50,
			skip: 0,
			token_name: local.token_name,
			present: true
		}).then((res) => {
			setLatestDgoods(res)
		})

		getDeltas("", {
			table: "dgoodstats",
			code: config.apis.mythical.iviPlaceholderEnv.toLowerCase(),
			scope: local.token_category,
			limit: 1,
			skip: 0,
			token_name: local.token_name,
			present: true
		}).then((res) => {
			setTokenStats(res)
		})

	},[dispatch, props.location])

	return (
			<Page title={'title'} className={classes.page}>
				{
					tokenStats
							?
							<>
								<TokenDetailsHeader
										title={page.token_name}
										subtitle={page.token_category}
										tokenStats={tokenStats["deltas"]}
								/>
							</>
							:<></>
				}
				{
					latestDgoods
							?
							<>
								<AssetDisplayCase
										latestDgoods={latestDgoods}
								/>
							</>
							:<></>
				}
			</Page>
	)
}

import React, {useEffect, useState} from 'react'

import Chart from "react-apexcharts";

import {connect} from "react-redux";
import {push} from "connected-react-router";
import { fetchStart, paramsSet } from '../../redux/modules/tokentransferactiongraph';

import {LoadingSpinner, ResizableBox} from "components";
import isObjectEmpty from "helpers/is-object-empty";

import { transformData } from "./helpers/transform_graph_data";


const TokenTransferActionGraph = props => {

	useEffect(()=>{
		let now = new Date().getTime();

		props.paramsSet({
			"action": "contract_actions",
			"contract_action": props.action,
			"range_field": "@timestamp",
			"lower_bound": new Date(now - 86400000).toISOString(),
			"upper_bound": new Date(now).toISOString(),
			"order": 'desc',
			"size": 100,
			"token_name": props.token_name,
			"title": props.title,
			"interval": "1H",
			"to": null,
			"from": null,
			"doc_count": 1
		})
		props.fetchStart();

	}, [props.token_name ])

	let { tokenTransferActionGraph: { isFetching, data, params } } = props;
	let { payload={}, error } = data;

	const buildAreaChart = (areaD) => {
		let areaData = transformData(areaD);
		return (
			<Chart
				className={"panel-inner-body"}
				options={areaData}
				series={areaData.series}
				type={"line"}
				width="100%"
				height="200px"
			/>
		)
	}

	return (
		<>
			{
				error ?
					<>
						{!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
						<button onClick={props.pollingStart}>Connection error, click to reload</button>
					</>
				: isFetching
					? <LoadingSpinner />
				: !payload.response
					? <LoadingSpinner />
				: payload.response.status_code === 200
					?
						<>
							{
								payload.response.data.length
									?
										<>
											{
												payload.response.full.aggregations
													?
													<>
														{
															payload.response.full.aggregations[props.title + "-transfers-agg"].buckets.length > 0
																?
																<>
																	{buildAreaChart(payload.response.full.aggregations[props.title + "-transfers-agg"].buckets)}
																</>
																:
																<>
																	{
																		buildAreaChart(
																			[
																				{
																					"doc_count": 0,
																					"key": 0
																				}
																			])
																	}
																</>

														}
													</>
													:
													<>
														{
															buildAreaChart(
																[
																	{
																		"doc_count": 0,
																		"key": 0
																	}
																])
														}
													</>

											}
										</>
									: <>{
										buildAreaChart(
											[
												{
													"doc_count": 0,
													"key": 0
												}
											])
									}</>
							}
						</>

				: <>Error retrieving</>
			}

		</>
	)
}

export default connect(
		({ tokenTransferActionGraphComponent: { tokenTransferActionGraph }, router}) => ({
			tokenTransferActionGraph,
			router
		}),
		{
			fetchStart,
			paramsSet,
			push
		}

)(TokenTransferActionGraph);

import React, { useState }from "react";

import "./TitlesHeader.scss";
import { connect } from "react-redux";

import { ReactDropdown } from "components"

const TitlesHeader = props => {
	const [dropdown, setDropdown] = useState(false)

	const toggleDropdown = () => {
		setDropdown(!dropdown)
	}

  return (
		<>
			<div className={`${
				props.is_transparent
					?
						"titles-header-transparent titles-header "
					:
						"titles-header "
			} flex justify-between flex-wrap ${
				props.margin === "none"
					? ``
					: ` `
			} left-padding-global-35`}>

				<div className="header-component" onClick={(e) => toggleDropdown(true)}>
					{
						props.is_dropdown
							?
								<ReactDropdown text={props.text}/>
							:
								<>
									<div className="header-text">
										{props.text}
									</div>
									{
										props.has_subtitle
											?
												<>
													<button className={"subtitle-div"} disabled>
														{props.subtitle_text}
													</button>
												</>
											: <></>
									}
								</>
					}
				</div>
			</div>
		</>
  );
};


export default connect(({ router }) => ({
  router
}))(TitlesHeader);

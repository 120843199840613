import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import moment from "moment";

const useStyles = makeStyles(theme => ({
	token_button: {
		background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		'&:hover': {
			background: `linear-gradient(${theme.body.accent2Primaryl3}, ${theme.body.accent2Primaryl3}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
			cursor: 'pointer',
			transition: theme.overrides.table.transition
		}
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		//alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		height: '100%',
		'&:last-child': {
			marginRight: 0
		}
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	top_row: {
		maxHeight: "10rem"
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
	},
	divider: {
		borderTop: `1px solid ${theme.body.accent2Primaryl4} !important`,
		margin: '10px 0px',
		width: '100%',
		flex: 1
	},
	icon: ({ color}) => ({
		color: color,
		minWidth: 45,
		width: '20%'
	}),
	issuer: {
		color: theme.text.secondary,
		fontSize: '.7rem',
		display: "flex"
	},
	table_item: {
		display: "flex",
		flexDirection: "row"
	},
	expires: {
		color: theme.text.secondary,
	},
	value_bold: {
		fontWeight: theme.typography.fontWeightMedium,
		paddingLeft: '.5rem',
		fontSize: '.7rem',
		flex: 1,
		display: "flex",
		justifyContent: "flex-end"
	},
	title: {
		color: theme.text.secondary,
		display: 'flex',
		fontSize: '.7rem',
		alignSelf: 'flex-end'
	},
	value: {
		color: theme.text.primary,
		fontSize: '1em',
	}
}))

const DGoodstats = props => {
	const { color, icon, range, title, value } = props
	const classes = useStyles({ color })

	let expires = props.dgoodstats.max_issue_window;

	if (expires === "1970-01-01T00:00:00") {
		expires = "Never"
	} else {
		expires = moment(expires).fromNow()
	}

	return (
		<a href={`/token?name=${props.dgoodstats.token_name}&category=${props.category}`} className={`${classes.token_button} w-1/4  mx-3 px-6 py-2`}>
			<div className={`${classes.col} ${classes.content}`}>
				<div className={`flex flex-col justify-between h-full pt-2`} >
					<div className={``} >
						<div className={`text-base`}>{props.dgoodstats.token_name.toUpperCase()}</div>
					</div>
					{console.log(props.dgoodstats.max_supply.split(' ')[0] === 0)}
					<div className={`flex justify-end`}>
						<div className={`${classes.value} pr-2`}>{props.dgoodstats.issued_supply.split(' ')[0]} </div>
						/
						<div className={`${classes.title} pl-2`}>{props.dgoodstats.max_supply.split(' ')[0] !== 0 ? `${props.dgoodstats.max_supply.split(' ')[0]} Max`: "Unlimited"} </div>
					</div>
				</div>
				<div className={classes.divider}></div>
				<div className={`pb-2`}>
					<div className={classes.table_item}>
						<div className={`flex text-xs ${classes.expires}`}>Issued By</div>
						<div className={`flex pl-4 text-sm flex-grow justify-end`}>{props.dgoodstats.issuer}</div>
					</div>
					<div className={classes.table_item}>
						<div className={`flex text-xs ${classes.expires}`}>Issuance Expires</div>
						<div className={`flex pl-4 text-sm flex-grow justify-end`}>{expires}</div>
					</div>
				</div>
			</div>
		</a>
	)
}

DGoodstats.propTypes = {
	title: PropTypes.string,
	range: PropTypes.string,
	value: PropTypes.string,
	icon: PropTypes.element
}

export default DGoodstats
import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {v4 as uuidv4} from 'uuid';

import {makeStyles} from '@material-ui/core/styles'
import {fetchDeltasList} from "../../redux/modules/deltaslist";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {SubHeader} from "../index";


const useStyles = makeStyles(theme => ({
	selected: {
		color: theme.text.primary,
		background: `linear-gradient(${theme.body.accent2Primaryl4}, ${theme.body.accent2Primaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		'&:hover': {
			backgroundColor: theme.body.darkCard
		}
	},
	category_buttons: {
		height: "100%",
		width: "150px",
		display: "flex",
		flexDirection: "column",
		borderRadius: 0,
	},
	category_button: {
		width: "100%",
		borderRadius: 0,
		borderBottom: `0px solid ${theme.body.accent2Primaryl4} !important`
	},
	buffer_space: {
		flex: 1,
		border: "0px solid !important"
	},
	unselected: {
		color: theme.text.secondary,
		background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		'&:hover': {
			backgroundColor: theme.body.darkCardTrans,
			color: theme.text.primary,

		}
	},
	disabled_skip_button: {
		border: "0px solid !important",
		borderRadius: "none !important"
	},
	skip_button: {
		border: "0px solid !important",
		borderRadius: "0px !important"
	},
	buttonOverride: {
		justifyContent: 'start'
	}
}))

const CategoriesList = props => {
	// SETTINGS
	const classes = useStyles()
	const dispatch = useDispatch()
	const {Settings, DeltaList} = useSelector(state => state)

	// FETCH ITEMS
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	// COMPONENT ITEMS
	const [pageSize, setPageSize] = useState(5)
	const [skip, setSkip] = useState(0)

	useEffect(() => {
		let body = {
			"code": props.account,
			"scope": props.account,
			"table": "categoryinfo",
			"limit": pageSize,
			"skip": skip
		}

		dispatch(fetchDeltasList(body, uuid))
	}, [skip, props.account, props.selectedCategory])


	const pageUp = () => {
		setSkip(skip + pageSize)
	}

	const pageDown = () => {
		setSkip(skip - pageSize)
	}

	const build = (cats) => {
		let builtCats = [];

		cats.map((cat) => {
			builtCats.push(
				<Button
					classes={{
						root: classes.buttonOverride
					}}
					className={`h-12 justify-left flex ${classes.category_button} ${cat.data.category === props.selectedCategory ? classes.selected : classes.unselected}`}
					onClick={() => {
						props.setCategory(cat.data.category)
					}}
				>
					{cat.data.category.toUpperCase()}
				</Button>
			)
		})

		if (builtCats.length < pageSize) {
			builtCats.push(
				<div className={`flex-grow`}></div>
			)
		}

		return(
			<>
					{builtCats}
			</>
		)
	}

	const setNewPayload = () => {
		setPayload(DeltaList.payload)

		if (!props.selectedCategory && DeltaList.payload.deltas.length) {
			props.setCategory(DeltaList.payload.deltas[0].data.category)
		}
	}
	return (
		<>
			<div className={`h-full`}>
				<SubHeader
						text={`Categories`}
				/>
				{
					payload !== DeltaList.payload && uuid === DeltaList.uuid
							?
							<>
								{setNewPayload(DeltaList.payload)}
							</>
							: <></>
				}
				{
					payload
							?
							<div
									className={`${classes.category_buttons} mr-3 h-full`}
							>

								{build(payload.deltas)}

							</div>
							:
							<></>
				}

			</div>

		</>

	)
}

export default CategoriesList

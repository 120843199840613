import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles'

import DashboardHeaderCard from "../DashboardHeaderCard";
import {CategoriesList} from "../../components/CategoriesList";
import {TokenCategoryDisplayCase} from "../../components/TokenCategoryDisplayCase";
import {Typography} from "@material-ui/core";
import {SubHeader} from "../index";

const useStyles = makeStyles(theme => ({
	card: {
		height: '100%',
	},
	titleDash: {
		width: '100%',
		height: '100%',
		userSelect: 'none',

	},
	tokenDash: {
		height: '200px !important',
		padding: 0
	},
	dataValue: {
		flex: 1,
		fontWeight: theme.typography.fontWeightMedium
	},
	emptyList: {
		display: "flex",
		justifyContent: "center",
		height: "100%",
		alignItems: "center",
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.text.tertiary,
		backgroundColor: theme.body.darkCard

	},
	borderRight: {
		borderRight: `1px solid ${theme.body.accent2Primaryl4}`
	}
}))

const TitleTokenContainer = (props) => {
	const classes = useStyles()
	const [account, setAccount] = useState(null);
	const [category, setCategory] = useState(null);

	useEffect(() => {
		if (props.account && props.account !== account) {
			setAccount(props.account)
		}
	}, [props.account])

	const setNewCategory = (newCategory) => {
		if (category !== newCategory) {
			setCategory(newCategory)
		}
	}

	return (
		<>
			<DashboardHeaderCard>
				<div className={`flex flex-col h-inherit ${classes.borderRight}`}>
					<CategoriesList
						setCategory={(e) => {
							setNewCategory(e)
						}}
						selectedCategory={category}
						account={props.account}
					/>
				</div>

				<div className={'flex flex-col w-full'}>
					{
						category
							?
							<TokenCategoryDisplayCase
								account={props.account}
								category={category}
							/>
							:
							<>
								<Typography className={classes.emptyList}>Choose A Category</Typography>
							</>
					}
				</div>
			</DashboardHeaderCard>

		</>
	);
}

export default TitleTokenContainer

import React, { useState, useEffect } from 'react'
import {  useSelector} from 'react-redux'
import {  NavLink, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import MenuIcon from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography'
import UserAvatar from '../UserAvatar'
import MythicalLogo from '../../assets/mythLogo.png'
import SearchBar from "../SearchBar";
import Brightness4Icon from '@material-ui/icons/Brightness4';
import Brightness7Icon  from '@material-ui/icons/Brightness7';

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.appBar.backgroundColor,
		boxShadow: 'none',
		display: 'flex',
		flexDirection: 'row',
		padding: 0,
		height: theme.appBar.height,
		width: '100%',
		position: 'fixed',
		alignItems: 'center',
		justifyContent: 'flex-end',
		zIndex: theme.appBar.zIndex
	},
	iconButton: {
		flex: "1",
		height: 50,
		width: 50
	},
	help: {
		color: theme.sideBar.color,
		fontSize: '1em',
		paddingRight: 25,
		cursor: 'pointer',
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		'&:visited': {
			color: 'inherit'
		}
	},
	brandLogo: {
		width: "50px"
	},
	menuIcon: {
		color: theme.appBar.color,
		position: 'absolute',
		top: 0,
		left: 12
	},
	spanWrapper: {
		display: 'flex',
		flex: 1,
		justifyContent: 'flex-end'
	},
	popover: {
		color: theme.text.secondary,
		padding: '5px 15px',
		display: 'flex',
		flexDirection: 'column',
		'& a': {
			marginTop: 10,
			marginBottom: 10
		},
		'& p': {
			color: theme.text.secondary,
		}
	},
	acctButton: {
		color: theme.appBar.color,
		padding: 0,
		height: "100%",
		paddingRight: 25,
		display: 'flex',
		'&:hover': {
			background: 'none !important'
		}
	},
	profileWrapper: {
		backgroundColor: theme.appBar.backgroundColor,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: '100%',
		borderRadius: 5
	},
	banner: {
		backgroundColor: '#747474',
		color: 'white',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		'& p': {
			fontSize: '1em'
		}
	}
}))

const NavigationBar = props => {
	const { Account, Authentication, Settings } = useSelector(state => state)
	const { profile } = Account
	const { activePage } = Settings

	const classes = useStyles({ activePage })
	const [state, setState] = useState({
		address: null,
		connected: false,
		eth: 0
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setState({ ...state, [anchor]: open });
	};

	const renderAuthed = () =>
		<div className={'flex font-thin items-center justify-end'}>
			<div className={'w-1/6'} color="inherit">
				<UserAvatar />
			</div>
			{ profile &&
			<div>
				{`${profile?.given_name ? profile?.given_name : ''} ${profile?.family_name?.length ? profile.family_name[0] : ''}`}
			</div>
			}
		</div>

	const renderUnAuthed = () =>
			<>
				<div className={''} color="inherit">
					<UserAvatar />
				</div>
				<Typography>LOGIN</Typography>
			</>

	const renderThemer = () => {
			if (props.theme === "DARK") {
				return (
					<>
						<div className={'mx-4'} color="inherit">
							<button onClick={() => {props.updateTheme('LIGHT')}}>
								<Brightness4Icon />
							</button>
						</div>
					</>
				)
			} else {
				return (
					<>
						<div className={'mx-4'} color="inherit">
							<button onClick={() => {props.updateTheme('DARK')}}>
								<Brightness7Icon />
							</button>
						</div>
					</>
				)
			}
	}


	return (
		<>
			<div className={`main-nav z-50 w-full flex h-100 justify-center font-thin`}>
				<nav id="header" className={`w-full text-white justify-center items-center flex `}>
					<div className={`w-full h-full`}>

						<div className="w-full h-full flex  items-center mt-0 md:justify-center">
							<div className={"flex items-center md:hidden  h-full w-50"}>
								<button onClick={toggleDrawer("top", true)} className={`inline-flex h-full w-full items-center justify-center p-2 rounded-md text-white focus:outline-none transition-old duration-150 ease-in-out`}>
									<MenuIcon />
								</button>
								<Drawer anchor={"top"} open={state["top"]} onClose={toggleDrawer("top", false)}>
									<div className={`rounded-lg shadow-md  bg-1-color`}>
										<div className={`rounded-lg shadow-xs overflow-hidden`}>
											<div className={`pr-5 pt-4 flex items-center justify-between`}>

											</div>
										</div>
									</div>
								</Drawer>
							</div>

							<div className={`hidden items-center md:flex w-full h-full`}>
								<div className="flex pr-6 justify-center items-center">
									<NavLink tag={Link}  className="toggleColour text-white no-underline hover:no-underline font-thin text-xl lg:text-2xl" to="/">
										<img className={""} width={"60px"} alt="IVI Engine" src={MythicalLogo} />
									</NavLink>
								</div>
								<div className={`flex justify-start `}>
									<NavLink className={`px-4`} tag={Link}  to={"/"}>
										<button className={`noline-button inline-flex items-center text-sm leading-5 font-thin text-white`}>Dashboard</button>
									</NavLink>
									<NavLink className={`px-4`} tag={Link}  to={"/info"}  >
										<button  aria-controls="simple-menu" aria-haspopup="true"  className={`noline-button inline-flex items-center text-sm leading-5 font-thin  ${classes.menuItem}`} >Info</button>
									</NavLink>
								</div>
								<SearchBar />
								{
									<>
										{renderThemer()}
									</>
								}

							</div>
						</div>
					</div>
				</nav>
			</div>
		</>
	)
}

export default NavigationBar;

import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchTitleDetails, setTitleDetails} from './actions'

import { mythicalFetcher } from '../../utilities'
import { getTitleDetails } from "../../../services/hyperion";

function* fetchTitleDetailsSaga(action) {
	try {
		const response = yield mythicalFetcher(getTitleDetails, [action.body])
		yield put(setTitleDetails(response))
	} catch(e) {}
}

function* watchFetchTitleDetailsSaga() {
	yield takeLatest(actions.REQ_TITLE_DETAILS, fetchTitleDetailsSaga)
}


export default function* titleDetailsSagas() {
	yield fork(watchFetchTitleDetailsSaga)
}

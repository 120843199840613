import React, { useState, useEffect } from 'react';

import { Input } from 'reactstrap';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
	searchBar: {
		backgroundColor: theme.body.darkCard,
	},
	searchBarColor: {
		color: theme.text.primary,
	}
}))

const SearchBar = props => {
  const [inputValue, setInputValue] = useState("");
	const classes = useStyles({  })

  const executeSearch = () => {
  	let search = inputValue;
	  setInputValue("")
    if (search.length === 64) {
		props.dispatch(push(`/transaction?id=${search}`))
	} else {
		props.dispatch(push(`/block?id=${search}`))
	}
  }

  return (
    <>
        <form className={`${classes.searchBar} flex-grow md:ml-24 px-6 h-1/2`}>
            <Input
                className={`${classes.searchBarColor}  w-full h-full bg-trans searchbar-input font-thin`}
                placeholder={"Search Blocks & Transactions"}
                value={inputValue}
                onKeyDown={
                    evt => {
                        if (evt.key === 'Enter') {
							evt.preventDefault();
							executeSearch();
                        }
                    }
                }
                onChange={evt=>{setInputValue(evt.target.value)}}/>
        </form>
    </>

  )
}

export default connect()(SearchBar);

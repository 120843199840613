import "./App.scss";
import "./index.css";

import React, {useEffect, useState} from "react";
import ReactGA from 'react-ga'
import {connect, useSelector} from 'react-redux'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import {withRouter} from "react-router";
import { MuiThemeProvider } from '@material-ui/core/styles'
import NavigationBar from '../components/NavigationBar'
import getTheme from '../themes'

import Dashboard from "pages/Dashboard"
import Info from "pages/Info"
import Blocks from "pages/Blocks"
import BlockDetails from "pages/BlockDetails"
import Transactions from "pages/Transactions"
import TransactionDetails from "pages/TransactionDetails"
import Explore from "pages/Explore"
import Titles from "pages/Titles"
import Title from "pages/Title"
import TokenType from "pages/TokenType"
import Accounts from "pages/Accounts"
import AccountDetails from "pages/AccountDetails"

import { reqLogin } from '../redux/modules/authentication'

import { setScreenDims } from '../redux/modules/settings'
import {fetchBlockchainConnection} from "../redux/modules/connectionindicator";

const App = (props) => {
	const {
		Authentication,
		dispatch,
		history,
		Settings,
		ConnectionIndicator,
		TransactionList,
		BlockList,
		TitlesList,
		TitleDetails,
		TableRows,
		ActionList,
		TransactionDetail,
		BlockDetail,
		DeltaList,
		AccountDetail,
		DGoodTableRows
	} = props
	const { authenticated } = Authentication.keycloak
	const {  errorMsg, showError } = Settings
	const [theme, setTheme] = useState(localStorage.getItem('explorer_theme'))

	const updateTheme = (new_theme) => {
		localStorage.setItem('explorer_theme', new_theme)
		setTheme(localStorage.getItem('explorer_theme'))
	}
	const appTheme = getTheme(theme || updateTheme('DARK'))

	useEffect(() => {
		document.body.style.backgroundColor = appTheme.body.backgroundColor
		return(()=> document.body.style.backgroundColor = null)
	},[appTheme])

	useEffect(() => {
		dispatch(fetchBlockchainConnection())
	}, [dispatch])

		return authenticated
			?<div></div>
			:
			<MuiThemeProvider theme={appTheme}>
				<Router history={history}>
					<div className={'flex flex-col px-4  md:px-0 md:max-w-screen-2xl mx-auto'}>
						<NavigationBar props={props} onAccountClick={() => !authenticated && dispatch(reqLogin())} theme={theme} updateTheme={(new_theme) => {updateTheme(new_theme)}}/>
						<Switch>
							<Route exact path="/" component={Dashboard}/>
							<Route exact path="/info" component={Info}/>
							<Route exact path="/accounts" component={Accounts}/>
							<Route exact path="/account" component={AccountDetails}/>
							<Route exact path="/blocks" component={Blocks}/>
							<Route exact path="/block" component={BlockDetails}/>
							<Route exact path="/transactions" component={Transactions} />
							<Route exact path="/transaction" component={TransactionDetails} />
							<Route exact path="/explore" component={Explore} />
							<Route exact path="/titles" component={Titles} />
							<Route exact path="/title" component={Title} />
							<Route exact path="/token" component={TokenType} />
							<Redirect to="/"/>
						</Switch>
					</div>
				</Router>
			</MuiThemeProvider>

}

const mapStateToProps = ({ Authentication, Settings }) => ({ Authentication, Settings });

export default withRouter(connect(mapStateToProps)(App));

import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, {setAccountDetails} from './actions'

import {mythicalFetcher} from "../../utilities";
import {getAccountDetails} from "../../../services/hyperion";

function* getAccountDetailsSaga(action) {
	try {
		const response = yield mythicalFetcher(getAccountDetails, [action.body])
		yield put(setAccountDetails(response, action.uuid))
	} catch(e) {}
}


function* watchFetchAccountDetailsSaga() {
	yield takeLatest(actions.FETCH_ACCOUNT_DETAILS, getAccountDetailsSaga)
}

export default function* accountDetailsSagas() {
	yield fork(watchFetchAccountDetailsSaga)
}
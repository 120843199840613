import React from 'react';

import ReactJson from "react-json-view";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {Link} from "react-router-dom";
import moment from "moment";

const TransferCards = props => {
	const [open, setOpen] = React.useState(false);

	let jsonTheme = () => {
		if (localStorage.getItem("theme") === "true") {
			return (
				{
					base00: "transparent",
					base01: "#ddd",
					base02: "#ddd",
					base03: "#444",
					base04: "purple",
					base05: "black",
					base06: "black",
					base07: "black",
					base08: "black",
					base09: "rgba(70, 70, 230, 1)",
					base0A: "rgba(70, 70, 230, 1)",
					base0B: "rgba(70, 70, 230, 1)",
					base0C: "rgba(70, 70, 230, 1)",
					base0D: "rgba(70, 70, 230, 1)",
					base0E: "rgba(70, 70, 230, 1)",
					base0F: "rgba(70, 70, 230, 1)"
				}
			)
		} else {
			return (
				{
					base00: "transparent",
					base01: "#ddd",
					base02: "#ddd",
					base03: "white",
					base04: "purple",
					base05: "white",
					base06: "white",
					base07: "white",
					base08: "white",
					base09: "rgba(70, 70, 230, 1)",
					base0A: "rgba(70, 70, 230, 1)",
					base0B: "rgba(70, 70, 230, 1)",
					base0C: "rgba(70, 70, 230, 1)",
					base0D: "rgba(70, 70, 230, 1)",
					base0E: "rgba(70, 70, 230, 1)",
					base0F: "rgba(70, 70, 230, 1)"
				}
			)
		}
	}

	const getCard = (item) => {
		console.log(item)
		return (
			<>
				<tr>
					<td className="text-nowrap">
						<Link
							to={`/transactions?transaction=${item.data.trx_id}`}
						>
							{
								item.data.trx_id.substring(0,5)
								+ "..." +
								item.data.trx_id.substring(item.data.trx_id.length - 5)
							}
						</Link>
					</td>
					<td className="text-nowrap">
						<Link
							to={`/blocks?block=${item.data.block_num}`}
						>
							{item.data.block_num}
						</Link>
					</td>
					<td className="text-nowrap">
						<span>
							{item.id}
						</span>
					</td>
					<td className="text-nowrap" title={item["@timestamp"]}>
						{moment(item.data["@timestamp"]).fromNow()}
					</td>
					<td className="text-nowrap">
						<span>
							{item.data["@transfer"].from}
						</span>
					</td>
					<td className="text-nowrap">
						<span>
							{item.data["@transfer"].to}
						</span>
					</td>
					<td className="text-nowrap">
						<span>
							{item.data.act.data.quantity}
						</span>
					</td>
				</tr>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<ReactJson src={item} theme={jsonTheme()} />
				</Collapse>
			</>
		)
	}

	return (
			<>
				{getCard(props.item)}
			</>
	)
}

export default TransferCards;

import React, { useEffect, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { connect } from 'react-redux';
import {getAbiSnapshot} from "../../services/hyperion";
import config from "../../config";

const HyperionSocketClient = require('@eosrio/hyperion-stream-client').default;

const StreamingDisplayCase = (props) => {
  const [streamData, setStreamData] = useState(null);
  const [clientConnection, setClientConnection] = useState({
    client: new HyperionSocketClient(config.apis.mythical.hyperionBaseUrl, {async: false}),
    streamData: [],
    enabledStreams: [],
    clientStatus: true,
    id: uuidv4()
  })

  useEffect(()=>{
    let params = {
      "contract": config.apis.mythical.iviPlaceholderEnv.toLowerCase(),
      "fetch": true
    };

    getData(params);
  }, [])


  const enableStream = (streams) => {
    reconnect(streams);

    setClientConnection({
      ...clientConnection,
      clientStatus: true,
      enabledStreams: streams,
      streamData: [],
      id: uuidv4()
    })
  }

  const reconnect = (streams) => {
    if (clientConnection.client.online) {
      clientConnection.client.disconnect();
    }
    clientConnection.client.connect();

    clientConnection.client.onConnect = () => {
      setDataStream(streams)
    }
  }

  const setDataStream = (streams) => {
    for (let stream of streams) {
      clientConnection.client.streamActions({
        contract: config.apis.mythical.iviPlaceholderEnv.toLowerCase(),
        action: stream,
        account: '',
        start_from: 0,
        read_until: 0,
        filters: [],
      })
    }
  }

  const enableData = () => {
    clientConnection.client.onData = async (data) => {
      console.log("DATA", data)
      setStreamData(data)
    }
  }

  const getData = (params) => {
    getAbiSnapshot({}, params).then((res) => {
      console.log(res)
      enableData();
      enableStream(['issuepaid', "logcall"])
    })
  }

  return (
    <>


    </>
  );
}
// <>
//   <div className="stream-container-wrapper">
//     <div className="stream-control-panel stream-component">
//       <StreamControlPanel
//           enabled={clientConnection.enabledStreams || ["issuepaid"]}
//           updateStream={(switches) => {
//             enableStream(switches);
//           }}
//           actions={payload.response.abi.actions}/>
//     </div>
//     <div className="stream-container stream-component">
//       <StreamContainer streamData={streamData} title={props.title}/>
//     </div>
//   </div>
// </>
export default connect()(StreamingDisplayCase);

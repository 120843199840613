import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../../components/Card'
import Table from '../../components/Table'
import {fetchBlocksList} from "../../redux/modules/blocklist";

const useStyles = makeStyles(theme => ({
	card: {
		width: '100%',
		height: 'fit-content',
		userSelect: 'none',
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-start',
		overflow: 'hidden',
		position: 'relative',
		width: '100%',
		height: '100%'
	},
	title: {
		color: theme.text.secondary,
		fontWeight: 600,
		padding: 5,
		position: 'absolute',
		top: 0,
		left: 0
	},
	table: {
		borderCollapse: 'collapse',
		borderSpacing: 0,
		border: '1px solid white',
		borderRadius: 3,
		marginTop: 50,
		overflow: 'hidden',
		width: '100%',
	},
	tableHeader: {
		width: '100%',
		color: 'white',
		backgroundColor: theme.table.header.backgroundColor,
		'& p': {
			display: 'flex',
			paddingLeft: 5,
			fontSize: '0.8em',
			textAlign: 'left'
		},
		'& td:last-child': {
			'& p': {
				justifyContent: 'flex-end',
				paddingRight: 5,
			}
		}
	},
	tableRow: {
		padding: '0 10px'
	},
	cell: {
		padding: 5,
		border: '1px solid white',
		'& p': {
			display: 'flex',
			fontSize: '1em',
		},
		'&:last-child': {
			'& p': {
				justifyContent: 'flex-end'
			}
		}
	},
	seeMore: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'absolute',
		width: '100%',
		bottom: 0,
		'& a': {
			textDecoration: 'none',
		},
		'& p': {
			color: theme.text.highlight,
			cursor: 'pointer',
			fontSize: '1em'
		}
	}
}))

const BlocksList = props => {
	const dispatch = useDispatch()
	const { Settings, BlockList } = useSelector(state => state)
	const [pageSize, setPageSize] = useState(props.pageSize)
	const classes = useStyles()

	let payload = { ...BlockList.payload }


	useEffect(() => {
		let body = {
			"pageSize": pageSize
		}

		dispatch(fetchBlocksList(body))
	},[dispatch, pageSize])

	const renderRowData = row => {
		const rowData = {
		  table: {},
		  raw: {},
			link: `/block?id=${row.block_num}`

		}
		Object.keys(row).forEach((key,i) => {
		  rowData.raw[key] = row[key]

		  switch(key) {
			case 'timestamp':
			  rowData.table[key] = new Date(row[key]).toLocaleString('en-US', { timeZone: 'UTC' })
			  break
					case 'act':
						rowData.table['action'] = row.act.name
			default:
			  rowData.table[key] = row[key]
			  break
		  }
		})
		return rowData
  }

	const rowData = payload.data && payload.data.length ? payload.data.map(row => renderRowData(row["_block_source"])) : []
	const tableData = props.meta ? {
		block_num: { label: 'Block', width: 0.2, sortable: true },
		cpu_usage: { label: 'CPU', width: 0.1, sortable: true },
		net_usage: { label: 'NET', width: 0.1, sortable: true },
		block_id: { label: 'Block Hash', width: 0.5, sortable: true },
		producer: { label: 'Producer', width: 0.1, sortable: true },
		blank: { label: '', width: 0, sortable: false },
	} : {
		block_num: { label: 'Block', width: 0.2, sortable: true },
		block_id: { label: 'Block Hash', width: 0.5, sortable: true },
		producer: { label: 'Producer', width: 0.1, sortable: true },
		blank: { label: '', width: 0, sortable: false },
	}

	const getNext = () => {
		// dispatch(fetchTransactionsList(TransactionList.payload["scrollId"]))
		// dispatch(fetchTransactionsList(TransactionList.payload["scrollId"]))
	}


	return (
		<>
			<Card className={classes.card}>
				<div className={classes.content}>
					{
						!payload.data
							?
							<>Loading</>
							: payload.data && payload.data.length > 0
							?
							<Table
								label={`Blocks`}
								downloadble={props.download}
								subLabel={props.sub ? `(Last ${props.pageSize} blocks)` : null}
								expandable={false}
								rowData={rowData}
								tableData={tableData} />

							: <>Loading</>
					}
				</div>
			</Card>

		</>

	)
}

export default BlocksList

const actionPrefix = `AccountDetails/AccountDetails/`;

export const actions = {
	FETCH_ACCOUNT_DETAILS: `FETCH_ACCOUNT_DETAILS`,
	SET_ACCOUNT_DETAILS: `SET_ACCOUNT_DETAILS`
}

export const fetchAccountDetails = (body, uuid) => ({ type: actions.FETCH_ACCOUNT_DETAILS, body, uuid});
export const setAccountDetails = (response, uuid) => ({ type: actions.SET_ACCOUNT_DETAILS, response, uuid });

export default actions
import actions from './actions'
import getTheme from '../../../themes'

const theme = getTheme()

const initialState = {
	activePage: null,
	errorMsg: 'An error Ocurred',
	menuOpen: window.innerWidth > theme.contentWidth ? true : false,
	showError: false,
	showModal: false,
	isMobile: window.innerWidth < theme.contentWidth,
	orientation: window.innerHeight < window.innerWidth ? 'landscape' : 'portrait',
	dashboard: {
		layout: [
			{i: 'chain_state', x: 0, y: 0, w: 8, h: 1, static: true},
			{i: 'chain_resource', x: 8, y: 0, w: 8, h: 1, static: true},
			{i: 'chain_indexed', x: 16, y: 0, w: 8, h: 1, static: true},
			{i: 'action_graph', x: 24, y: 0, w: 6, h: 1, static: true},
			{i: 'mini_block_list', x: 0, y: 1, w: 12, h: 1, static: true},
			{i: 'mini_transaction_list', x: 12, y: 1, w: 12, h: 1, static: true},
		]
	},
	full: {
		layout:[
			{i: 'full_content', x: 0, y: 0, w: 24, h: 9, static: true},
		],
	},
	title: {
		layout:[
			{i: 'title_header', x: 0, y: 0, w: 24, h: 5, static: true},
			{i: 'title_container', x: 0, y: 5, w: 24, h: 9, static: true},
		],
		dashboard_layout: [
			{i: 'title_token_container', x: 0, y: 0, w: 24, h: 2, static: true},
			{i: 'newest_dgoods', x: 0, y: 2, w: 12, static: true},
			{i: 'newest_actions', x: 12, y: 2, w: 12, static: true},
		],
		transaction_panel: [
			{i: 'transaction_panel', x: 0, y: 0, w: 24, static: true},
		],
	},
	transaction_details: {
		layout: [
			{i: 'header', x: 2, y: 0, w: 20, h: 4, static: true},
			{i: 'transaction_container', x: 2, y: 4, w: 20, h: 9, static: true},
		],
		details_layout: [
			{i: 'main_details', x: 0, y: 0, w: 24, h: 4, static: true},
			{i: 'action_details', x: 0, y: 0, w: 24, h: 4, static: true}
		]
	},
	block_details: {
		layout: [
			{i: 'header', x: 2, y: 0, w: 20, h: 5, static: true},
			{i: 'block_container', x: 2, y: 5, w: 20, h: 9, static: true},
			{i: 'transaction_container', x: 12, y: 4, w: 12, h: 9, static: true},
		],
	},
	title_token_container:{
		layout: [
			{i: 'categories', x: 0, y: 0, w: 4, h: 2, static: true},
			{i: 'tokens', x: 4, y: 0, w: 20, h: 2, static: true},
		],
	},
	account_details: {
		layout: [
			{i: 'account_header', x: 2, y: 0, w: 20, h: 5, static: true},
			{i: 'account_container', x: 2, y: 5, w: 20, h: 9, static: true},
		],
	},
	screenDims: {
		width: 1920,
		height: 1080

	},
	favoriteTitles: JSON.parse(localStorage.getItem('favorite_titles')) || [],
	theme: 'LIGHT',
}

export default function Settings(state = initialState, action) {
	switch (action.type) {
		case 'persist/REHYDRATE':
			if(action.payload && action.payload.Settings) {
				const { errors, ...persistedSettings } = action.payload.Settings
				return({ ...state, ...persistedSettings } )
			} else return state

		case actions.SET_ACTIVE_PAGE:
			return {
				...state,
				activePage: action.page
			}

		case actions.SET_ADD_TITLE_TO_FAVORITES:
			return {
				...state,
				favoriteTitles: action.response
			}

		case actions.SET_ERROR_MESSAGE:
			return {
				...state,
				errorMsg: action.message
			}

		case actions.SET_DASHBOARD_LAYOUT:
			return {
				...state,
				dashboard: {
					...state.dashboard,
					layout: action.layout
				}
			}

		case actions.SET_MENU_OPEN:
			return {
				...state,
				menuOpen: action.menuOpen
			}

		case actions.SET_SHOW_ERROR:
			return {
				...state,
				showError: action.showError
			}

		case actions.SET_SHOW_MODAL:
			return {
				...state,
				showModal: action.showModal
			}

		case actions.SET_SCREEN_DIMS:
			return {
				...state,
				isMobile: action.screenDims.width <= theme.contentWidth ? true : false,
				orientation: action.screenDims.height < action.screenDims.width ? 'landscape' : 'portrait',
				screenDims: action.screenDims
			}

		case actions.SET_THEME:
			return {
				...state,
				theme: action.theme
			}

		default:
			return state
	}
}

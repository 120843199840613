import config from '../config.js'

const  { hyperionBaseUrl } = config.apis.mythical

const doFetch = async ({url, method, body}) => {
	const options = {
		method,
		mode: 'cors',
	}
	if(body) {
		options.body = await JSON.stringify(body)
	}

	try {
		let res = await fetch(url, options)
		//console.log('HTTPS response status: ', res.status, res, options)

		return res.status === 204 || res.status === 404 || res.status === 500
			? res
			: res.json()

	} catch(error) {
		console.error(error)
	}
}

export const getAbiSnapshot = async({token, environmentId}, body) => {
	const url = `${hyperionBaseUrl}/v2/history/get_abi_snapshot?${returnActionQueryParams(body)}`
	const method = 'GET'
	const response = await doFetch({url, method})
	return response
}

export const getAccountDetails = async({token, environmentId}, body) => {
	const url = `${hyperionBaseUrl}/v2/state/get_account?${returnActionQueryParams(body)}`
	const method = 'GET'
	const response = await doFetch({url, method})
	return response
}

export const getActions = async({token, environmentId}, body) => {
	const url = `${hyperionBaseUrl}/v2/history/get_actions?${returnActionQueryParams(body)}`
	const method = 'GET'
	const response = await doFetch({url, method})
	return response
}

export const getDeltas = async({token, environmentId}, body) => {
	const url = `${hyperionBaseUrl}/v2/history/get_deltas?${returnDeltasQueryParams(body)}`
	const method = 'GET'
	const response = await doFetch({url, method})
	return response
}

export const getTableRows = async(params, body) => {
	const url = `${hyperionBaseUrl}/v1/chain/get_table_rows`
	const method = 'POST'
	const response = await doFetch({url, method, body})
	return response
}

export const getConnection = async() => {
	const url = `${hyperionBaseUrl}/v2/health`
	const method = 'GET'
	const response = await doFetch({url, method})
	return response
}

export const getTransactionDetails = async(params, body) => {
	const url = `${hyperionBaseUrl}/v2/history/get_transaction?id=${body.id}`
	const method = 'GET'
	const response = await doFetch({url, method})
	return response
}

export const getBlockDetails = async(params, body) => {
	const url = `${hyperionBaseUrl}/v1/chain/get_block`
	const method = 'POST'
	const response = await doFetch({url, method, body})
	return response
}

/******** Titles ********/

export const getTitleDetails = async({token, environmentId}, body) => {
	const url = `${hyperionBaseUrl}/v2/state/get_account?account=${body.account}`
	const method = 'GET'

	const response = await doFetch({url, method})
	return response
}

export const getTitles = async({token, environmentId}, body) => {
	const url = `${hyperionBaseUrl}/v2/history/get_actions?${returnActionQueryParams(body)}`
	const method = 'GET'

	const response = await doFetch({url, method})
	return response
}

const returnActionQueryParams = (body) => {
	let str = ''

	if ('account' in body) {
		if (body.account !== undefined) {
			str = str.concat(`account=${body.account}`)
		}
	}

	if ('contract' in body) {
		if (body.contract !== undefined) {
			str = str.concat(`contract=${body.contract}`)
		}
	}


	if ('fetch' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`fetch=${body.fetch}`)
	}

	if ('filter' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`filter=${body.filter}`)
	}

	if ('action' in body) {
		if (body.action !== undefined) {
			str = str.concat(`${str.length ? '&': ''}`)

			if (body.action === "transfernft") {
				str = str.concat(`act.name=transfer&memo=sale of dgood`)

			} else {
				str = str.concat(`act.name=${body.action}`)
			}
		}
	}

	if ('skip' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`skip=${body.skip}`)
	}

	if ('limit' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`limit=${body.limit}`)
	}

	if ('sort' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`sort=${body.sort}`)
	}

	if ('token_name' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`act.data.token_name=${body.token_name}`)
	}

	return str

}

const returnDeltasQueryParams = (body) => {
	let str = ''

	if ('code' in body) {
		str = str.concat(`code=${body.code}`)
	}

	if ('scope' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`scope=${body.scope}`)
	}

	if ('table' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`table=${body.table}`)
	}

	if ('limit' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`limit=${body.limit}`)
	}

	if ('skip' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`skip=${body.skip}`)
	}
	
	if ('payer' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`payer=${body.payer}`)
	}

	if ('token_name' in body) {
		str = str.concat(`${str.length ? '&': ''}`)
		str = str.concat(`data.token_name=${body.token_name}`)
	}

	return str

}


import React, {useState} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TokenHeaderCard from '../../components/TokenHeaderCard'
import ChipList from "../UI/ChipList";
import {useDispatch, useSelector} from "react-redux";
import StreamingDisplayCase from "../StreamingDisplayCase/StreamingDisplayCase";
import IssuedPieChart from "../IssuedPieChart";

const useStyles = makeStyles(theme => ({
	headerCard: {
		width: '100%',
		height: '100%',
		background: 'transparent',
		boxShadow: 'none',
		userSelect: 'none',
		display: 'flex',
		justifyContent: 'space-between'
	},
	titleNameHeader: {

	},
	favoriteButton: {
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		cursor: "pointer"
	},
	favorited: {
		color: theme.text.primaryRed
	},
	unfavorited: {
		color: theme.text.primary
	},
	title: {
		color: theme.text.primary,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.fontSizeMedium,
	},
	subtitle: {
		color: theme.text.primary
	}
}))

const TokenDetailsHeader = props => {
	const dispatch = useDispatch()
	const classes = useStyles();
	const { Settings } = useSelector(state => state)

	const createChipData = () => {
		let max_supply = props.tokenStats[0]['data']['max_supply'];
		if (max_supply === "0 DGOODS") {
			max_supply = "Unlimited"
		}
		return [
			{ key: 0, label: `As Of Block`, text: props.tokenStats[0]['block_num']},
			{ key: 1, label: `Issued Supply`, text: props.tokenStats[0]['data']['issued_supply']},
			{ key: 2, label: `Max Supply`, text: max_supply},
		]
	};
	const createBoolChipData = () => {
		let chips = []
		console.log(props.tokenStats)

		if (props.tokenStats[0]["data"]['burnable']) {
			chips.push(
					{ key: chips.length+1, label: `Burnable`, text: ``}
			)
		}
		if (props.tokenStats[0]["data"]['sellable']) {
			chips.push(
					{ key: chips.length+1, label: `Sellable`, text: ``}
			)
		}
		if (props.tokenStats[0]["data"]['transferable']) {
			chips.push(
					{ key: chips.length+1, label: `Transferable`, text: ``}
			)
		}
		if (props.tokenStats[0]["data"]['fungible']) {
			chips.push(
					{ key: chips.length+1, label: `Fungible`, text: ``}
			)
		}


		return chips
	};

	return (
		<TokenHeaderCard className={`${classes.headerCard} pr-12`}>
			<div className={'flex flex-col'}>
				<div className={classes.titleNameHeader}>
					<Typography className={classes.title}>
						{props.title}
					</Typography>
					<div className={`${classes.subtitle} font-thin my-4 pb-4`}>
						Category	<span className={'font-semibold'}>{props.subtitle}</span>
					</div>
				</div>
				<ChipList chipData={
					props.tokenStats ?
							createBoolChipData()
							: []
				}/>
				<ChipList chipData={
					props.tokenStats ?
							createChipData()
							: []
				}/>
			</div>

			<IssuedPieChart data={
				props.tokenStats ?
						{
							"issued": parseInt(props.tokenStats[0]['data']['issued_supply'].split(' ')[0]),
							"max": parseInt(props.tokenStats[0]['data']['max_supply'].split(' ')[0])
						}
						: {
							"issued": null,
							"max": 0
						}
			}/>
		</TokenHeaderCard>
	)
}

export default TokenDetailsHeader

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { connect } from 'react-redux';
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import 'react-multi-carousel/lib/styles.css';
import { push } from 'connected-react-router';
import MenuItem from "@material-ui/core/MenuItem";
import isObjectEmpty from 'helpers/is-object-empty';
import { fetchStart, paramsSet } from '../../redux/modules/actionlistmenu';

import { LoadingSpinner } from 'components';

const ActionListMenu = (props) => {
	const [anchorListEl, setAnchorListEl] = useState(null);

	const openList = Boolean(anchorListEl);

	useEffect(()=>{
		let params = {
			contract: props.title,
			fetch: true
		};

		props.paramsSet(params);
		props.fetchStart();

	}, [])

	let { actionListMenu: { isFetching, data, params } } = props;
	let { payload={}, error } = data;

	const handleClick = (event) => {
		setAnchorListEl(event.currentTarget);
	};

	const handleOrderClose = (state) => {
		if (state !== null) {
			setAnchorListEl(null);
			props.setAction(state);
		} {
			setAnchorListEl(null);
		}
	};

	const currentState = () => {
		return props.action || "All";
	}

	let action_items = [];
	const buildMenuList = (actions) => {
		console.log("ACTIONS: ", actions)
		for (let action of actions) {
			action_items.push(
				<MenuItem onClick={() => {handleOrderClose(action.name)}} className={
					action.name === props.action
						?
							` active-item `
						: ``
				}>
					{action.name}
				</MenuItem>
			)
		}

		action_items.unshift(
			<MenuItem onClick={() => {handleOrderClose("all")}} className={
				props.action === null || props.action === "all" || props.action === "All"
					?
						` active-item `
					: ``
			}>
				All
			</MenuItem>
		)
		return action_items
	}

	return (
		<>
			<div className={
				props.action === null || props.action === "all" || props.action === "All"
					?
						`filter-cell`
						: `filter-cell active-menu`
			}>
				<div aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
					{currentState()}
				</div>
				{ error
					?
						<>
							{!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
							<button onClick={props.fetchStart}>Connection error, click to reload</button>
						</>
					: isFetching
						? <></>
					: (payload === undefined && payload === null && payload)
						? <></>
					: (payload.response === undefined)
						? <></>
					:
						<>
							<Menu
								id="fade-menu"
								anchorEl={anchorListEl}
								keepMounted
								open={openList}
								onClose={() => {handleOrderClose(null)}}
								TransitionComponent={Fade}
							>
								{buildMenuList(payload.response.abi.actions)}
							</Menu>
						</>
				}
			</div>
		</>
	);
}

export default connect(
({ actionListMenuComponent: { actionListMenu }, router}) => ({
	actionListMenu,
	router
}),
{
	fetchStart,
	paramsSet,
	push
}

)(ActionListMenu);

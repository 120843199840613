import actions from './actions'

const initialState = {
	payload: [],
	uuid: undefined,
	error: undefined
}

export default function TransactionDetail(state = initialState, action) {
	switch (action.type) {

		case `SET_TRANSACTION_DETAILS`:
			return {
				...state,
				payload: action.response,
				uuid: action.uuid,
				error: undefined
			};

		default:
			return state;
	}
}
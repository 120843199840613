
export const actions = {
	FETCH_TRANSACTION_DETAILS: `FETCH_TRANSACTION_DETAILS`,
	SET_TRANSACTION_DETAILS: `SET_TRANSACTION_DETAILS`,
}

export const fetchTransactionDetails = (body, uuid) => ({ type: actions.FETCH_TRANSACTION_DETAILS, body, uuid });
export const setTransactionDetails = (response, uuid) => ({ type: actions.SET_TRANSACTION_DETAILS, response, uuid });


export default actions
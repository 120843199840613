import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage, setDashboardLayout } from '../../redux/modules/settings'
import Page from '../../components/Page'
import {TransactionsList} from '../../components/TransactionsList'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import {ActionsList} from "../../components/ActionsList";


const ResponsiveGridLayout = WidthProvider(GridLayout)

const useStyles = makeStyles({
	col: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		'&:last-child': {
			marginLeft: 20,
		}
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	dashContainer: ({ height }) => ({
		marginTop: 20,
		width: '100%',
		height: height - 200
	}),
	envSelect: {
		width: 350,
	},
	page: {
		height: '100%'
	},
	select: {
		marginLeft: 10,
		marginRight: 10
	},
}, {name: "Transactions"})

export default function Transactions() {
	const dispatch = useDispatch()
	const { Settings } = useSelector(state => state)
	const { colorTheme, dashboard, screenDims } = Settings
	const theme = getTheme(colorTheme)
	const classes = useStyles({ height: screenDims.height })
	const layout = [
		{i: 'rpc_data', x: 0, y: 0, w: 1, h: 3, minW: 1, static: true}
	];

	const breadcrumbs = [
    {
      path: '/',
      label: 'Home'
    },
    {
      path: `/transactions`,
      label: 'Transactions'
    }
  ]

	// Set initial activePage and environment
	useEffect(() => {
		dispatch(setActivePage('Transactions'))
	},[dispatch])

	return (
		<Page
			title={copy.pages.transactions.title}
			breadcrumbs={breadcrumbs}
		>
			<ActionsList
				pageSize={25}
			/>
		</Page>
	)
}

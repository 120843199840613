import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import TagFacesIcon from '@material-ui/icons/TagFaces';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		listStyle: 'none',
		boxShadow: 'none',
		backgroundColor: 'transparent',
		paddingLeft: 0,
	},
	label: {
		color: theme.text.secondary
	},
	chip: {
		margin: 0,
		padding: 4,
		background: `linear-gradient(${theme.body.accent2Primaryl3}, ${theme.body.accent2Primaryl3}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		color: theme.text.primary,
		justifyContent: "center",
		alignItems: "center"
	},
	clickableChip: {
		margin: 0,
		padding: 4,
		background: `linear-gradient(${theme.body.accent2Primaryl4}, ${theme.body.accent2Primaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		color: theme.text.mint,

		"&:hover": {
			backgroundColor: theme.body.backgroundColorGradientCorner
		}
	},
	boolChip: {
		border: `1px solid ${theme.text.mint}`,
		backgroundColor: theme.body.darkCard,

		paddingLeft: 8

	}
}));

const ChipList = props => {
	const classes = useStyles();

	return (
		<Paper component="ul" className={`${classes.root} mb-4 `}>
			{props.chipData.map((data) => {

				return (
					<li key={data.key} className={`mr-2 `}>
						<Chip
							icon={<p className={classes.label}>{data.label}</p>}
							label={data.text !== '' ? data.text : null}
							className={
								`${data.label === "Block" || data.label === "As Of Block" 
										? classes.clickableChip 
									: data.label === "Burnable" || data.label === "Transferable" ||data.label === "Sellable" 
										?
											classes.boolChip
								: classes.chip}`
							}
							clickable={data.label === "Block"  || data.label === "As Of Block" ? true : false}
							component={data.label === "Block" || data.label === "As Of Block" ? "a" : "div"}
							href={`/block?id=${data.text}`}
						/>
					</li>
				);
			})}
		</Paper>
	);
}


export default ChipList

import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';


import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import { LoadingSpinner } from 'components';
import { push } from 'connected-react-router'
import isObjectEmpty from 'helpers/is-object-empty';
import { fetchStart, paramsSet } from '../../redux/modules/deltaslistmenu';
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const DeltasListMenu = (props) => {
  const [anchorListEl, setAnchorListEl] = React.useState(null);
  const openList = Boolean(anchorListEl);

  useEffect(()=>{
    let params = {
      contract: props.title,
      fetch: true
    };

    props.paramsSet(params);
    props.fetchStart();

  }, [])

  let { deltasListMenu: { isFetching, data, params } } = props;
  let { payload={}, error } = data;

  const handleClick = (event) => {
    setAnchorListEl(event.currentTarget);
  };

  const handleOrderClose = (state) => {
    if (state !== null) {
      setAnchorListEl(null);
      props.setTable(state);
    } {
      setAnchorListEl(null);
    }
  };

  const currentState = () => {
    return props.table || "All";
  }

  let delta_items = [];
  const buildMenuList = (tables) => {
    for (let table of tables) {
      delta_items.push(
          <MenuItem onClick={() => {handleOrderClose(table.name)}} className={
            table.name === props.table
              ?
                ` active-item `
              : ``
          }>
            {table.name}
          </MenuItem>
      )
    }

    delta_items.unshift(
        <MenuItem onClick={() => {handleOrderClose("all")}} className={
          props.table === null || props.table === "all" || props.table === "All"
              ?
              ` active-item `
              : ``
        }>
          All
        </MenuItem>
    )
    return delta_items
  }

  return (
      <>
        <div className={
          props.action === null || props.action === "all" || props.action === "All"
            ?
              `nav-item`
            : `nav-item active-menu`
        }>
          <div aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
            {currentState()}
          </div>
          { error
            ?
            <>
              {!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
              <button onClick={props.fetchStart}>Connection error, click to reload</button>
            </>
            : isFetching
              ? <></>
            : (payload === undefined && payload === null && payload)
                ? <></>
            : (payload.response === undefined)
                ? <></>
            :
              <>
              <Menu
                  id="fade-menu"
                  anchorEl={anchorListEl}
                  keepMounted
                  open={openList}
                  onClose={() => {handleOrderClose(null)}}
                  TransitionComponent={Fade}
              >
                {buildMenuList(payload.response.abi.tables)}
              </Menu>
              </>
          }
        </div>
      </>
  );
}

export default connect(
    ({ deltasListMenuComponent: { deltasListMenu }, router}) => ({
      deltasListMenu,
      router
    }),
    {
      fetchStart,
      paramsSet,
      push
    }

)(DeltasListMenu);

import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid';

import moment from "moment";
import Card from "../Card";
import GridLayout, {WidthProvider} from "react-grid-layout";
import {useSelector} from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

const ResponsiveGridLayout = WidthProvider(GridLayout)
const tableRowMargin = 15

const useStyles = makeStyles(theme => ({
	card: {
		height: 'auto',

	},
	titleDash: {
		width: '100%',
		height: '100%',
		userSelect: 'none',

	},
	tableRow: {
		background: theme.table.rows.background,
		borderBottom: `1px solid ${theme.body.accent2Primaryl4}`,
		display: 'flex',
		alignItems: 'center',
		minHeight: 50
	},
	tableCell: {
		flex: 1,
		border: 'none',
		color: theme.text.primary,
		textAlign: 'left',
		textOverflow: 'ellipsis',
		fontSize: '12px',
		padding: 5,
		'&:first-child': {
			paddingLeft: tableRowMargin,
		},
		'&:last-child': {
			paddingRight: tableRowMargin,
		}
	},
	cellData: {
		display: 'flex',
		textAlign: 'left',
		marginBottom: '10px',
		marginTop: '10px'
	},
	dataKey: {
		flex: 1
	},
	dataValue: {
		flex: 1,
		fontWeight: theme.typography.fontWeightMedium
	}
}))

export default function Blockdetail (props) {
	const classes = useStyles()
	const { Settings } = useSelector(state => state)

	const buildRow = (key, value) => {
		return(
			<TableRow key={uuidv4()} className={classes.tableRow}>
				<TableCell component="th" scope="row" className={classes.tableCell}>
					{key}
				</TableCell>
				<TableCell align="right" className={`${classes.dataValue} ${classes.tableCell}`}>
					{value}
				</TableCell>
			</TableRow>)

	}

	return (
		<>

			<Card className={classes.card}>
				<div className={classes.content}>
					<Table className={classes.table}>
						<TableBody>
							{buildRow("Timestamp", props.data.timestamp)}
							{buildRow("Block ID", props.data.id)}
							{buildRow("Action MRoot", props.data.action_mroot)}
							{buildRow("Transaction MRoot", props.data.transaction_mroot)}
							{buildRow("", "")}

							{buildRow("Producer", props.data.producer)}

							{buildRow("Producer Sig", props.data.producer_signature)}

						</TableBody>
					</Table>
				</div>
			</Card>

		</>
	);
}


import React, {useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage } from '../../redux/modules/settings'
import Page from '../../components/Page'
import {Actiondetails} from '../../components/Actiondetails'
import getTheme from '../../themes'
import {checkQueryParams} from "../../helpers/check-location";
import {fetchTransactionDetails} from "../../redux/modules/transactiondetail";
import {TransactionDetailsHeader} from "../../components/Cards";

const useStyles = makeStyles({

	dashContainer: ({ height }) => ({
		width: '100%',
	})

}, {name: "Transactions"})

export default function TransactionDetails(props) {
	const dispatch = useDispatch()
	const { Settings, TransactionDetail } = useSelector(state => state)
	const { colorTheme, transaction_details, screenDims } = Settings
	const [uuid, setUuid] = useState(uuidv4())

	const theme = getTheme(colorTheme)
	const classes = useStyles({ height: screenDims.height })
	const [page, setPage] = useState({
		pageLabel: null,
		tx_id: null,
		root_breadcrumbs: [
			{
				path: '/',
				page: "home",
				label: 'Home',
			},
			{
				path: `/transactions`,
				page: "transactions",
				label: 'Transactions'
			}
		],
		sub_breadcrumbs: []
	})
	const [tab, setTab] = useState(0);

	let payload = { ...TransactionDetail.payload }

	const setUpSubPage = (local) => {
		setPage({
			...page,
			tx_id: local.tx_id,
			pageLabel: local ? local.label : "transactiondetails",
			sub_breadcrumbs: local ? [...local.breadcrumb] : []
		})
	}

	// Set initial activePage and environment
	useEffect(() => {
		let local = checkQueryParams(props.location)

		setUpSubPage(local)

		dispatch(setActivePage('Transactions'))
		if (local.tx_id) {
			dispatch(fetchTransactionDetails({id: local.tx_id}, uuid))
		}

	},[dispatch, props.location])

	return (
		<>
			<div className={'flex flex-col justify-between'}>
				{
					!payload
							?
							<>Loading</>
							: !payload.trx_id
							?
							<>Loading</>
							: payload.trx_id
									?
									<>
										<TransactionDetailsHeader
												title={`Transaction Details`}
												subtitle={payload.trx_id}
												block={payload["actions"].length ? payload["actions"][0]["block_num"] : ' N/A '}
												query_time_ms={payload.query_time_ms}
												traces={payload.actions.length}
												status={payload.executed ? "EXECUTED" : "NOT EXECUTED"}
												setTab={(e) => {setTab(e)}}
												tabValue={tab}
										/>

										<Actiondetails
												data={payload.actions}
										/>
									</>
									: <></>
				}
			</div>

		</>


	)
}

import React from 'react';
import Chart from "react-apexcharts";

const IssuedPieChart = props => {
	let _series = [Math.floor((props.data["issued"] / props.data["max"]) * 100)]
	if (props.data["max"] === 0) {
		_series = [props.data["issued"]]
	}
	const chartData = {
		series: _series,
		options: {
			chart: {
				height: 150,
				type: 'radialBar',
				toolbar: {
					show: false
				}
			},
			plotOptions: {
				radialBar: {
					startAngle: -135,
					endAngle: 225,
					hollow: {
						margin: 0,
						size: '70%',
						background: 'transparent',
						image: undefined,
						imageOffsetX: 0,
						imageOffsetY: 0,
						position: 'front',
						dropShadow: {
							enabled: true,
							top: 3,
							left: 0,
							blur: 4,
							opacity: 0.24
						}
					},
					track: {
						background: '#1b202f',
						strokeWidth: '25%',
						margin: 0, // margin is in pixels
						dropShadow: {
							enabled: true,
							top: -3,
							left: 0,
							blur: 4,
							opacity: 0.95
						}
					},

					dataLabels: {
						show: true,
						name: {
							offsetY: -10,
							show: true,
							color: '#888',
							fontSize: '17px'
						},
						value: {
							formatter: function(val) {
								if (props.data["max"] === 0) {
									return parseInt(val);
								} else {
									return parseInt(val) + "%";
								}

							},
							color: 'white',
							fontSize: '26px',
							show: true,
						}
					}
				}
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'dark',
					type: 'horizontal',
					shadeIntensity: 0.5,
					gradientToColors: ['#9bfefd'],
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 100]
				}
			},
			stroke: {
				lineCap: 'round'
			},
			labels: ['Issued'],
		},
	};

	return (
		<>
			{
				Number.isInteger(props.data["issued"])
					?
						<>

						<Chart
								options={chartData.options}
								series={chartData.series}
								type="radialBar"
								width="250"
						/>
							{console.log(props.data["max"])}
						</>

							: <>
							{console.log(props.data)}
						</>
			}
		</>
	)
}

export default IssuedPieChart;

const actionPrefix = `ConnectionIndicator/ConnectionIndicator/`;

export const actions = {
	REQ_BLOCKCHAIN_CONNECTION: `FETCH_BLOCKCHAIN_CONNECTION`,
	SET_BLOCKCHAIN_CONNECTION: `SET_BLOCKCHAIN_CONNECTION`
}

export const fetchBlockchainConnection = () => ({ type: actions.REQ_BLOCKCHAIN_CONNECTION });
export const setBlockchainConnection = (response) => ({ type: actions.SET_BLOCKCHAIN_CONNECTION, response });

export default actions
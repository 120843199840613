import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import GridLayout, { WidthProvider } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { setActivePage, setDashboardLayout } from '../../redux/modules/settings'
import Page from '../../components/Page'
import getTheme from '../../themes'
import copy from '../../copy-text.js'
import {checkQueryParams} from "../../helpers/check-location";
import {TitlesList} from "../../components/TitlesList";
import {AccountsList} from "../../components/AccountsList";

const ResponsiveGridLayout = WidthProvider(GridLayout)

const useStyles = makeStyles({
	col: {
		display: 'flex',
		flex: 1,
		flexDirection: 'column',
		'&:last-child': {
			marginLeft: 20,
		}
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	dashContainer: ({ height }) => ({
		marginTop: 20,
		width: '100%',
		height: height - 200
	}),
	envSelect: {
		width: 350,
	},
	page: {
		height: '100%'
	},
	select: {
		marginLeft: 10,
		marginRight: 10
	},
}, {name: "Info"})

export default function Explore(props) {
	const dispatch = useDispatch()
	const [page, setPage] = useState({
		pageLabel: null,
		root_breadcrumbs: [
			{
				path: '/',
				page: "home",
				label: 'Home',
			},
			{
				path: `/explore`,
				page: "explore",
				label: 'Explore'
			}
		],
		sub_breadcrumbs: []
	})

	const { Settings } = useSelector(state => state)
	const { colorTheme, dashboard, screenDims } = Settings
	const theme = getTheme(colorTheme)
	const { revenue, accounts, issuedItems, soldItems } = theme.dashboard.icons
	const classes = useStyles({ height: screenDims.height })

	let breadcrumbs = []

	const setUpSubPage = (local) => {
		setPage({
			...page,
			pageLabel: local ? local.label : "explore",
			sub_breadcrumbs: local ? [...local.breadcrumb] : []
		})
	}

	// Set initial activePage and environment
	useEffect(() => {
		let local = checkQueryParams(props.location)
		setUpSubPage(local)
		dispatch(setActivePage('Explore'))
	},[dispatch, props.location.search])

	return (
		<Page
			title={copy.pages.info.title}
			className={classes.page}
			breadcrumbs={[...page.root_breadcrumbs, ...page.sub_breadcrumbs]}
		>
			{
				page.pageLabel === "explore"
					?
						<>explore</>
				: page.pageLabel === "accounts"
					?
						<AccountsList />
				: page.pageLabel === "titles"
					?
						<TitlesList />
				: <>Not Found</>
			}
		</Page>
	)
}

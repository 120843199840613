import { v4 as uuidv4 } from 'uuid';
import React, {useState} from 'react';

const Paginator = props => {

  return (
    <>
      <div key={uuidv4()} className="flex w-1/5 justify-end no-outline">
        <button key={uuidv4()} className="p-4 " onClick={props.pageBackward}>{"< "}</button>
		<div className="p-4 flex flex-grow justify-center" >
			<span className="px-2">{props.start} </span>
			<span className="px-2">-</span>
			<span className="px-2">{props.end}</span>

		</div>
        <button key={uuidv4()} className="p-4 "  onClick={props.pageForward}>{" >"}</button>
      </div>
    </>
  )
}

export default Paginator;

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({

	content: {
		display: 'flex',
		flexDirection: 'column',
		//alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		'&:last-child': {
			marginRight: 0
		}
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	}
}))

const Header = props => {
	const { color, icon, range, title, value } = props
	const classes = useStyles({ color })

	return (
		<div className={`text-2xl w-auto px-6 text-white pt-12`}>
			{props.header}
		</div>
	)
}

Header.propTypes = {
	header: PropTypes.string,
}

export default Header
import React, { useEffect, useState } from 'react';


import { connect } from 'react-redux';
import 'react-multi-carousel/lib/styles.css';
import { push } from 'connected-react-router';
import isObjectEmpty from 'helpers/is-object-empty';
import { fetchStart, paramsSet } from '../../redux/modules/deltasdisplaycase';

import { LoadingSpinner, Paginator, DeltaCards } from 'components';
import TokenCategoryDisplayCase from "../TokenCategoryDisplayCase/TokenCategoryDisplayCase";

const DeltasDisplayCase = (props) => {
  const [dgoodLimit, setDgoodLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [table, setTable] = useState("all");
  const [order, setOrder] = useState("desc");

  const [optionsState, setOptionsState] = useState(10);
  const [actionsState, setActionsState] = useState("all");
  const [tableState, setTableState] = useState("all");

  useEffect(()=>{
    let now = new Date().getTime();

    let params = {
      action: "range_deltas",
      field: "@timestamp",
      environment: props.title,
      table: tableState,
      lower_bound: new Date(now - 86400000).toISOString(),
      upper_bound: new Date(now).toISOString(),
      order: order,
      limit: optionsState
    };

    if (table !== "all") {
      params["table"] = table
    }

    props.paramsSet(params);
    props.fetchStart();

  }, [order, tableState, optionsState])

  let { deltasDisplayCase: { isFetching, data, params } } = props;
  let { payload={}, error } = data;

  const goToNextPage = () => {
    if (payload.response.deltas.length === dgoodLimit) {
      setPage(page + dgoodLimit)
    }
  }

  const goToPreviousPage = () => {
    if (page - dgoodLimit >= 0) {
      setPage(page - dgoodLimit)
    }
  }

  const handleOptionsChange = (event) => {
    setOptionsState(event.target.value);
  }

  const handleTableChange = (event) => {
    setTableState(event.target.value);
  }

  return (
      <>
        {
          props.header
              ?
              <>
                <div className="row dataTables_wrapper">
                  <div className="col-sm-10 col-md-9 mt-n2 text-secondary">
                    <div className="dataTables_info py-3">
                      Showing <span className="font-weight-bold text-secondary">{props.title.toUpperCase()}</span>'s {optionsState}  Latest Deltas
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-3 text-secondary d-flex justify-content-end ">
                    <div className="dataTables_length pr-3">
                      <label>
                        {"Showing "}
                        <select value={tableState} onChange={(e) => handleTableChange(e)} name="my_actions" aria-controls="myactions" className="custom-select custom-select-sm form-control form-control-sm">
                          <option value={'all'}>All</option>
						<option value={'accounts'}>accounts</option>
						<option value={'asks'}>asks</option>
                          <option value={'catandname'}>catandname</option>
                          <option value={'categoryinfo'}>categoryinfo</option>
                          <option value={'dgood'}>dgood</option>
                          <option value={'dgoodstats'}>dgoodstats</option>
                          <option value={'tokenconfig'}>tokenconfig</option>
                        </select>
                        {" table"}
                      </label>
                    </div>
                  </div>
                </div>
              </>
              : <></>
        }
         <table className="table font-size-1 text-nowrap mb-0">
             <thead className="thead-light">
               <th scope="col">Table</th>
               <th scope="col">Block</th>
               <th scope="col">Account</th>
               <th scope="col">Age</th>
               <th scope="col"></th>
             </thead>
             <tbody>
              { error
                ?
                <>
                  {!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
                  <button onClick={props.fetchStart}>Connection error, click to reload</button>
                </>
                : isFetching
                  ? <LoadingSpinner />
                  : !payload.response
                    ? <LoadingSpinner />
                  : payload.response.status_code === 200
                    ?
                    <>
                      {
                        payload.response.data.data.length > 0
                          ?
                            <>
                                {
                                  Object.keys(payload.response.data.data).map(item => {
                                    console.log(item, payload)
                                    return (
                                        <DeltaCards item={payload.response.data.data[item]} />
                                    )
                                  })
                                }
                            </>
                          :
                            <>No DGoods Found Higher</>
                      }
                    </>
			  :<></>
              }
             </tbody>
         </table>
      </>
  );
}

export default DeltasDisplayCase


import React, { useEffect, useState } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../../components/Card'
import Table from "../Table";

const useStyles = makeStyles(theme => ({
	card: {
		background: theme.body.darkCard
	},
	title: {
		paddingBottom: 15
	},
	noneFound: {
		color: theme.text.mint,
		paddingTop: 15,
		fontSize: theme.typography.fontSize
	}
}))

const TitleLatestActions = props => {

	const classes = useStyles()

	const renderRowData = row => {
		const rowData = {
			table: {},
			link: {},
			raw: {}
		}
		Object.keys(row).forEach((key,i) => {
			rowData.raw[key] = row[key]
			rowData.table[key] = row[key]
			rowData.link = `/transaction?id=${row.trx_id}`

			if (key === "act") {
				rowData.table['name'] = row.act.name
			}
		})
		return rowData
	}
	const rowData = props.titleActions && props.titleActions.length ? props.titleActions.map(row => renderRowData(row)) : []

	const tableData = {
		timestamp: { label: 'Timestamp', width: 0.3, sortable: true },
		name: { label: 'Action', width: 0.2, sortable: true },
		block_num: { label: 'Block', width: 0.1, sortable: true },
		trx_id: { label: 'Transaction', width: .1, sortable: true },
		blank: { label: '', width: 0, sortable: false },
	}

	return (
		<Card className={`${classes.card} pt-6`}>
			<div className={classes.content}>
				<Typography className={classes.title}>Latest Actions</Typography>
				<Table
					label={``}
					downloadble={false}
					expandable={false}
					rowData={rowData}
					tableData={tableData}
					transparent={true}
				/>
			</div>
		</Card>
	)
}

export default TitleLatestActions


import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AccountCircle from '@material-ui/icons/AccountCircle'

const useStyles = makeStyles(theme => ({   
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    height: '100%',
    flex: '1',
    '& img': {
      borderRadius: '50%',
      width: '100%'
    },
    '& svg': {
      marginBottom: 0
    }
  },
  icon: {
    width: '50%',
    height: '100%'
  }  
}))

const UserAvatar = (props) => {

  const { Account, className } = props
  const classes = useStyles()
                    
  return (
    <div className={`${classes.root} ${className ? className : ''}`}>
      {
        Account.profile && Account.profile.picture                    
          ? <img alt="profile" src={Account.profile.picture} />
          : <AccountCircle className={classes.icon} />
      }
    </div>       
  )
}

const mapStateToProps = ({ Account }) => ({ Account })

export default connect(mapStateToProps)(UserAvatar)
import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'

const useStyles = makeStyles(theme => ({
	noLoad: {
		color: theme.text.secondary,
		fontWeight: 900,
		fontSize: 75,
		// height: "95vh"
	},
	subNoLoad: {
		fontSize: 25,
		color: theme.text.primaryRed
	}
}))

export default function Title(props) {
	const { Settings } = useSelector(state => state)
	const { screenDims } = Settings
	const classes = useStyles({ height: screenDims.height })

	return (
		<div className={`${classes.noLoad} w-full flex flex-col justify-center items-center`}>
			Unauthorized
			<span className={`${classes.subNoLoad}`}>
				Marketplace participants only
			</span>
		</div>
	)
}

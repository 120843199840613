import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
const useStyles = makeStyles(({ buttons }) => ({ 
  button: ({ disabled, type }) => ({ 
    background: disabled 
      ? buttons[type].disabled.background 
      : buttons[type].default.background, 
    boxShadow: 'none',
    border: buttons[type].default.border,
    borderRadius: 20,
    color: buttons[type].default.color,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    height: 40,
    maxWidth: 250,
    margin: 5,
    padding: '0 15px',    
    textTransform: 'none',
    whiteSpace: 'nowrap',
    '&:hover': {
      background: buttons[type].hover.background, 
      border: buttons[type].hover.border,
      color: buttons[type].hover.color
    },
    '&:active': {      
      boxShadow: 'none',
      background: buttons[type].active.background, 
      border: buttons[type].active.border,
      color: buttons[type].active.color
    },
    '&:disabled': {
      background: buttons[type].btnDisabled, 
      color: buttons[type].btnText,
    }
  }),
  icon: ({ disabled, type }) => ({
    color: disabled 
      ? buttons[type].btnDisabled
      : buttons[type].btnText,    
    display: 'flex',
    marginRight: 10
  })
}))

const MythicalButton = props => {
  const { children, className, disabled=null, fill, icon, label, onClick,  type='outlined', ...otherProps } = props    
  const classes = useStyles({ disabled, type })
  
  return (
    <Button       
      disabled={disabled}
      disableRipple={true}            
      disableFocusRipple={true}            
      className={classes.button}              
      onClick={onClick}     
      {...otherProps} >
      {icon ? <span className={classes.icon}>{icon}</span> : null}
      {children}      
      {label}      
    </Button>     
  )         
}

MythicalButton.propTypes = {    
  className: PropTypes.string,  
  children: PropTypes.any,
  disabled: PropTypes.bool,  
  fill: PropTypes.bool,  
  label: PropTypes.string,  
  onClick: PropTypes.func,  
  outlined: PropTypes.bool,  
}


export default MythicalButton

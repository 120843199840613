import React from 'react';

const BlockchainInfoCard = props => {

	const buildData = (data) => {
		let rows = [];
		console.log(data);
		for (const [key, value] of Object.entries(data)) {
			rows.push(
				<>
					<div className="text-cap font-weight-normal mb-1">{key}</div>
					<div className="font-weight-normal small mb-4">{value}</div>
				</>
			);
		}
		return rows;
	}

	return (
		<>
			<div className="card ">
				<div className="card-body mb-3">
					<div className="d-flex align-items-center px-3 mb-3">{props.head}</div>

					<div className="row mx-gutters-lg-1">
						<div className="col-lg-4 d-flex flex-column">
							<div className="d-flex flex-column flex-grow-1">
								{buildData(props.chainData)}
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	)
}

export default BlockchainInfoCard;

import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchBlocksList, setBlocksList } from './actions'

import { mythicalFetcher } from '../../utilities'
import { getBlocks } from "../../../services/ivi-engine";

function* fetchBlocksListSaga(action) {
	try {
		const response = yield mythicalFetcher(getBlocks, [action.body])
		yield put(setBlocksList(response))
	} catch(e) {}
}

function* watchFetchBlocksListSaga() {
	yield takeLatest(actions.REQ_BLOCKS_LIST, fetchBlocksListSaga)
}


export default function* blockListSagas() {
	yield fork(watchFetchBlocksListSaga)
}


import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import HeaderCard from '../../components/HeaderCard'
import TabBar from '../UI/TabBar'
import ChipList from "../UI/ChipList";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
	headerCard: {
		width: '100%',
		height: '100%',
		background: 'transparent',
		boxShadow: 'none',
		userSelect: 'none',
		position: "relative"
	},
	titleNameHeader: {

	},
	favoriteButton: {
		backgroundColor: "transparent",
		border: "none",
		outline: "none",
		cursor: "pointer"
	},
	favorited: {
		color: theme.text.primaryRed
	},
	unfavorited: {
		color: theme.text.primary
	},
	title: {
		color: theme.text.primary,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: theme.typography.fontSizeMedium,
	},
	subTitle:{
		// color: theme.text.mint,
		margin: 5
	}
}))

const BlockDetailsHeader = props => {
	const classes = useStyles();

	const createChipData = () => {
		return [
			{ key: 0, label: `Ref Block Prefix`, text: props.ref_block_prefix},
			{ key: 1, label: `Transaction Count`, text: props.txs},
			{ key: 2, label: `Status`, text: props.status}
		]
	};
	const blockTabs = [
		{label: "Details", index: 0},
		{label: "Transactions", index: 1}
	]

	return (
		<HeaderCard className={classes.headerCard}>
			<div className={classes.titleNameHeader}>
				<Typography className={classes.title}>
					{props.title}
				</Typography>
				<div className={`font-thin m-4 pb-4`}>
					{props.subtitle}
				</div>
			</div>
			<ChipList chipData={createChipData()}/>
			<TabBar tabValue={props.tabValue} tabs={blockTabs} setTab={(e) => {props.setTab(e)}}/>
		</HeaderCard>
	)
}

export default BlockDetailsHeader

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Card from '../Card'

const useStyles = makeStyles(theme => ({
	atAGlance: {
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		//alignItems: 'center',
		justifyContent: 'center',
		position: 'relative',
		height: '100%',
		'&:last-child': {
			marginRight: 0
		}
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1,
	},
	divider: {
		borderTop: `1px solid ${theme.border.secondary}`,
		margin: '10px 0px',
		height: 1,
		width: '100%'
	},
	icon: ({ color}) => ({
		color: color,
		minWidth: 45,
		width: '20%'
	}),
	range: {
		color: theme.text.secondary,
		fontSize: '.7rem',
	},
	title: {
		color: theme.text.secondary,
		display: 'flex',
		fontSize: '.7rem',
		alignSelf: 'flex-end'
	},
	value: {
		color: theme.text.primary,
		fontSize: '1em',
	}
}))

const AtAGlance = props => {
	const { color, icon, range, title, value } = props
	const classes = useStyles({ color })

	return (
		<Card className={`mx-6 my-4  w-1/3 ${classes.atAGlance}`}>
			<div className={`${classes.col} ${classes.content}`}>
				<div className={classes.row} style={{ justifyContent: 'space-between' }}>
					<div className={classes.col} style={{ justifyContent: 'flex-end' }}>
						<div className={classes.icon}>{icon}</div>
					</div>
					<div className={classes.col} style={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}>
						<Typography className={classes.title}>{title}</Typography>
						<Typography className={classes.value}>{value}</Typography>
					</div>
				</div>
				<span className={classes.divider} />
				<div className={classes.row} style={{ justifyContent: 'flex-start' }}>
					<Typography className={classes.range}>{range}</Typography>
				</div>
			</div>
		</Card>
	)
}

AtAGlance.propTypes = {
	title: PropTypes.string,
	range: PropTypes.string,
	value: PropTypes.string,
	icon: PropTypes.element
}

export default AtAGlance
import React from 'react';
import moment from 'moment';

export const transformData = (data) => {
	let series_data = [];
	let categories = []
	let maximumY = 10;

	for (let item of data) {
		series_data.push(item.doc_count)
		categories.push(moment(item["key_as_string"]).fromNow())
	}

	let font = "#6984aa"
	let lines = "#6984aa";
	let theme = localStorage.getItem("theme")
	if (theme === "false") {
		font = "#6984aa"
		lines = "#6984aa"
		theme = "dark"
	} else {
		theme = "light"
	}


	series_data.map(d => {
		maximumY = Math.max(maximumY, d)
	})

	return (
		{
			series: [{
					name: "Issues",
					data: series_data
				}]
			,
			chart: {
				foreColor: font,
				toolbar: {
					show: false
				},
			},
			tooltip: {
				enabled: true,
				followCursor: true,
				style: {
					fontSize: '12px',
				},
				theme: theme
			},
			fill: {
				type: 'gradient',
				gradient: {
					shade: 'light',
					gradientToColors: [ '#11B1B4', '#a905bb'],
					stops: [1, 95, 100],
					colorStops: [
						{
							offset: 0,
							color: "#656ceb",
							opacity: 1
						},
						{
							offset: 20,
							color: "#7971e3",
							opacity: 1
						},
						{
							offset: 60,
							color: "#0acadc",
							opacity: 1
						},
						{
							offset: 100,
							color: "#098ed5",
							opacity: 1
						}
					]
				},
			},
			stroke: {
				width: 4,
				curve: "smooth",
				lineCap: "round"
			},

			grid: {
				show: false
			},

			plotOptions: {
				area: {
					fillTo: 'end',
				}
			},

			yaxis: {
				forceNiceScale: true,
				max: maximumY + 1,
				axisBorder: {
					show: false,
					color: font
				},
				axisTicks: {
					show: false
				}
			},

			xaxis: {
				categories: categories,
				axisTicks: {
					show: false
				},
				axisBorder: {
					show: false,
					color: lines
				},
				labels: {
					show: true,
					trim: true
				}
			},

			dataLabels: {
				enabled: false,
				style: {
					fontSize: '14px',
					fontWeight: 'bold',
					colors: undefined
				},
				background: {
					enabled: true,
					foreColor: '#fff',
					padding: 4,
					borderWidth: 0,
					opacity: 0.9,
					dropShadow: {
						enabled: true,
						top: 1,
						left: 1,
						blur: 1,
						color: '#000',
						opacity: 0.45
					}
				},
			}
		}
	)
}


export const transformBubbleData = (data) => {
	let series_data = [];
	let categories = []
	let maximumY = 10;
	let maximumX = 1;
	let minimumX = 10000000000000;

	for (let item of data) {
		series_data.push([item.key_as_string, item.doc_count, item.doc_count])
		categories.push(item.key_as_string)
	}

	let font = "#1e222a"
	let lines = "#1e222a";
	let theme = localStorage.getItem("theme")
	if (theme === "false") {
		font = "#ffffff"
		lines = "#ffffff"
		theme = "dark"
	} else {
		theme = "light"
	}

	series_data.map(d => {
		maximumY = Math.max(maximumY, d[1])
		maximumX = Math.max(maximumX, d[0])
		minimumX = Math.min(minimumX, d[0])
	})

	return (
			{
				series: [{
					name: "Issuepaid",
					data: series_data
				}],
				chart: {
					foreColor: font
				},
				tooltip: {
					enabled: true,
					followCursor: true,
					style: {
						fontSize: '12px',
					},
					theme: theme
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						gradientToColors: [ '#11B1B4'],
						shadeIntensity: 1,
						type: 'vertical',
						opacityFrom: 0.85,
						opacityTo: 0.2,
						stops: [1, 95, 100]
					},
				},
				stroke: {
					curve: 'smooth'
				},

				grid: {
					show: false
				},

				plotOptions: {
					area: {
						fillTo: 'end',
					},
					bubble: {
						minBubbleRadius: 5,
						maxBubbleRadius: 10,
					}
				},
				yaxis: {
					forceNiceScale: true,
					max: maximumY + 10,
					axisBorder: {
						show: true,
						color: font
					},
					axisTicks: {
						show: false
					}
				},

				xaxis: {
					type: "datetime",
					// axisTicks: {
					// 	show: false
					// },
					axisBorder: {
						show: true,
						color: lines
					}
				},

				dataLabels: {
					enabled: false,
					style: {
						fontSize: '14px',
						fontWeight: 'bold',
						colors: undefined
					},
					background: {
						enabled: true,
						foreColor: '#fff',
						padding: 4,
						borderWidth: 0,
						opacity: 0.9,
						dropShadow: {
							enabled: true,
							top: 1,
							left: 1,
							blur: 1,
							color: '#000',
							opacity: 0.45
						}
					},
				}
			}
	)
}

export const transformBarData = (data) => {
	console.log("AGG DATA IS: ", data)
	let series_data = [];
	let categories = []

	for (let item of data) {
		series_data.push(item.doc_count)
		categories.push(item.key_as_string)
	}

	let font = "#1e222a"
	let lines = "#1e222a";
	let theme = localStorage.getItem("theme")
	if (theme === "false") {
		font = "#ffffff"
		lines = "#ffffff"
		theme = "dark"
	} else {
		theme = "light"
	}

	return (
			{
				series: [{
					name: "Issuepaid",
					data: series_data
				}],
				chart: {
					foreColor: font
				},
				tooltip: {
					enabled: true,
					followCursor: true,
					style: {
						fontSize: '12px',
					},
					theme: theme
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						gradientToColors: [ '#11B1B4'],
						shadeIntensity: 1,
						type: 'vertical',
						opacityFrom: 0.85,
						opacityTo: 0.2,
						stops: [1, 95, 100]
					},
				},
				grid: {
					show: false
				},

				yaxis: {
					forceNiceScale: true,
					axisBorder: {
						show: true,
						color: font
					},
					axisTicks: {
						show: false
					}
				},

				xaxis: {
					categories: categories,
					axisTicks: {
						show: false
					},
					axisBorder: {
						show: true,
						color: lines
					},
					labels: {
						show: true,
						trim: true
					}
				}
			}
	)
}

const splitQuantity = (quantity) => {
	let split = quantity.split(" ");
	return split[0]
}
import { all, debounce, fork, put, select, takeLatest } from 'redux-saga/effects'
import actions, { fetchTableRows, setTableRows} from './actions'

import { mythicalFetcher } from '../../utilities'
import { getTableRows } from "../../../services/hyperion";

function* fetchTableRowsSaga(action) {
	try {
		const response = yield mythicalFetcher(getTableRows, [action.body])
		yield put(setTableRows(response, action.uuid))
	} catch(e) {}
}

function* watchFetchTableRowsSaga() {
	yield takeLatest(actions.REQ_DGOOD_TABLE_ROWS, fetchTableRowsSaga)
}


export default function* dgoodTableRowsSagas() {
	yield fork(watchFetchTableRowsSaga)
}

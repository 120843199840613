import baseTheme from './base'

const colorPrimary = '#FE395A'
const colorSecondary = '#9AFFFD'
const colorTertiary = '#53908f'

const textPrimary = '#FFFFFF'
const textSecondary = '#bfbfbf'
const textLowlight = '#000000'

// main
const basePageColor = "#080a0e"

const accent = `#0a0c10`
const accent2 = `#22252d`
const accentRed = `#E71D36`
const accentGreen = `#00F0B5`
// F7FF58
const accentPrimaryl = `${accent}`
const accentPrimaryl0 = `${accent}11`
const accentPrimaryl1 = `${accent}31`
const accentPrimaryl2 = `${accent}64`
const accentPrimaryl3 = `${accent}94`
const accentPrimaryl4 = `${accent}ff`

const accent2Primaryl = `${accent2}`
const accent2Primaryl0 = `${accent2}24`
const accent2Primaryl1 = `${accent2}44`
const accent2Primaryl2 = `${accent2}64`
const accent2Primaryl3 = `${accent2}84`
const accent2Primaryl4 = `${accent2}ff`

// const bgGradient = `linear-gradient(193.68deg, #000000 0.68%, ${bgColor6} 100.48%)`
// const bgGradientLeft = `linear-gradient(193.68deg, ${bgColor8} 0.68%, ${bgColor7} 100.48%)`
// const bgGradientRight = `linear-gradient(193.68deg, ${bgColor9} 0.68%, ${bgColor8} 100.48%)`
const bgDark = '#11141b'
const hoverBackground = "#2a3040";
const activeBackground = "#1b1d24";

const darkBorder = "#31364A"
const theme = {
	...baseTheme,
	body: {
		backgroundColor: basePageColor,
		basePageColor: accent,
		backgroundColorGradientCorner: basePageColor,
		darkCard: accentPrimaryl,
		accentPrimaryl: accentPrimaryl,
		accent2Primaryl2: accent2Primaryl2,
		accent2Primaryl3: accent2Primaryl3,
		accent2Primaryl4: accent2Primaryl4,
		darkCardL2: accentPrimaryl2,
		// background: bgGradient,
		// backgroundLeft: bgGradientLeft,
		// backgroundRight: bgGradientRight,
		darkCardTrans: accentPrimaryl4
	},
	border: {
		secondary: darkBorder
	},
	buttons: {
		...baseTheme.buttons,
		outlined: {
			...baseTheme.buttons.outlined,
			default: {
				...baseTheme.buttons.outlined.default,
				color: textPrimary,
			}
		}
	},
	card: {
		background: basePageColor,
		header: {
			backgroundColor: basePageColor,
			color: textSecondary,
		}
	},
	environments: {
		...baseTheme.environments,
		card: {
			backgroundColor: '#3C4153',
		},
	},
	imageUploader: {
		backgroundColor: basePageColor,
	},
	modal: {
		backgroundColor: basePageColor,
	},
	overrides: {
		...baseTheme.overrides,
		MuiCheckbox: {
			...baseTheme.overrides.MuiCheckbox,
			colorSecondary: {
				'&.Mui-disabled': {
					color: 'rgba(255,255,255,0.25)'
				},
			},
			root: {
				...baseTheme.overrides.MuiCheckbox.root,
				color: textPrimary
			}
		},
		MuiFormControlLabel: {
			label: {
				'&.Mui-disabled': {
					color: textSecondary
				}
			}
		},
		MuiFormHelperText: {
			root: {
				color: textSecondary,
				'&.Mui-error': {
					color: colorPrimary,
				}
			},
		},
		MuiFormLabel: {
			root: {
				color: textPrimary,
				'&.Mui-disabled': {
					color: textSecondary,
				},
				'&.Mui-focused': {
					color: colorSecondary,
				},
				'&.Mui-error': {
					color: colorPrimary,
				},
			},
		},
		MuiIconButton: {
			root: {
				color: textSecondary,
			},
		},
		MuiInput: {
			underline: {
				'&:before': {
					borderBottomColor: textSecondary,
				},
				'&:after': {
					borderBottomColor: textSecondary,
				},
				'&:hover:before': {
					borderBottomColor: [textSecondary, '!important'],
				},
				'&.Mui-error:after': {
					borderBottomColor: colorPrimary,
				},
			},
			root: {
				color: textPrimary,
				'&:hover': {
					borderBottom: 'none'
				},
				'& .Mui-disabled': {
					color: textPrimary,
				},
			},
		},
		MuiInputAdornment: {
			root: {
				color: textPrimary,
			}
		},
		MuiPaper: {
			root: {
				backgroundColor: basePageColor,
				color: textPrimary,
			}
		},
		MuiPickersDay: {
			day: {
				color: textPrimary,
			},
		},
		MuiPickersCalendarHeader: {
			dayLabel: {
				color: textSecondary,
			},
			iconButton: {
				backgroundColor: 'none'
			}
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: basePageColor,
			}
		},
		MuiPopover: {
			paper: {
				backgroundColor: basePageColor,
			},
		},
		MuiSelect: {
			icon: {
				color: textPrimary
			}
		},
		MuiTypography: {
			colorTextSecondary: {
				color: textSecondary
			}
		}
	},
	palette: {
		...baseTheme.palette,
		primary: {
			...baseTheme.palette.primary,
			main: colorPrimary,
		},
		secondary: {
			...baseTheme.palette.secondary,
			main: colorSecondary,
		}
	},
	select: {
		backgroundColor: basePageColor,
	},
	table: {
		backgroundColor: 'transparent',
		actions: {
			color: '#C9C9C9'
		},
		header: {
			backgroundColor: darkBorder
		},
		highlight: {
			backgroundColor: basePageColor
		},
		rows: {
			hoverBackgroundColor: hoverBackground,
			activeBackgroundColor: activeBackground,
			backgroundColor: basePageColor
		}
	},
	tabs: {
		indicator: colorPrimary,
		buttonTabs: {
			root: {
				backgroundColor: basePageColor,
				color: '#666'
			},
			selected: {
				backgroundColor: '#DADBDF'
			}
		}
	},
	text: {
		primary: textPrimary,
		secondary: textSecondary,
		tertiary: colorTertiary,
		highlight: colorSecondary,
		lowlight: textLowlight,
		mint: colorSecondary,
		primaryRed: colorPrimary,
	},
}

export default theme
import React, {useEffect, useState} from 'react';

import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuidv4 } from 'uuid';

import moment from "moment";
import Card from "../Card";
import {useSelector} from "react-redux";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {Button} from "react-bootstrap";
import TreeMenu from 'react-simple-tree-menu'

import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const tableRowMargin = 15

const useStyles = makeStyles(theme => ({
	card: {
		height: 'auto',
		overflow: "inherit",
	},
	overlay: {
		position: "fixed",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0
	},
	dataDisplay: {
		background: `linear-gradient(${theme.body.accent2Primaryl4}, ${theme.body.accent2Primaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		borderRadius: 5,
		zIndex: 100000
	},
	noOutline: {
		outline: "none !important"
	},
	titleDash: {
		width: '100%',
		height: '100%',
		userSelect: 'none',

	},
	tableRowChild: {
		background: theme.table.rows.background,
		borderLeft: `2px solid ${theme.body.darkCard}`,
		borderBottom: `2px solid ${theme.body.darkCard}`,
		display: 'flex',
		alignItems: 'center',
		minHeight: 50,
		width: `calc(100% - 3.75rem - 2px)`
	},
	tableRow: {
		background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		// borderBottom: `1px solid ${theme.table.header.backgroundColor}`,
		flexGrow: 'inherit',
		display: 'flex',
		alignItems: 'center',
		minHeight: 50
	},
	tableHead: {
		display: 'flex',
		alignItems: 'center',
		minHeight: 50
	},
	tableCell: {
		flex: 1,
		border: 'none',
		color: theme.text.primary,
		textAlign: 'left',
		textOverflow: 'ellipsis',
		fontSize: '12px',
		position: "relative",
		padding: 5,
		'&:first-child': {
			paddingLeft: tableRowMargin,
		},
		'&:last-child': {
			paddingRight: tableRowMargin,
		}
	},
	cellData: {
		display: 'flex',
		textAlign: 'left',
		marginBottom: '10px',
		marginTop: '10px'
	},
	authKey: {
		flex: 1
	},
	authData: {
		flex: 1,
		color: theme.text.primaryRed,
		fontWeight: theme.typography.fontWeightMedium
	},
	dataKey: {
		flex: 1
	},
	dataValue: {
		flex: 1,
		color: theme.text.mint
	},
	sentDGood: {
		color: theme.text.mint

	}
}))
export default function Actiondetails (props) {
	const classes = useStyles()
	const { Settings } = useSelector(state => state)
	const [treeData, setTreeData] = useState([])

	function createData(name, data) {
		return { name, data };
	}

	const [openedKey, setOpenedKey] = useState(null)

	const buildObjectCell = (data) => {
		let cell = [];
		let dgood = null;
		for (let key in data) {
			if (data.hasOwnProperty(key) && typeof data[key] !== "object" && key !== "amt_paid" && key !== "currency_paid" && key !== "relative_uri" && key !== "amount" && key !== "quantity" && key !== "symbol") {
				if (key === "memo") {
					let spl = data[key].split(",");
					if (spl.length > 1) {
						spl[0] = `#${spl[0]}`
						dgood = <>{spl[0]} <ArrowRightAltIcon /> {spl[1]}</>
					} else {
						cell.push(
							<div className={`flex justify-between w-auto py-2`} key={uuidv4()}>
								<div className={`flex-grow`}>{key.toUpperCase()}</div>
								<div className={`font-semibold text-right`}>{data[key] || ' - '}</div>
							</div>
						)
					}
				} else {
					cell.push(
						<div className={`flex justify-between w-auto py-2`} key={uuidv4()}>
							<div className={`flex-grow`}>{key.toUpperCase()}</div>
							<div className={`font-semibold text-right`}>{data[key] || ' - '}</div>
						</div>
					)
				}
			}
		}

		if (dgood) {
			cell.push(
					<div className={`${classes.sentDGood} w-auto py-2 flex items-center justify-center font-bold uppercase`} key={uuidv4()}>
						{dgood}

					</div>
			)
		}
		return cell
	}

	const getDoodId = (data) => {
		if ("dgood_id" in data) {
			return data["dgood_id"]
		} else if ("dgood_ids" in data) {
			return data["dgood_ids"].toString()
		} else if ("batch_id" in data) {
			return data["batch_id"]
		} else {
			return '-'
		}
	}

	const openDetails = (g_seq) => {
		setOpenedKey(g_seq)
	}

	const buildRow = (type, created, contract, action, data, g_seq) => {
		let reactNodeDiv = React.createElement('div', {id: g_seq, className: 'rstm-div-node'},
			<div key={g_seq} className={type ? classes.tableRow : classes.tableRowChild}>
				<div component="th" scope="row" className={classes.tableCell} title={created}>
					{created} UTC
				</div>
				<div className={classes.tableCell}>
					{action}
				</div>
				<div className={`${classes.tableCell}`}>
					{getDoodId(data)}
				</div>
				<div className={classes.tableCell}>
					{
						openedKey === g_seq
							? <Button onClick={(e) => {openDetails(false)}} className={`${classes.noOutline}`} ><VisibilityOffIcon /></Button>
							: <Button onClick={(e) => {openDetails(g_seq)}} className={`${classes.noOutline}`} ><VisibilityIcon /></Button>

					}
					{
						openedKey === g_seq
							?
								<>
									<div className={`absolute left-0 ${classes.dataDisplay} p-4 h-auto w-full`}>
										{buildObjectCell(data)}
									</div>
									<Button onClick={() => {openDetails(false)}} className={classes.overlay}></Button>
								</>
							: <></>
					}
				</div>
			</div>
		)
		return reactNodeDiv
	}

	const buildTreeData = (data) => {
		let treeD = []
		let parent = -1
		let currentParentGs = null

		for (let item of data) {
			if (item.creator_action_ordinal === 0) {
				if (item.global_sequence !== currentParentGs) {
					currentParentGs = item.global_sequence
					parent ++
				}

				treeD[parent] = {
					key: `${item.global_sequence}`,
					label: buildRow(true, item[`@timestamp`], item.act.account, item.act.name, item.act.data, item.global_sequence),
					nodes: []
				}
			}
			if (item.creator_action_ordinal !== 0) {
				treeD[parent].nodes.push({
					key: `${item.global_sequence}`,
					label: buildRow(false, item[`@timestamp`], item.act.account, item.act.name, item.act.data, item.global_sequence),
				})
			}
		}
		setTreeData(treeD)

		console.log(treeD)
	}

	useEffect(() => {
		buildTreeData(props.data)
	}, [openedKey, props.data])

	return (
		<>
			{
				treeData.length
					?
					<Card className={classes.card}>
						<div className={classes.content}>
							<div className={classes.table}>
								<div>
									<div key={uuidv4()} className={classes.tableHead}>
										<div className={classes.tableCell}>Created</div>
										<div align="right" className={classes.tableCell}>Action</div>
										<div align="right" className={classes.tableCell}>DGood ID</div>
										<div align="left" className={classes.tableCell}>Data</div>
									</div>
								</div>
								<div>
									<TreeMenu
										data={treeData}
										hasSearch={false}
									/>
								</div>
							</div>
						</div>
					</Card>
					: <></>
			}
		</>
	);
}
// {
// 	props.data.map((row) => {
// 		return buildRow(row, row.timestamp, row.act.account, row.act.name, row.act.data, row.global_sequence)
// 	})
// }
import "./App.scss";
import "./index.css";

import React, { useEffect } from "react";
import ReactGA from 'react-ga'
import {connect} from 'react-redux'
import { Route, Router, Switch, Redirect } from 'react-router-dom'
import {withRouter} from "react-router";
import { MuiThemeProvider } from '@material-ui/core/styles'
import getTheme from '../themes'

import NoLoad from "pages/NoLoad"

import { setScreenDims } from '../redux/modules/settings'
import {fetchBlockchainConnection} from "../redux/modules/connectionindicator";

const Unauthorized = (props) => {
	const {
		Authentication,
		dispatch,
		history
	} = props
	const { authenticated } = Authentication.keycloak

	const appTheme = getTheme('DARK')

	useEffect(() => {
		document.body.style.backgroundColor = appTheme.body.backgroundColor
		return(()=> document.body.style.backgroundColor = null)
	},[appTheme])

	useEffect(() => {
		const handleResize = () => {
			dispatch(setScreenDims({ height: window.innerHeight, width: window.innerWidth }))
		}
		window.addEventListener('resize', handleResize)
		handleResize()
		return () => window.removeEventListener('resize', handleResize)
	},[dispatch])

	// Settup analytics
	useEffect(() => {
		const observer = new PerformanceObserver(list => {
			list.getEntries().forEach(entry => {
				ReactGA.timing({
					category: "Load Performace",
					variable: 'Server Latency',
					value: entry.responseStart - entry.requestStart
				})
				ReactGA.timing({
					category: "Load Performace",
					variable: 'Download Time',
					value: entry.responseEnd - entry.responseStart
				})
				ReactGA.timing({
					category: "Load Performace",
					variable: 'Total Unauthorized Load Time',
					value: entry.responseEnd - entry.requestStart
				})
			})
		})
		observer.observe({entryTypes: ['navigation']})
		ReactGA.pageview(history.location.pathname)
	},[history.location.pathname])

	useEffect(() => {
		const unlisten = history.listen(location => {
			ReactGA.set({ page: location.pathname })
			ReactGA.pageview(location.pathname)
		})
		return () => unlisten()

	},[history])

	useEffect(() => {
		dispatch(fetchBlockchainConnection())
	}, [dispatch])

		return !authenticated
			?<div></div>
			:
			<MuiThemeProvider theme={appTheme}>
				<Router history={history}>
					<div className={'my-0 xl:my-12 wrapper'}>
						<div className={'interface px-4 md:px-12 md:px-0 w-full h-full'}>
							<Switch>
								<Route exact path="/" component={NoLoad}/>
								<Route exact path="/info" component={NoLoad}/>
								<Redirect to="/"/>
							</Switch>
						</div>
					</div>
				</Router>
			</MuiThemeProvider>

}

const mapStateToProps = ({ Authentication, Settings }) => ({ Authentication, Settings });

export default withRouter(connect(mapStateToProps)(Unauthorized));

import React, {useEffect, useState} from 'react'

import Chart from "react-apexcharts";

import {connect} from "react-redux";
import {push} from "connected-react-router";
import { fetchStart, paramsSet } from '../../redux/modules/tokenissuepaidactiongraph';

import {LoadingSpinner, ResizableBox} from "components";
import isObjectEmpty from "helpers/is-object-empty";

import { transformData, transformBubbleData, transformBarData } from "./helpers/transform_graph_data";

const TokenIssuepaidActionGraph = props => {
	const [offset, setOffset] = useState(500000);
	const [lowerBound, setLowerBound] = useState(props.keyBlock / 2);
	const [upperBound, setUpperBound] = useState(props.keyBlock);
	const [chartType, setChartType] = useState("area");
	const [interval, setInterval] = useState("1H");

	useEffect(()=>{
		let now = new Date().getTime();

		props.paramsSet({
			"action": "contract_actions",
			"range_field": "@timestamp",
			"lower_bound": new Date(now - 86400000).toISOString(),
			"upper_bound": new Date(now).toISOString(),
			"contract_action": props.action,
			"token_name": props.token_name,
			"order": 'desc',
			"size": 100,
			"title": props.title,
			"category": props.category,
			"interval":interval,
			"doc_count": 1
		});
		props.fetchStart();

	}, [lowerBound, upperBound, props.token_name, chartType, interval])

	let { tokenIssuepaidActionGraph: { isFetching, data, params } } = props;
	let { payload={}, error } = data;

	const buildAreaChart = (areaD) => {
		let areaData = transformData(areaD, lowerBound, upperBound);
		return (
			<Chart
					className={"panel-inner-body"}
					options={areaData}
					series={areaData.series}
					type={"line"}
					width="100%"
					height="200px"
			/>
		)
	}

	return (
		<>
			{
				error ?
					<>
						{!isObjectEmpty(error) && <p className="text-danger">{JSON.stringify(error)}</p>}
						<button onClick={props.pollingStart}>Connection error, click to reload</button>
					</>
				: isFetching
					? <LoadingSpinner />
				: !payload.response
					? <LoadingSpinner />
				: payload.response.status_code === 200
					?
						<>
							{
								payload.response.data.length
									?
										<>
											{
												payload.response.full.aggregations
													?
														<>
															{console.log(payload.response)}
															{
																payload.response.full.aggregations["issuepaid-agg"].buckets.length > 0
																	?
																		<>
																			{buildAreaChart(payload.response.full.aggregations["issuepaid-agg"].buckets)}
																		</>
																	:
																		<>
																			{
																				buildAreaChart(
																					[
																						{
																							"doc_count": 0,
																							"key": 0
																						}
																					])
																			}
																		</>
															}
														</>
													:
														<>
															{
																buildAreaChart(
																	[
																		{
																			"doc_count": 0,
																			"key": 0
																		}
																	])
															}
														</>
											}
										</>
									:
										<>
											{
												buildAreaChart(
													[
														{
															"doc_count": 0,
															"key": 0
														}
													])
											}
										</>
							}
						</>

				:
					<>
						{
							buildAreaChart(
								[
									{
										"doc_count": 0,
										"key": 0
									}
								])
						}
					</>
			}

		</>
	)
}

export default connect(
		({ tokenIssuepaidActionGraphComponent: { tokenIssuepaidActionGraph }, router}) => ({
			tokenIssuepaidActionGraph,
			router
		}),
		{
			fetchStart,
			paramsSet,
			push
		}

)(TokenIssuepaidActionGraph);

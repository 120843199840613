import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid';

import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'react-bootstrap';
import TableHyperion from '../../components/TableHyperion'
import {fetchActionList} from "../../redux/modules/actionlist";
import Paginator from "../../components/Paginator";
import GridLayout, {WidthProvider} from "react-grid-layout";
import Card from "../Card";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(theme => ({
	card: {
		background: `linear-gradient(${theme.body.accent2Primaryl2}, ${theme.body.accent2Primaryl2}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
	},
	noOutline: {
		outline: "none !important"
	},
	dropdown: {
		background: `linear-gradient(${theme.body.accent2Primaryl4}, ${theme.body.accent2Primaryl4}), linear-gradient(${theme.body.basePageColor}, ${theme.body.basePageColor})`,
		borderRadius: 5,
		zIndex: 100000
	},
	overlay: {
		position: "fixed",
		width: "100%",
		height: "100%",
		top: 0,
		left: 0
	},
}))

const ActionsList = props => {
	// SETTINGS DATA
	const dispatch = useDispatch()
	const { Settings, ActionList } = useSelector(state => state)
	const { colorTheme, title, screenDims } = Settings
	const classes = useStyles()

	// FETCH DATA
	const [uuid, setUuid] = useState(uuidv4())
	const [payload, setPayload] = useState(null)

	// PAGE DATA
	const [page, setPage] = useState(0)
	const [pageSize, setPageSize] = useState(props.pageSize)
	const [action, setAction] = useState(props.action)
	const [skip, setSkip] = useState(0)
	const [rowData, setRowData] = useState(null)

	// ACTION DROPDOWN
	const [opened, setOpened] = useState(false);

	useEffect(() => {
		let body = {
			"account": props.account,
			"limit": pageSize,
			"skip": skip,
			"action": action
		}

		dispatch(fetchActionList(body, uuid))
	},[dispatch, pageSize, skip, action])

	const renderRowData = row => {
		const rowData = {
		  table: {},
		  raw: {}
		}
		Object.keys(row).forEach((key,i) => {
		  rowData.raw[key] = row[key]

		  switch(key) {
			case 'timestamp':
				console.log(row[key])
			  rowData.table[key] = `${row[key]} UTC`
			  break
			case 'act':
				rowData.link = `/transaction?id=${row.trx_id}`
				rowData.table['action'] = row.act.name
			default:
			  rowData.table[key] = row[key]
			  break
		  }
		})
		return rowData
  }

	const tableData = {
		action: { label: 'action', width: 0.1, sortable: true },
		trx_id: { label: 'Transaction ID', width: 0.6, sortable: true },
		block_num: { label: 'Block', width: 0.1, sortable: true },
		timestamp: { label: 'Timestamp', width: 0.2, sortable: true },
	}

	const setNewPage = (e) => {
		const max = payload['total']['value'];

		switch(e) {
			case 'next':
				if (skip+pageSize < max) {
					setPage(page+1)
					setSkip(skip + pageSize)
				}
				break;
			case 'back':
				if (skip+pageSize > pageSize) {
					setPage(page-1)
					setSkip(skip - pageSize)
				}
				break;
			default:
				console.log("no more")
				break;
		}
	}

	const setPayloadData = () => {
		setRowData(ActionList.payload.actions && ActionList.payload.actions.length ? ActionList.payload.actions.map(row => renderRowData(row)) : [])
		setPayload(ActionList.payload)
	}

	const getActionName = (_action) => {
		switch(_action) {
			case "issuepaid":
				return "Issuances"
				break;
			case "listsalenft":
				return "Listings"
				break;
			case "transfernft":
				return "Buys"
				break;
			case "create":
				return "Creates"
				break;
			default:
				return "All"
		}
	}

	const setActionAndClose = (action) => {
		setAction(action);
		setOpened(!opened);
	}
	return (
		<>
			<Card className={`${classes.card} flex-grow`}>
				<div className={classes.content}>
					{
						payload !== ActionList.payload && uuid === ActionList.uuid
							?
							<>
								{setPayloadData(ActionList.payload)}
							</>
							: <></>
					}
					{
						payload
							?
							<>
								<div className={"flex justify-between"}>
									{
										props.hideDropdown
											?
												<>

												</>

											:
												<>
													<div className={'relative w-full py-2'}>
														<Button className={`w-500 flex items-center px-4 ${classes.noOutline}`} onClick={(e) => {setOpened(!opened)}}>{getActionName(action)} <ArrowDropDownIcon /></Button>

														{
															opened
																?
																<>
																	<div className={`flex flex-col absolute ${classes.dropdown}`}>
																		<Button className={`text-left p-2 px-4 ${classes.noOutline}`} onClick={(e) => {setActionAndClose(undefined)}}>All</Button>
																		<Button className={`text-left p-2 px-4 ${classes.noOutline}`}  onClick={(e) => {setActionAndClose("issuepaid")}}>Issuances</Button>
																		<Button className={`text-left p-2 px-4 ${classes.noOutline}`}  onClick={(e) => {setActionAndClose("create")}}>Creations</Button>
																		<Button className={`text-left p-2 px-4 ${classes.noOutline}`}  onClick={(e) => {setActionAndClose("listsalenft")}}>Listings</Button>
																		<Button className={`text-left p-2 px-4 ${classes.noOutline}`}  onClick={(e) => {setActionAndClose("transfernft")}}>Buys</Button>
																	</div>
																	<Button onClick={() => {setActionAndClose(undefined)}} className={classes.overlay}></Button>

																</>
																: <></>
														}
													</div>

												</>
									}

									<div className={'flex-grow'}>

									</div>
									<Paginator
										pageBackward={() => {setNewPage("back")}}
										pageForward={() => {setNewPage("next")}}
										start={skip}
										end={skip+pageSize}
									/>
								</div>

								<TableHyperion
									downloadble={true}
									expandable={false}
									rowData={rowData}
									tableData={tableData}
									pageSize={pageSize}
									maxResults={pageSize + skip}
									skip={`${skip}`}
									page={page}
								/>
							</>
							: <>Loading</>
					}
				</div>
			</Card>
		</>

	)
}

export default ActionsList

const actionPrefix = `ActionListMenu/ActionListMenu/`;

export const actions = {
	FETCH_START: actionPrefix + `FETCH_START`,
	FETCH_FULFILLED: actionPrefix + `FETCH_FULFILLED`,
	FETCH_REJECTED: actionPrefix + `FETCH_REJECTED`,
	PARAMS_SET: actionPrefix + `PARAMS_SET`
}

export const fetchStart = () => ({ type: actions.FETCH_START });
export const fetchFulfilled = (payload) => ({ type: actions.FETCH_FULFILLED, payload });
export const fetchRejected = ( payload, error ) => ({ type: actions.FETCH_REJECTED, payload, error });
export const paramsSet = (params) => ({ type: actions.PARAMS_SET, params });

export default actions